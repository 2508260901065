<template>


<div class="login_form">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
        {{$t('uc.finance.xx57')}}
      </span>
      <span>{{  }}</span>
    </div>



    <div class="body">
      <Collapse v-model="value1">
        <Panel name="1">
          {{$t('uc.finance.xx58')}}
            <div slot="content">
              <div style="display: flex;flex-direction: column;">
<div style="display: flex; justify-content: space-between;">
<span>{{ $t('common.date2') }}</span>
<span>{{ 0 }}
</span>
</div>

<div style="display: flex; justify-content: space-between; margin-top: 5px;">
<span>{{ $t('uc.finance.xx50') }}</span>
<span>{{ 0 }}
</span>
</div>

<div style="display: flex; justify-content: space-between;margin-top: 5px;">
<span>{{ $t('uc.finance.xx51') }}</span>
<span>{{ 0 }}
</span>
</div>

<div style="display: flex; justify-content: space-between;margin-top: 5px;">
<span>{{ $t('uc.finance.xx52') }}</span>
<span>{{ 0 }}
</span>
</div>

<div style="display: flex; justify-content: space-between;margin-top: 5px;">
<span>{{ $t('uc.finance.xx53') }}</span>
<span>{{ 0 }}
</span>
</div>

<div style="display: flex; justify-content: space-between;margin-top: 5px;">
<span>{{ $t('uc.finance.xx54') }}</span>
<span>{{ 0 }}
</span>
</div>

<div style="display: flex; justify-content: space-between;margin-top: 5px;">
<span>{{ $t('uc.finance.xx55') }}</span>
<span>{{ 0 }}
</span>
</div>


<div style="display: flex; justify-content: space-between;margin-top: 5px;">
<span>{{ $t('uc.finance.xx56') }}</span>
<span>{{ 0 }}
</span>
</div>
              </div>
            </div>
 
           
        </Panel>
       
    </Collapse>



    <div style="height: 100%; width: 100%;  margin-top: 10px;">
 
 <ve-table :columns="columns" style="width:100%"
       scroll-width="120%" :table-data="commsionList"></ve-table>
</div>
    </div>
</div>
 
</template>

<script>
export default {
  data () {
  return{

    value1:'1',
    columns: [
                        {
                            field: "type",
                            key: "a",
                            title: this.$t('uc.finance.gametype'),
                            align: "center",
                            fixed: "left",
                            width: "20%",
                        },
                        {
                          field: "effectwinlose",
                            key: "b",
                            title: this.$t('common.date7'),
                            align: "left",
                            width: "30%",
                        },
                        {
                          field: "fee",
                            key: "c",
                            title: this.$t('uc.finance.xx59'),
                            align: "left",
                            width: "30%",
                        },
                        { field: "winlose", key: "d", title: this.$t('common.date2'),width: "20%",align: "left" },
                    ],
                    commsionList:[
    {
      type: '体育',
    effectwinlose: '0',
    fee: '100',
    winlose:'988',
                    },
                    {
                      type: 'AG捕鱼',
                      effectwinlose: '0',
                      fee: '100000',
                      winlose:'100',
                    },
    ],
 
  }
        
      },

  methods: {
    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },

 
}
}
</script>

<style lang="scss" scoped>
.login_form {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  }


  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;

  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}
.body{
  margin-top: 60px;
  width: 90%;
  margin-left: 5%;
}

</style>
