<template>
    <div class="tab-bar-item" @click="itemClick">
        <div v-if="!isActive">
            <slot name="item-icon"></slot>
        </div>
        <div class="active" v-else>
            <slot name="item-icon-active"></slot>
        </div>
        <!-- <div v-if="!isActive"><slot name="item-icon_1"></slot></div>
        <div v-else><slot name="item-icon-active_1"></slot></div> -->
        <div class="text">
            <slot name="item-text"></slot>
        </div>

        <!-- <img src="../../assets/img/tabbar/home.png" alt="">
         <div>首页</div> -->
    </div>
</template>

<script>
export default {
    props: {
        path: String,
        activeColor: {
            type: String,
            default: 'red'
        }
    },
    data() {
        return {
            // isActive:true
        }
    },
    computed: {
        isActive() {
            return this.$route.path.indexOf(this.path) !== -1 && this.$route.path.length === this.path.length
        },
        activeStyle() {
            return this.isActive ? {color: this.activeColor} : {}
        }
    },
    methods: {

        itemClick() {
            // if (this.$store.getters.member.isNobody == true) {
            //    this.$Notice.error({
            //               title: this.$t("common.tip"),
            //               desc: this.$t("uc.forget.loginman")
            //             });
            //   return
            // } else {
            this.$router.replace(this.path)
            // }

        }
    }
}
</script>

<style scoped>


.tab-bar-item {
    flex: 1;
    text-align: center;
    height: 68px;
    font-size: 12px;
    color: #999999;
}


/*  设置图片大小 */
.tab-bar-item img {
    margin-top: 18px;
    width: 16px;
    /* 去除图片下面的空间，默认的3像素 */
    vertical-align: middle;
}

.tab-bar-item .active {
    position: absolute;
    top: 0;
    height: 4px;
    width: 10%;
    background-color: #4491E6;
    margin-left: 5%;
}

.text {
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 20%;
    text-align: center;
    margin-bottom: 14px;
    font-weight: 600;
}


</style>
