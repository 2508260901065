<template>
  <div class="withdraw">
  
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
        {{$t('uc.finance.trans')}}
      </span>
      <span>{{  }}</span>
    </div>
    
    <div class="bodyHeader">
      <!-- <RadioGroup v-model="button1" type="button" @on-change="changeCUr">
        <Radio label="USDT"></Radio>
        <Radio label="USD"></Radio>
        <Radio label="VND"></Radio>
        <Radio label="CNY"></Radio>
    </RadioGroup> -->
   
    <div style="display: flex; width: 100%;margin-top: 10px;">
      <div style="align-items: center;display: flex;">
      <img :src='currenyImg' alt="" width="25">
      <div style="margin-left: 10px;color: #fff;">{{$t('uc.finance.tradetail')}} : </div>
      <span style="color: #fff;">{{selfamount}}</span>  
      </div>
     
      

    </div>
     
    </div>
      
       <div class="rightarea">
        
      <section v-if="model1 == 'USDT'">
     
     <div class="celllall">
      <!-- <span style="margin-left:20px">
        {{$t('uc.forget.userID')}}
      </span> -->
      <!-- <input type="text" v-model="inputAddress" prop="inputAddress" @input="changePrice('inputAddress')" style="color: #738191; width:80%; padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px; border-style:ridge; margin-left: 20px;" clearable  :placeholder="$t('uc.forget.addresstip21')"> -->
      <Input v-model="inputAddress" type="text"  style="color: #898989;background-color: #fff; border-radius: 10px; border-color: #eae8df; border-inline-width: 0px;  width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.forget.addresstip21')" />
 
    
    </div>
     <div class="celllall">
      <!-- <span style="margin-left:20px">
        {{$t('uc.finance.invitingmining')}}
      </span> -->
      <!-- <input type="text" @change="onKeyYHKNameInput" v-model="withdrawAmount" style="color: #738191; width:80%;padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px;border-style:ridge;margin-left: 20px;" clearable  :placeholder="$t('uc.finance.invitingmining')"> -->
      <Input v-model="withdrawAmount" type="text"  @on-change="onKeyYHKNameInput" style="color: #898989;background-color: #fff;border-radius: 10px; border-color: #eae8df; border-inline-width: 0px;  width:80%;  margin-left: 20px; " clearable   :placeholder="$t('uc.finance.invitingmining')" />
 
    </div>
     
      <div style="margin-top:40px;">
        <Button style="width:40%; height: 40px; background-color: #ff5722; font-size: medium; border: none;  color: #fff; "   @click="firstModel">{{$t('uc.forget.save')}}</Button>
      </div>                
                  
      </section>
      <section v-else-if=" model1 == 'HUIONE'">
      </section>
 
      <div style="color:#f60;margin-top: 20px;">
        <span>
          {{$t('common.notice23')}}
        </span>
      </div>
      </div>
    

    <Modal v-model="modal2" width="360">
      <p slot="header" style="color:#f60;text-align:center">
       
        <span>{{$t('uc.forget.emailcodetip1')}}</span>
      </p>
      <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
        <FormItem>
          <Input type="password" password v-model="formInline.fundpwd" :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
        <FormItem>
          <Input type="password" password v-model="formInline.refundpwd" :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" size="large" long @click="submitCode">{{$t('uc.forget.save')}}</Button>
      </div>
    </Modal>

    <Modal v-model="modal9" width="450">
      
      <p slot="header">
        {{$t("uc.forget.emailcodetip")}}
      </p>
      <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
        <FormItem>
          <Input type="password" password v-model="formInline.fundpwd" :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
       
      </Form>
      <div slot="footer">
        <span style="margin-right:50px" @click="cancel">{{$t("common.close")}}</span>
        <span style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;" @click="checkpp">{{$t("common.ok")}}</span>
      </div>
    </Modal>

    <Modal v-model="modal10" width="450">
      
      <p slot="header">
        {{$t("common.tibifirst")}}
      </p>
      <p>
        {{$t('uc.forget.confirmpwd11')}} : {{inputAddress}}
      </p>
      <br>
      <p>
        {{$t('uc.forget.walletaddress2')}} : {{withdrawOutAmount}}
      </p>
      <div slot="footer">
        <span style="margin-right:50px" @click="cancel">{{$t("common.close")}}</span>
        <span style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;" @click="okfirst">{{$t("common.ok")}}</span>
      </div>
    </Modal>
    <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
  </div>
</template>


<script>



export default {
    components: {
  },
  data() {
    return {
      user: {},
      codeIsSending: false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      countdown: 60,
      formInline: {
        fundpwd: "",
        refundpwd: ""
      },
      button1:'USDT',
      value14:'',
       modal9: false,
      modal10: false,
      modal: false,
      modal2: false,
      fundpwd: "",
      currentCoin: {},
      transaction: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      loading: true,
      withdrawAdress: "",
      inputAddress: "", //用户输入的地址
      withdrawAmount: 0,
      withdrawFee: 0,
      withdrawOutAmount: 0,
      coinType: "",
      coinList: [],
      tableWithdraw: [],
      allTableWithdraw: [],
      model1:'USDT',
      moneybab:'',
      model21:'',
      selfamount:'',
      currenyImg: '',
      eloading: false
    };
  },
 
  methods: {
    goback() {
      //获取个人安全信息
      this.$router.back(-1)
     
    },
    changeCUr(data){
      this.coinType = data

      this.tableWithdraw.forEach(element => {
              if (element.ccy == this.coinType) {
                this.selfamount = element.amount
                
              }
            });
    },

    okfirst(){
      // this.getMemberInfo()
       if (this.user.jbPassword) {
            // self.getAddrList()
            this.modal9 = true
           }else{
            // this.$Message.error(this.$t('uc.forget.emailcodetip1'));
            this.modal2 = true
           }
    },
    onKeyYHKNameInput() {
       let value = '' + this.withdrawAmount
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
        }
        this.$nextTick(function(){
          this.withdrawOutAmount = value;
          this.withdrawAmount = Number(value).toLocaleString()
        })
     },
    cancel() {
      this.modal9 = false;
      this.modal10 = false;
      this.formInline.code = "";
      this.formInline.fundpwd = "";
      this.formInline.refundpwd = "";
    },
    
   
  
    clearValues() {
      if (this.$refs.address) {
        this.$refs.address.setQuery(" ");
      }
      this.withdrawAdress = "";
      this.inputAddress = "";
      this.withdrawAmount = 0;
      // this.withdrawFee= 0;
      this.withdrawOutAmount = 0;
      this.formInline.fundpwd = ''
      this.formInline.refundpwd = ''
    },
  


getMemberInfo() {
      //获取个人安全信息
      let self = this;
     
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          let resp = response.body;
          if (resp.code == "0000") {
          self.user = resp.data
          self.coinType = self.button1 = resp.data.remark
         
          if (self.coinType == 'USDT') {
    self.currenyImg = require('@/assets/logo/usdt.png');
   }else if (self.coinType == 'USD') {
    self.currenyImg = require('@/assets/logo/usa.png')
   }else if (self.coinType == 'VND') {
    self.currenyImg = require('@/assets/logo/vietnam.png')

   }else if (self.coinType == 'CNY') {
    self.currenyImg = require('@/assets/logo/china.png')
   }else if (self.coinType == 'IDR') {
    self.currenyImg = require('@/assets/logo/indiaN.png')
   }else if (self.coinType == 'JPY') {
    self.currenyImg = require('@/assets/logo/japan.png')
   }else if (self.coinType == 'KRW') {
    self.currenyImg = require('@/assets/logo/korea.png')
   }
   else if (self.coinType == 'THB') {
    self.currenyImg = require('@/assets/logo/thai.png')
   }

   self.getMember()

          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },
  getMember() {
      //获取个人安全信息
      let self = this;
      // var params = {
      //     };
         
      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
         
      this.$http
        .post(self.host + this.api.uc.personalWallet)
        .then(response => {
          let resp = response.body;
          if (resp.code == "0000") {
            // self.user = resp.data;
            // self.usernameS = this.user.username.slice(0,1);
            self.tableWithdraw = resp.data
           
            self.tableWithdraw.forEach(element => {
              if (element.ccy == self.button1) {
                
                self.selfamount = element.amount
                
              }
            });
            // if (this.isTg) {
            //   this.changeCoin()
            //   this.sliderSellMarketPercent = Number(this.$store.getters.amount)
            // }
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

checkpp(){
  
  if (this.formInline.fundpwd == '') {
              this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: this.$t("uc.forget.emailcodetip")
                  });
                  return
             }
             this.getAddrList(this.formInline.fundpwd)
// let params = {};
  
// params['password']= this.formInline.fundpwd
// this.$http
//         .post(this.host + this.api.uc.mylistrecord, params)
//         .then(response => {
//           this.fundpwd = "";
//           var resp = response.body;
//           if (resp.code == "0000") {
//             this.modal9 = false;
//             this.formInline.fundpwd = ''
//             // this.$Message.success(resp.mesg);
//             if (resp.data == true) {
//               this.getAddrList()
//             }else
//              this.$Notice.error({
//                     title: this.$t("common.tip"),
//                     desc: this.$t("uc.regist.emailerr")
//                   });
            
//           } else {
//             this.$Message.error(resp.mesg);
//           }
//         });
},

submitCode(){
  if (this.formInline.fundpwd != this.formInline.refundpwd) {
              this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: this.$t("uc.forget.pwdvalidate2")
                  });
                  return
             }
             this.getAddrList(this.formInline.fundpwd)

// let params = {};
  
// params['jbPassword']= this.formInline.fundpwd
// this.$http
//         .post(this.host + this.api.uc.activitylist, params)
//         .then(response => {
//           this.fundpwd = "";
//           var resp = response.body;
//           if (resp.code == "0000") {
//             this.modal2 = false;
//             this.formInline.fundpwd = ''
//             this.getAddrList(this.formInline.fundpwd)
//           } else {
//             this.$Message.error(resp.mesg);
//           }
//         });
},



   

getAddress() {
      //获取个人安全信息
      var self = this;
      // var params = {
      //     };
         
      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
         
      this.$http
        .post(self.host + this.api.uc.wallet)
        .then(response => {
          var resp = response.body;
          if (resp.length > 0) {
            // self.inputAddress = resp[0].walletAddress
          } else {
            if (resp.mesg) {
              self.$Message.error(resp.mesg);
            }
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

    getAddrList1() {
  //     if (this.$store.getters.member.pwdStatus == '0') {
  //         this.$Message.error(this.$t('uc.forget.emailcodetip1'));
  //         return
  // }
      let params = {};
      
      params["amount"] = this.withdrawOutAmount
      params["ccy"] = this.coinType
      params["userNo"] = this.inputAddress
      // BigDecimal amount String userNo  String ccy 
      this.$http
        .post(this.host + this.api.uc.checkuser, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            if (resp.data.id != null && resp.data.userType != null) {
              let member = this.$store.getters.member
              if (member.userType == '1' || resp.data.userType == '1') {
                this.modal10 = true;
              } else {
                this.$Message.error(this.$t('uc.login.normaluser'))
              }
              
          //  this.$Message.success(resp.mesg)
            }else
            this.$Message.error(this.$t('uc.login.nouser'))
          } else {
            this.$Message.error(resp.mesg);
          }
        });
    },
    // initwithdraw
    getAddrList(pass) {
  //     if (this.$store.getters.member.pwdStatus == '0') {
  //         this.$Message.error(this.$t('uc.forget.emailcodetip1'));
  //         return
  // }

//   amount (number, optional): 转账金额 ,
// ccy (string, optional): 币种 ,
// pass (string, optional): 交易密码 ,
// payeeUserNo (string, optional): 用户编号
      let params = {};
      let that = this
      params["amount"] = this.withdrawOutAmount
      params["ccy"] = this.coinType
      params["payeeUserNo"] = this.inputAddress
      params['pass'] = pass
this.eloading = true
      // BigDecimal amount String userNo  String ccy 
      this.$http
        .post(this.host + this.api.uc.exchangecard, params)
        .then(response => {
          var resp = response.body;
          that.eloading = false
          if (resp.code == "0000") {
            that.model21 = resp.data
            that.modal10 = false
            that.modal9 = false
            that.modal2 = false
            that.clearValues();
            that.getMember()
            that.$Message.success(resp.mesg)
          } else {
            that.$Message.error(resp.mesg);
          }
        });
    },
  
  
    getList() {
      //获取tableRecharge
      this.loading = true;
      let memberId = 0;
      !this.$store.getters.isLogin && this.$router.push("/login");
      this.$store.getters.isLogin && (memberId = this.$store.getters.member.id);
      console.log("hahah"+ memberId)
      this.$http.post(this.host + this.api.uc.paydividends).then(response => {
          var resp = response.body;
          if (resp.code == '0000') {
            if (resp.data) {
            
              console.log('xwe')
              this.coinList = resp.data
              // resp.data.forEach(element => {
              //   if (element.acctName == 'USDT') {
              //     this.panel1 = element
              //   } else {
              //     this.panel2 = element
              //   }
              // });
            }
            this.loading = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
    },
  

firstModel(){
   
  //  this.getAddrList1();
  this.modal10 = true;
},
    
    getMoneyback(){
 var self = this;
    this.$http
        .post(this.host + this.api.uc.apply)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            this.getMember();
          } else {
            self.$Message.error(resp.mesg);
          }
        });
  },
  },
  created() {
    
    // this.$http.options.emulateJSON = false;
    // this.coinType = this.$route.query.name || "";
    // this.getAddrList();
    
    // this.getMember();
    this.getMemberInfo()
    // this.getList();
    this.getAddress()
    // this.getMoneyback();
    // console.log(this.$store.getters.member);
  },
  computed: {
    member: function() {
      console.log(this.$store.getters.member);
      return this.$store.getters.member;
    },
    tableColumnsWithdraw() {
      let columns = [],
        filters = [];
      if (this.coinList.length > 0) {
        this.coinList.forEach(v => {
          filters.push({
            label: v.unit,
            value: v.unit
          });
        });
      }
      columns.push({
        title: this.$t("uc.finance.withdraw.time"),
        width: 180,
        key: "createTime"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.symbol"),
        key: "symbol",
        filters,
        filterMultiple: false,
        filterMethod(value, row) {
          return row.coin.unit === value;
        },
        render: function(h, params) {
          return h("span", params.row.coin.unit);
        }
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.address"),
        key: "address"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.num"),
        key: "totalAmount"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.fee"),
        key: "fee"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.txid"),
        key: "transactionNumber"
      });
      columns.push({
        title: this.$t("uc.finance.withdraw.status"),
        key: "status",
        render: (h, params) => {
          let text = "";
          if (params.row.status == 0) {
            text = this.$t("uc.finance.withdraw.status_1");
          } else if (params.row.status == 1) {
            text = this.$t("uc.finance.withdraw.status_2");
          } else if (params.row.status == 2) {
            text = this.$t("uc.finance.withdraw.status_3");
          } else if (params.row.status == 3) {
            text = this.$t("uc.finance.withdraw.status_4");
          }
          return h("div", [h("p", text)]);
        }
      });
      return columns;
    }
  }
};
</script>
<style lang="scss" scoped>
.withdraw-form-inline {
  padding: 20px 40px 0 40px;
  .ivu-input {
    height: 40px;
    line-height: 40px;
  }
}
.box_21{
    position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}
.vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-modal{
            top: 0;
        }
    }

.celllall{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}

.celllall1{
  width: 100%;
  height: 40px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  align-items: flex-start;
  // background: url(../assets/netmage/qqqy.png);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  font-size: medium;
  margin-top: 50px;
  
}

.headerinfo{
  width: 100%;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
 
}


.withdraw{
  background-color: $color-background;

  height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}


  .rightarea {
  width: 100%;
  height: auto;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 95% 100%;
  margin-top: 40px;
  padding: 20px;
    }

/deep/ .ivu-input{
  
  border-radius: 8px;
  border: 0px solid #3d56a2;
  background-color: #666;
  color: #fff;
}

.bodyHeader{
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20px;
  padding: 10px;
  color: $color-text;
  font-size: medium;
}
.ivu-btn-primary {
  background-color: #f0a70a;
  border-color: #f0a70a;
}

</style>


<!-- <style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border: 0;
  border-radius: 25px;
  
  color: #898989
}
    
</style> -->

