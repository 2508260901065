<template>
    <div class="card-list">
        <div class="v-card" @click="selectItem(item)" v-for="(item, index) in list" :key="index">
            <div class="card">
                <img :src="item.ImageUrl" class="pic">

            </div>
            <p class="title">{{ item.GameName | ellipsis }}</p>
        </div>
    </div>
</template>
<!-- {
  "mType": 7004,
  "isNew": true,
  "image": "https://dl.lfyanwei.com/jdb-assetsv3/games/7004/7004_en.png",
  "name": "Five Dragons Fishing"
}, -->
<script type="text/ecmascript-6">
// 由于接收的list有两种不同格式的数据，所以对两种格式做了部分兼容
export default {
    props: {
        list: {
            type: Array,
            default: null
        }
    },
    filters: {
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 10) {
                return value.slice(0, 10) + '...'
            }
            return value
        }
    },
    methods: {
        selectItem(item) {
            this.$emit('select', item)
        },
        goMore() {

        },
        nothing() {

        }
    },

}
</script>

<style lang="scss" scoped>

.card-list {
    display: flex;
    flex-wrap: wrap;

    .v-card {
        display: inline-block;
        width: 28%;
        box-sizing: border-box;
        background-color: $color-link;
        border-radius: 15px;
        margin-top: 10px;
        margin-left: 10px;
        padding-bottom: 10px;

        .card {
            position: relative;
            overflow: hidden;
            // border-radius: 20px;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;

            .pic {
                top: 0;
                left: 0;
                width: 100%;
                height: 80px;
            }

            .count {
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 5px 6px;
                box-sizing: border-box;
                color: #ffffff;
                // font-size: 12px;
                // background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), transparent);

                .left {
                    span {
                        margin-right: 5px;
                    }
                }

                i {
                    margin-right: 5px;
                }
            }
        }

        .title {
            font-size: smaller;
            line-height: 11px;
            margin-top: 2px;
            color: #ffffff;
            overflow: hidden;
            // text-overflow: ellipsis;
            padding-left: 5px;
            padding-right: 5px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: Georgia, serif;
        }
    }
}
</style>
