<template>
   <div class="nav-rights">
    


    <Transition @after-leave="onAfterLeave" name="slide-fade">
      <div class="headBox" v-if="isShow == true">
        <img src="../assets/logo/back.png" alt="" width="20" @click="submitFist()">
      <span  style="font-size:20px;">
        <!-- {{$t('uc.finance.gamedetail')}} -->
      </span>
      <span @click="hideTop">{{ $t('uc.finance.xx146') }}</span>
      </div>

      
    </Transition>


    <div class="box111" v-if="isAfter == true" @click="showTop">
        <img src="../assets/logo/down.png" width="20" height="10" alt="" >
      </div>
     

      

      <!-- <drag-ball :value="message" @click="submitFist()" style="background: transparent ;width: 40px;height: 40px;">
        <div slot="value">
            <img src="../assets/logo/home_1.png" width=30  alt="">
        </div>
    </drag-ball> -->

        <div @touchmove='touchmove'>
          <iframe style="position: absolute; top: 0px; left: 0px; width: 100%;"  :src="outurl" frameborder="0"  id="gameframe"></iframe>
        </div>


        <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>

    <Modal
        v-model="modal2"
        :title="$t('common.tip')"
        @on-ok="submitSecond"
        @on-cancel="cancel">
        <p>{{$t('uc.finance.xx95')}}</p>
       
    </Modal>
   </div>
</template>

<script>
// import { browser } from "../config/index.js";
export default {
  data() {
    return {
      outurl:'',
      windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      message:'',
      tryout: 0,
      eloading: false,
      modal2: false,
      isShow: true,
      isAfter: false,
      cmdParam: {}
    }
    },

    computed: {
   
    isLogin: function() {
      return this.$store.getters.isLogin;
    },
    
  },


    created() {
      // let id = this.$route.query.gid
      if (!this.isLogin) {
        // this.outurl = 'https://www.waliyouxi.com/trial/trial.html?game=' + id
        this.$router.push('/Home')
       
      } else {
          // if(this.$route.query.gpage == undefined) console.log("undefined") ;
        if (this.$route.query.gid == 'cmd') {
          this.cmdParam = this.$route.query.gpage

          let user = this.$store.getters.member.userNo+'-'+this.cmdParam.CurrencyCode
          let cyz = this.cmdParam.CurrencyCode
          if (cyz == 'VND') {
            cyz = 'VD'
          }
          this.outurl = 
          'https://jbsmart.1win888.net/auth.aspx?lang='+this.cmdParam.lang+'&user='+user+'&token='+localStorage.getItem('TOKEN')+'&currency='+cyz+'&templatename=green&view=v1'
        
        }else 
        this.outurl = this.$route.query.gpage;
        console.log("ssss " + this.outurl)
      }

   
        
    this.tryout = 0
  
  },
  mounted(){
    const iggame = document.getElementById('gameframe');
   
    iggame.height = document.documentElement.clientHeight;

  //   this.$nextTick(function () {
  //   // Code that will run only after the
  //   // entire view has been rendered
  //   if (browser().ios) {
  //     document.getElementById('outbody').style.overflow = 'hidden'
  //   }

  //   if (this.$route.query.gid == '-1') {
  //     document.getElementById('outbody').style.overflow = ''
  //   }
  // })
    
    window.addEventListener('resize', this.renderResize, false);
   
  },
  methods: {

    touchmove (e) {
     e.preventDefault()
	},
   
    hideTop(){
this.isShow = false
    },

    onAfterLeave(){
      this.isAfter = true
    },
    showTop(){
      this.isShow = true
      this.isAfter = false
    },
    cancel(){
      this.modal2 = false
    },
    submitFist(){
      this.modal2 = true
    },
    submitSecond(){
      this.goHome()
    },

    
    renderResize(){
       const iggame = document.getElementById('gameframe');
       iggame.height = document.documentElement.clientHeight;
   
    },
    getMoneyback(){

      let param = {};
        param["gameCode"] = this.$route.query.gid;
 let self = this;
 this.eloading = true
    this.$http
        .get(this.host + this.api.uc.mdboMakeTransfer, {'params':param})
        .then(response => {
          let resp = response.body;
          self.eloading = false
          self.modal2 = false
          if (resp.code == '0000') {
            // this.getMember();
            this.$router.replace({ path: '/', query: { gid:param.gameCode } })
            self.tryout = 0
          }else if (resp.code == '5000') {
           
            // self.$Notice.warning({
            //         title: this.$t("common.tip"),
            //         desc: resp.mesg
            //       });

                  this.$router.replace({ path: '/', query: { gid:param.gameCode } })
          }
           else {
            self.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
          }
        });
  },
   getMember() {
      //获取个人安全信息
      let self = this;
     
      this.$http
        .post(self.host + this.api.uc.personalWallet)
        .then(response => {
          let resp = response.body;
          if (resp.code == "0000") {
          
            resp.data.forEach(element => {
              if (element.ccy == 'USDT') {
                self.moneybab = element
                
              }
            });
           
          } else {
            self.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
          }
        });
    },

    goHome(){
      if (this.$route.query.gid == '-1') {
        this.$router.replace({ path: '/me' })
      }else
     
          // this.getMoneyback();
          this.$router.replace({ path: '/'  })
    }
  }
}


</script>

<style lang="scss" scoped>
.nav-rights {
  background: transparent;
  height: $full-page;
  position: relative;
  overflow: hidden;


.box111{
  position: fixed;
  top: 0;
  right: 25%;
  left: 25%;
  display: flex;
  justify-content: center;
  align-items: top;
  z-index: 200;
  height: 40px;
   width: 50%;
   
  
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateY(50px);
}
.slide-fade-leave-to {
  transform: translateY(-50px);
  // opacity: 0;
}

}



</style>

