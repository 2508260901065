<template>
  <div class="total">
    <div class="headBox">
      <span>{{ }}</span>
      <span  style="font-size:20px;color:#fff;">
        {{$t('common.ads')}}
      </span>
      <span>{{ }}</span>
    </div>
    <div class="body">
      <List item-layout="vertical" size="small" :split="false">
        <ListItem v-for="item in adList" :key="item.title">
           <div style="padding-left: 10px; padding-right: 10px;">
            <img :src="item.coverImg" alt="" width="100%" style="border-radius: 20px;">
            <span style="font-size: large;color: blueviolet;">{{ item.jumpTarget }}</span>
           </div>
        </ListItem>
    </List>
    </div>
   
    <Main-tab-bar></Main-tab-bar>
  </div>
  
</template>

<script>

import MainTabBar from '../components/tabbar/MainTabBar'
export default {
  components: {
MainTabBar
  },
  data() {
   
   return {
    adList:[],
   }
  },
  created() {
    this.loadPicData()
  },
  methods: {
    loadPicData() {
     let param={}
     param.adType = 'VIP_EVENT'
      this.$http
          .get(this.host + this.api.uc.mycardlist, {params:param})
          .then(response => {
            var result = response.body;
            if (result.code == '0000') {
              this.adList = result.data;
            } else {
              console.log();
            }
          });
    },
  }

  
}
</script>

<style lang="scss" scoped>

.header{
  position: fixed;
  top: 0;
  left: 0;
	right: 0;
  height: 60px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items:center;
  background:url(../assets/logo/barBottom.png);
  transform: rotate(0.5turn);
}


.total{
  background-color: $color-background;
  height: 55rem;
}

.body{
  margin-top: 45px;
  text-align: center;
}

</style>
