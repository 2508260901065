<template>
  <div class="login_form">

    <div class="box">
      <img src="../assets/netmage/arrowleft.png" alt="" style="width:20px;height:15px;" @click="goback">
      <span style="color:aliceblue;font-size:20px;margin-left: 40px;">
        {{$t('uc.finance.gamedetail')}}
      </span>
    </div>

    <div style="margin-top:100px">
  <Button style="width:40%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="popdata">{{$t('common.date')}}</Button>
</div>

<todo-list style="height:100%;" message='0' :doneList = 'datalist' @nextPage='nextPage'/>
<calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange"/>

  </div>
</template>

<script>

import TodoList from '@/components/TodoList'
import dayjs from 'dayjs';
export default {
  components: {
    TodoList
  },
 data() {
return{
datalist:[],
startDate: '',
      endDate : '',
      defaultDate:[ dayjs(), dayjs().add(-30, 'day') ],
      totalregist:'',
      totolwin: 0,
      totalbet: 0,
      totalValidBet: 0,
      calendarShow:false,
      currentPage: 1,
userNo: ''
}
 },


created: function() {
  this.userNo =  this.$route.query.gpage
  this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.initGtCaptcha();
  },


methods: {
  nextPage(){
    let that = this
  let params = {}
     params.beginDateStr = this.startDate
     params.endDateStr = this.endDate
     this.currentPage += 1
     params.pageNum = this.currentPage
     params.pageSize = 10
       this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
       var resp = response.body;
      
                    if (resp.code == "0000") {
                      let xyz = resp.data.list
                      if (xyz.length <= 0) {
                        this.$Notice.info({
                        title: that.$t("uc.finance.xx14"),
                       
                      });
                      }
                      xyz.forEach(element => {
                        that.datalist.push(element)
                      });
                     
                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
  },

goback() {
      //获取个人安全信息
      this.$router.back(-1)
     
    },
    onChange(date){
if (date[0] != null && date[1] != null) {
  this.startDate = date[0].format('YYYY-MM-DD')

  this.endDate = date[1].format('YYYY-MM-DD')


this.initGtCaptcha()
}

    },

 initGtCaptcha() {
  let that = this
  let params = {}
     params.beginDateStr = this.startDate
     params.endDateStr = this.endDate
     params.userNo = this.userNo
     params.pageNum = 1
     this.currentPage = 1
     params.pageSize = 10
       this.$http.post(this.host + '/app-agent/getUserRecordList', params).then(response => {
       var resp = response.body;
      
                    if (resp.code == "0000") {
                      that.datalist = resp.data
                        this.totalbet = resp.data.totalBet
                        this.totolwin = resp.data.totalWin
                        this.totalValidBet = resp.data.totalValidBet
                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    popdata(){
        this.calendarShow = !this.calendarShow
    },

}


}
</script>

<style lang="scss" scoped>

// .login_form {
//   background: #0b1520;
//   height: 56rem;
//   position: relative;
//   overflow: hidden;
//   }

  .login_form{
  width: 100%;
  height: $full-page;
  overflow: hidden;
  background-color: black;
}
.box{
 position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
   width: 100%;
  align-items: flex-start;
  align-items: baseline;
   background: url(../assets/netmage/xxyz.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 200;
}

.head2{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.bodyhead{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.head21{
  color:#898989 ;
display: flex;
flex-direction: column;
font-size: medium;
}

.head22{
display: flex;
flex-direction: column;
font-size: medium;
}
</style>



