<template>
  <div class="login_form ">

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
        {{$t('uc.forget.resetemailpwd')}}
      </span>
      <span>{{  }}</span>
    </div>
<div class="login_right">
    <Form  ref="formInline" :model="formInline" :rules="ruleInline" inline>
     <FormItem >
      <!-- <img src="../assets/netmage/logo08.png" style="width: 125px;height:119px"> -->
        </FormItem>
    <!-- <FormItem prop="user" style="margin-top:-50px">
          <Input type="text" style="width:230px;" size="large" v-model="formInline.user" :placeholder="$t('uc.login.usertip')" disabled>
            <Select v-model="country" slot="prepend" style="width: 65px;border-bottom: 1px solid #27313e;" disabled>
            <Option value="+855" label="+855"><span>+855</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.cambodia')}}</span></Option>
              <Option value="+855" label="+855"><span>+855</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.cambodia')}}</span></Option>
              <Option value="+86" label="+86"><span>+86</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.china')}}</span></Option>
              <Option value="+65" label="+65"><span>+65</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.singapore')}}</span></Option>
              <Option value="+82" label="+82"><span>+82</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.korea')}}</span></Option>
              <Option value="+81" label="+81"><span>+81</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.japan')}}</span></Option>
              <Option value="+66" label="+66"><span>+66</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.thailand')}}</span></Option>
              <Option value="+84" label="+84"><span>+84</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.vietnam')}}</span></Option>
              <Option value="+62" label="+62"><span>+62</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.india')}}</span></Option>
              <Option value="+852" label="+852"><span>+852</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.hk')}}</span></Option>
              <Option value="+60" label="+60"><span>+60</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.malaysia')}}</span></Option>
              <Option value="+886" label="+886"><span>+886</span><span style="margin-left:10px;color:#ccc">{{$t('uc.regist.taiwan')}}</span></Option>
            </Select>
          </Input>
        </FormItem> -->

        <FormItem prop="code" class="captcha" v-show="showCode">
          <Input type="text" size="large" v-model="formInline.code" :placeholder="$t('uc.regist.smscode')">
          </Input>
          <input  id="sendCode" @click="sendCode();"  type="Button" style="margin-top:10px;height: 100%;color:gray;border-top-right-radius: 8px;border-bottom-right-radius: 8px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;border-right: 2px solid #666;border-bottom: 2px solid #666;border-top: 2px solid #666;border-left: 2px solid #666;"  shape="circle" :value="sendcodeValue" :disabled='codedisabled'/>

        </FormItem>
        <!-- <FormItem prop="oldpassword"  style="width:100%;">
          <Input type="password"  password size="large" v-model="formInline.oldpassword" :placeholder="$t('common.capitalPassinput')">
          </Input>
        </FormItem> -->

        <FormItem prop="password2"  style="width:100%;">
          <Input type="password" password size="large" v-model="formInline.password2" :placeholder="$t('uc.forget.newpwd')">
          </Input>
        </FormItem>

        <!-- <FormItem prop="repassword"  style="width:100%;">
          <Input type="password" password size="large" v-model="formInline.repassword" :placeholder="$t('uc.regist.confirmpwdtip')">
          </Input>
        </FormItem> -->

        <FormItem style="width:100%;">
          <Button style="color:black;width:100%"  @click="handleSubmit">{{$t('uc.forget.save')}}</Button>
        </FormItem>
        </Form>
        </div>

        <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
  </div>
</template>

<script>
export default {
data(){

  return{
    formInline: {
        oldpassword:'',
        password: "",
        repassword: "",
      },
      country: "+855",
      country1:'',
       showCode: true,
       countdown: 60,
        key: "",
      code: "",
      eloading: false,
      codedisabled:false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      tempMem:null,
ruleInline: {

        code: [
          {
            required: true,
            message: this.$t("uc.regist.smscodetip"),
            trigger: "blur"
          }
        ],
        oldpassword: [
          {
            required: true,
            message: this.$t("uc.forget.emailcodetip"),
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: this.$t("uc.regist.pwdmsg"),
            trigger: "blur"
          }
        ],
        password2: [
          {
            required: true,
            message: this.$t("uc.forget.newpwd"),
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: this.$t("uc.regist.pwdmsg"),
            trigger: "blur"
          }
        ],
        repassword: [
          {
            required: true,
            message: this.$t("uc.regist.confirmpwdtip"),
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: this.$t("uc.regist.pwdmsg"),
            trigger: "blur"
          }
        ],
      },
      timercode:''
  }
},

destoryed() {
    clearInterval(this.timercode)
  },
 mounted() {

  this.formInline.user = this.$store.getters.member.phone
  this.country = this.$store.getters.member.areaCode

  },
  created(){
    this.getMemberInfo()
  },
methods: {
  goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },
   settime() {
      this.sendcodeValue = this.$t("uc.regist.resendcode") + this.countdown + ")";
      this.codedisabled = true;
      var _this = this;
      this.timercode = setInterval(() => {
        _this.countdown--;
        _this.sendcodeValue = _this.$t("uc.regist.resendcode") + _this.countdown + ")";
        if (this.countdown <= 0) {
          clearInterval(_this.timercode);
          _this.codedisabled = false;
          _this.sendcodeValue = _this.$t("uc.regist.sendcode");
          _this.countdown = 120;
        }
      }, 1000);
    },
sendCode() {
      var params = {};

          // let xy = this.country.substring(1, this.country.length)

      params["mobilePhone"] = this.tempMem.phone;
      params["areaCode"] = this.tempMem.areaCode;
      params["sendType"] = 4;
      // var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      // reg.test(params["phone"]) && this.$http.post(this.host + "/uc/mobile/code", params).then(response => {
      this.$http.post(this.host + this.api.uc.captcha, params).then(response => {
            var resp = response.body;
            resp.code == "0000" && this.$Notice.success({title: this.$t("common.tip"),desc: resp.mesg});
            resp.code == "0000" && this.settime();
            resp.code != "0000" && this.$Notice.error({title: this.$t("common.tip"),desc: resp.mesg});
          });
      // !reg.test(params["phone"]) &&this.$Notice.error({title: this.$t("common.tip"),desc: this.$t("uc.finance.withdraw.telerr")});
},

getMemberInfo() {
      //获取个人安全信息
      var self = this;
     this.eloading = true
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          self.eloading = false
          if (resp.code == "0000") {

          //  this.$store.commit("setMember", resp.data);
          self.tempMem = resp.data
           clearInterval(this.timercode)
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

handleSubmit() {

        let that = this
            var params = {};

          // let xy = this.country.substring(1, this.country.length)
//           code (string, optional): 验证码 ,
// newPwd (string, optional): 新密码
            // params["mobile"] = this.formInline.user;
            // params["oldPassword"] = this.formInline.oldpassword;
            params["code"] = this.formInline.code;
            params["newPwd"] = this.formInline.password2;
            this.$http.post(this.host + this.api.uc.myInnovationOrderList, params)
              .then(response => {
                var resp = response.body;
                if (resp.code == '0000') {
                  that.getMemberInfo()
                  this.$Notice.success({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
                } else {
                  this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: resp.mesg
                  });
                }
              });

          // this.$Message.success(this.$t('uc.forget.resetpwdsuccess'));


    },

},
};
</script>

<style scoped lang="scss">


.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  color: $color-text-tag;
  align-items: center;
  z-index: 200;
   width: 100%;
}
.captcha {
  width: 100%;
}

/deep/ .ivu-input{
  border-radius: 8px;
  border: 1px solid #2f2f2f;
  background-color: #1B2C38;
  color: #fff;
}
.login_form {
  background: $color-background;

  height: $full-page;
  position: relative;
  overflow: hidden;

   .login_right {

     padding: 20px 30px 20px 30px;
    position: absolute;
    // background: #17212e;
    width: 350px;
    height: 600px;
    left: 5%;
    top: 15%;
    // border-top: 4px solid #f4c772;
    border-radius: 5px;
    form.ivu-form.ivu-form-label-right.ivu-form-inline {
      .ivu-form-item {
        .ivu-form-item-content {
          .register_btn.ivu-btn {
            background-color: #f4c772;
            outline: none;
            border-color: #f4c772;
            color: #000;
            border-radius: 5px;
            font-size: 18px;
            margin-top: 50px;
            margin-left: -120px;
            &:focus {
              -moz-box-shadow: 2px 2px 5px transparent, -2px -2px 4px transparent;
              -webkit-box-shadow: 2px 2px 5px transparent, -2px -2px 4px transparent;
              box-shadow: 2px 2px 5px transparent, -2px -2px 4px transparent;
            }
          }
          .ivu-input-wrapper.ivu-input-type {
            .ivu-input {
              border: 1px solid red;
            }
          }
          #sendCode {
            position: absolute;
            border: 1px solid #0b1520;
            background: transparent;
            top: -10px;
            outline: none;
            right: 0;
            width: 30%;
            color: #000;
            cursor: pointer;
          }
        }
      }

    }
  }
}

</style>
