<template>
  <div class="login_form">
    <div class="headBox">
     
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
     
     <span  style="font-size:20px;color:#fff;">
       {{$t('uc.finance.agentpage')}}
     </span>
     <span>{{  }}</span>
    </div>
   

    <div class="body">

      <img src="../assets/logo/55555.png" alt="" width="70%" style="margin-top:100px;margin-left:15%">

      <div class="btngroup">
        <Button  class="login_btn" @click="applyAgent" style="margin-bottom: 20px;">{{$t('uc.finance.agentapply')}}</Button>
        <Button  class="login_btn" @click="gotoDataPage">{{$t('uc.finance.agentdata')}}</Button>
      </div>
     
    </div>
    
  </div>
</template>

<script>
export default {


  data() {
    return{
      
      isagent:'',
     
    }
  },
  created() {
 
    this.getMemberInfo();
    
  },

  methods: {
    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },

  getMemberInfo() {
      //获取个人安全信息
      var self = this;
     
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
              localStorage.setItem("MEMBER", JSON.stringify(null));
              self.$store.commit("setMember", resp.data);
              self.isagent = resp.data.userType
              self.nicknamexx = self.$store.getters.member.nickname
              self.userNoxx = self.$store.getters.member.userNo
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

  applyAgent(){
    console.log('agent apply');

    this.$router.push("/tabCS");

  },

  gotoDataPage(){
    if (this.isagent !== 1) {
      this.$Modal.info({
                           
                            content: this.$t('uc.finance.agentnotice')
                        });
                        return

    }

    this.$router.push("/dataPage");

  }
}

}
</script>

<style lang="scss" scoped>
.login_form {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  }

  .header{
  position: fixed;
  top: 0;
  left: 0;
	right: 0;
  height: 60px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items:center;
  background:url(../assets/logo/barBottom.png);
  transform: rotate(0.5turn);
}

  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;

  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}



.btngroup{
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  width: 50%;
  margin-left: 20%;
}

.body{
  margin-top: 90px;
}


.login_btn.ivu-btn {
        width: 230px;
        background: url(../assets/logo/loginBtn.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 25px;
        outline: none;
        color: aliceblue;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 18px;
        border: none;
        &:focus {
          -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
          -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
          box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
        }
      }
</style>
