<template>
  <div>
    <h1>x</h1>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
    <p>hello Home</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
