import { render, staticRenderFns } from "./singlecard-list.vue?vue&type=template&id=9ecea72e&scoped=true"
import script from "./singlecard-list.vue?vue&type=script&lang=js"
export * from "./singlecard-list.vue?vue&type=script&lang=js"
import style0 from "./singlecard-list.vue?vue&type=style&index=0&id=9ecea72e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ecea72e",
  null
  
)

export default component.exports