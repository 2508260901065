<template>
   <Tab-bar>
      <Tab-bar-item path="/me" activeColor="#999999">
          <img slot="item-icon" src="../../assets/logo/asset_0.svg" alt="">
          <img slot="item-icon-active" src="../../assets/logo/asset_00.svg" alt="">
        <div slot="item-text">{{$t('common.tabbar1')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/" activeColor="#999999">
          <img slot="item-icon" src="../../assets/logo/asset_1.svg" alt="">
          <img slot="item-icon-active" src="../../assets/logo/asset_11.svg"  alt="" >
        <div slot="item-text" >{{$t('common.tabbar2')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/about1" activeColor="#999999">
          <img slot="item-icon"  src="../../assets/logo/asset_2.svg" alt="">
          <img slot="item-icon-active" src="../../assets/logo/asset_22.svg" alt="">
       <div slot="item-text" >{{$t('common.tabbar3')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/sports" activeColor="#999999">
        <img slot="item-icon" src="../../assets/logo/asset_3.svg" alt="">
        <img slot="item-icon-active" style="" src="../../assets/logo/asset_33.svg" alt="">
        <div slot="item-text">{{$t('common.tabbar4')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/tabCS" activeColor="#999999">
          <img slot="item-icon" src="../../assets/logo/asset_4.svg" alt="">
          <img slot="item-icon-active" src="../../assets/logo/asset_44.svg" alt="">
        <div slot="item-text">{{$t('common.tabbar5')}}</div>
      </Tab-bar-item>

    </Tab-bar>
</template>

<script>

import TabBar from './TabBar'
import TabBarItem from './TabBarItem'

export default {
  components:{
    TabBar,
    TabBarItem
  }
}
</script>

<style>

</style>
