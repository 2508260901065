<template>
    <div class="card-banner common-t">
        <div class="left">
            <Icon style="color: #ccc;font-size: 20px;margin-left: 15px;" :type="icon"/>
            <span class="l-txt">{{ txt }}</span>
        </div>
        <!--type 1 2 3-->
        <div class="right" v-if="type === 1 ">
            <div class="arrL arr" style="margin-right: 20px">
                <Icon style="font-size: 16px" type="md-arrow-round-back"/>
            </div>
            <div class="arrR arr">
                <Icon style="font-size: 16px" type="md-arrow-round-forward"/>
            </div>
        </div>
        <div class="right gun" v-if="type === 2">
            <div class="arrL arr" style="margin-right: 20px">
                <Icon style="font-size: 16px" type="md-menu"/>
            </div>
            <Dropdown @on-click="handleMenuClick">
                <a href="javascript:void(0)">
                    下拉菜单
                    <Icon type="ios-arrow-down"></Icon>
                </a>
                <template #list>
                    <DropdownMenu>
                        <DropdownItem name="1">总分盘</DropdownItem>
                        <DropdownItem name="2">获胜盘</DropdownItem>
                        <DropdownItem name="3">让分盘</DropdownItem>
                    </DropdownMenu>
                </template>
            </Dropdown>
        </div>
        <div class="right gun" v-if="type === 3" style="opacity: .5">
            <div class="arrL arr" style="margin-right: 20px">
                <Icon style="font-size: 16px" type="md-menu"/>
            </div>
            <Dropdown @on-click="handleMenuClick" disabled>
                <a href="javascript:void(0)">
                    赢家
                    <Icon type="ios-arrow-down"></Icon>
                </a>
<!--                <template #list>-->
<!--                    <DropdownMenu>-->
<!--                        <DropdownItem name="1">总分盘</DropdownItem>-->
<!--                        <DropdownItem name="2">获胜盘</DropdownItem>-->
<!--                        <DropdownItem name="3">让分盘</DropdownItem>-->
<!--                    </DropdownMenu>-->
<!--                </template>-->
            </Dropdown>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    props: ['txt', 'icon', 'type'],
    methods: {
        handleMenuClick(name) {
            console.log(name)
        },
    }
}
</script>

<style scoped lang="scss">
.card-banner {
    display: flex;
    justify-content: space-between;
    color: #fff;

    .left {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;

        .l-txt {
            display: inline-block;
            text-indent: 4px;
        }
    }

    .right {
        display: flex;
        justify-content: space-evenly;
        margin-right: 30px;
        padding: 5px 10px;
        border: 1px solid #aaa;
        border-radius: 20px;
        position: relative;
        color: #aaa;

        .arr {
            display: flex;
            align-items: center;
        }

        .arr:active {
            color: #aaa;
        }
    }

    .right:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        background-color: #aaa;
        top: 0;
    }

    .gun {
        margin-right: 16px;
        border: none;
    }

    .gun:before {
        width: 0;
    }

}

/deep/ .ivu-dropdown {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: #0E212E;
}

/deep/ .ivu-dropdown-rel {
    a {
        color: #fff;
    }
}
</style>
