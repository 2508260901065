<template>
  <div>
    <div class="nav-rights">

    <div class="headBox">
        <!-- <img src="../assets/netmage/arrowleft.png" alt="" style="width:20px;height:15px" @click="goback"> -->
        <span>{{  }}</span>
        <span  style="font-size:20px;color:#fff;">
          {{$t('common.service')}}
        </span>
        <span>{{  }}</span>
      </div>

      <div class="body">

          <img src="../assets/logo/sss1.png" width="60" alt="">
          <div style="display: flex; flex-direction:column">
          <span style="margin-left:20px;color: #fff;font-size: 20px;">{{$t('uc.finance.xx83')}}</span>
          <span style="margin-left:20px;color: #fff;font-size: 20px;">{{$t('uc.finance.xx82')}}</span>
          </div>

        </div>



      <div style="margin-top:20px; ">
          <CellGroup @on-click="gotoCS" >

                <Cell :title="$t('uc.finance.xx84')" :label="$t('uc.finance.xx92')" class="boxCs" @on-click="gotoCam" name="cam">
                    <img src="../assets/logo/service.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell :title="$t('uc.finance.xx86')" :label="$t('uc.finance.xx92')" class="boxCs" @on-click="gotoChn" name="chn">
                    <img src="../assets/logo/service.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell :title="$t('uc.finance.xx85')" :label="$t('uc.finance.xx92')" class="boxCs" @on-click="gotoVie" name="vie">
                    <img src="../assets/logo/service.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell :title="$t('uc.finance.xx145')" :label="$t('uc.finance.xx92')" class="boxCs" @on-click="gotoEng" name="eng">
                    <img src="../assets/logo/service.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell :title="$t('uc.finance.xx155')" :label="$t('uc.finance.xx92')" class="boxCs" @on-click="gotoTh" name="th">
                    <img src="../assets/logo/service.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell :title="$t('uc.finance.xx156')" :label="$t('uc.finance.xx92')" class="boxCs" @on-click="gotoIn" name="in">
                    <img src="../assets/logo/service.png" width="25" alt="" slot="icon" >
                </Cell>

            </CellGroup>

        </div>
        <Main-tab-bar></Main-tab-bar>
   </div>
 </div>
</template>

<script>
import MainTabBar from '../components/tabbar/MainTabBar'

export default {
   name:'TabCS',
    components: {
MainTabBar
  },
    data() {
    return {
      customerUrl:'',

    }
    },


    methods: {

      gotoCS(data){
        console.log(data);
        if (data == 'cam' || data == 'eng') {
          this.$router.push({name:"CustomService", params:{urlxx: 'https://kefu.jb.bet/chatIndex?kefu_id=kefu-en&ent_id=1'}}); //添加红包判断

        }else if (data == 'chn') {
          this.$router.push({name:"CustomService", params:{urlxx: 'https://kefu.jb.bet/chatIndex?kefu_id=kefu-cn&ent_id=1'}}); //添加红包判断

        }else if (data == 'vie') {
          this.$router.push({name:"CustomService", params:{urlxx: 'https://kefu.jb.bet/chatIndex?kefu_id=kefu-vn&ent_id=1'}}); //添加红包判断

        }else if (data == 'th') {
          this.$router.push({name:"CustomService", params:{urlxx: 'https://kefu.jb.bet/chatIndex?kefu_id=kefu-th&ent_id=1'}}); //添加红包判断

        }else if (data == 'in') {
          this.$router.push({name:"CustomService", params:{urlxx: 'https://kefu.jb.bet/chatIndex?kefu_id=kefu-in&ent_id=1'}}); //添加红包判断

        }
      },

      gotoCam(data){
        console.log(data);

      },

      gotoChn(data){
        console.log(data);
        // https://kefu.jb.bet/chatIndex?kefu_id=kefu-cn&ent_id=1&visitor_id=用户ID

      },
      gotoVie(data){
        console.log(data);

        // https://kefu.jb.bet/chatIndex?kefu_id=kefu-vn&ent_id=1&visitor_id=用户ID
      },
      gotoEng(){
console.log();
      },
      gotoTh(){
console.log();
      },
      gotoIn(){
console.log();
      },
    }

}
</script>

<style lang="scss" scoped>

.nav-rights {
  background-color: #0E212E;
  height: 55rem;
  position: relative;
  overflow: hidden;
}

.body{
  margin-top: 60px;
  width: 100%;
  color: #fff;
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  // align-items: center;
}

.boxCs{
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    background-color: #1B2C38;
    color: #fff;
    font-weight: 600;

}

</style>
