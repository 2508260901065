<script>
import {getToken} from "@/util/tools";

export default {
    name: "",
    data() {
        return {
            cityList: [],
            currencyType: '', //当前币种
            cType: 'USDT',
            ifLogin: false,
            country_en: "",
        }
    },
    created() {
        this.initToken()
        this.getCurrency()
    },
    computed: {
        member: function () {
            return this.$store.getters.member;
        },
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
    },
    methods: {
        gotoLogin() {
            this.$router.push("/login");
        },
        gotoReg() {
            this.$router.push("/MobileRegister");
        },
        initToken() {
            const t = getToken()
            const m = this.member && this.member !== 'null'
            if (t || m) {
                this.ifLogin = true
            } else {
                this.ifLogin = false
            }
        },
        getCurrency() {
            console.log('--------------------')
            console.log(this.member)
            if (this.member) {
                this.cType = JSON.parse(this.member).remark
            }
            this.$http.post(this.host + this.api.uc.personalWallet).then(response => {
                let resp = response.body;
                this.cityList = []
                if (resp.code == "0000") {
                    resp.data.forEach(item => {
                        this.cityList.push({
                            amount: item.amount,
                            ccy: item.ccy,
                            id: item.id,
                            uid: item.uid,
                            userNo: item.userNo,
                            value: item.ccy,
                            label: item.amount
                        })
                    });
                } else {
                    self.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.mesg
                    });
                }
            });
        }
    }
}
</script>

<template>
    <div class="header-box">
        <img v-if="country_en != 'zh-CN'" src="../assets/logo/logojb1.png" style="width: 100px;" alt="">
        <img src="../assets/logo/logojb1.png" style="width: 100px;height: 20px;" alt="" v-else>
        <!--币种-->
        <Select v-if="ifLogin" v-model="cType" style="width:160px;float: left">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }} {{ item.ccy }}
            </Option>
        </Select>
        <div class="l-r-btn" v-else>
            <div class="btn-login btn" @click="gotoLogin">{{ $t('uc.login.login') }}</div>
            <div class="btn-reg btn" @click="gotoReg">{{ $t('common.register') }}</div>
        </div>
        <div v-if="ifLogin">
            <Icon style="font-size: 24px;color: #fff" type="ios-person"/>
        </div>
        <div v-if="ifLogin">
            <Icon style="font-size: 22px;color: #fff" type="ios-notifications"/>
        </div>
    </div>
</template>

<style scoped lang="scss">
.header-box {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    display: flex;
    background: #1A2C38;
    box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
}

.l-r-btn {
    display: flex;

    .btn-login {
        margin-right: 10px;
        padding: 5px 10px;
        border-radius: 4px;
    }

    .btn {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
    }

    .btn-reg {
        background-color: #1375E1;
        padding: 5px 10px;
        border-radius: 4px;
    }
}

/deep/ .ivu-select {
    height: 80%;

    .ivu-select-selection {
        height: 100%;
        border: none;
        border-radius: 0;

        & > div {
            height: 100%;
            background-color: #0E212E;
            display: flex;

            justify-content: center;
            align-items: center;

            .ivu-select-selected-value {
                height: 100%;
                color: #fff;
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
