<template>
    <div class="login_form">
        <div class="headB">
            <img src="../assets/logo/back_1.png" alt="" style="width:20px;height:20px;" @click="goback">
            <span style="font-size:20px;"></span>
        </div>
        <!--        <div style="color: #fff;margin-top:50px; font-size: x-large;margin-left: -40%;">{{ $t('uc.finance.xx162') }}-->
        <!--        </div>-->
        <div class="login_right">
<!--            <img src="../assets/logo/jinbet.png" width="120" style="margin-top: 40px;">-->
            <p class="login">{{ $t('uc.login.forget') }}</p>
            <Form ref="formInline" :model="formInline" :rules="ruleInline" inline style="margin-top: 10%;">
                <FormItem prop="user" class="captcha">
                    <Input type="text" size="large" v-model="formInline.user" :placeholder="$t('uc.login.usertip')">
                        <Select v-model="country" slot="prepend" style="width: 85px;">
                            <Option value="+855" label="+855"><span>+855</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.cambodia') }}</span></Option>
                            <Option value="+855" label="+855"><span>+855</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.cambodia') }}</span></Option>
                            <Option value="+86" label="+86"><span>+86</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.china') }}</span></Option>
                            <Option value="+65" label="+65"><span>+65</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.singapore') }}</span></Option>
                            <Option value="+82" label="+82"><span>+82</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.korea') }}</span></Option>
                            <Option value="+81" label="+81"><span>+81</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.japan') }}</span></Option>
                            <Option value="+66" label="+66"><span>+66</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.thailand') }}</span></Option>
                            <Option value="+84" label="+84"><span>+84</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.vietnam') }}</span></Option>
                            <Option value="+62" label="+62"><span>+62</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.india') }}</span></Option>
                            <Option value="+852" label="+852"><span>+852</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.hk') }}</span></Option>
                            <Option value="+60" label="+60"><span>+60</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.malaysia') }}</span></Option>
                            <Option value="+886" label="+886"><span>+886</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.taiwan') }}</span></Option>
                            <Option value="+673" label="+673"><span>+673</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.wenlai') }}</span></Option>
                        </Select>
                    </Input>
                </FormItem>
                <FormItem prop="code" class="captcha">
                    <Input type="text" size="large" v-model="formInline.code" :placeholder="$t('uc.forget.smscode')">
                    </Input>
                    <input id="sendCode" size="large" @click="sendCode();" type="Button"
                           style="margin-top:10px;height: 100%;color:#fff;border:none"
                           shape="circle" :value="sendcodeValue" :disabled="codedisabled"/>
                </FormItem>
                <FormItem prop="password" class="captcha">
                    <Input type="password" size="large" v-model="formInline.password"
                           :placeholder="$t('uc.forget.newpwd')" password>
                    </Input>
                </FormItem>
                <FormItem prop="repassword" class="captcha">
                    <Input type="password" size="large" v-model="formInline.repassword"
                           :placeholder="$t('uc.forget.confirmpwd')" password>
                    </Input>
                </FormItem>
                <FormItem style="margin-bottom:15px; margin-top: 20px;">
                    <Button class="login_btn" @click="handleSubmit('formInline')">{{ $t('uc.forget.save') }}</Button>
                </FormItem>
            </Form>
        </div>
        <Modal v-model="modal1" :mask-closable="false">
            <p slot="header" style="text-align:center">{{ $t('uc.regist.modaltitle') }}</p>
            <div style="text-align:center">
                <div>
                    <div id="captcha">
                        <p id="wait" class="show">{{ $t('uc.login.validatecodeload') }}......</p>
                    </div>
                </div>
                <p id="notice" class="hide">{{ $t('uc.login.validatemsg') }}</p>
            </div>
            <p slot="footer"></p>
        </Modal>
    </div>
</template>


<style scoped lang="scss">

.memo1text {
    color: aliceblue;
    font-size: medium;
    font-weight: 600;
}

/deep/ .ivu-input {
    border: 0;
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    color: #fff;
}

.cardx {
    width: 30%;
    background-color: $color-text-l;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/deep/ .ivu-input::-moz-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

/deep/ .ivu-input::-webkit-input-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

/deep/ .ivu-input::-ms-input-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

.captcha {
    width: 85%;
    margin-left: 10px;
}

.headB {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: $head;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    z-index: 200;
    width: 100%;
}

.login_form {
    background: url(../assets/logo/login_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .login_right {
        background-color: #1A2C38;
        padding: 10px;
        width: 94%;
        border-radius: 10px;
        height: 420px;
        .login{
            color: #fff;
            font-weight: 600;
            font-size: 18px;
        }

        form.ivu-form.ivu-form-label-right.ivu-form-inline {
            .ivu-form-item {
                width: 85%;
                .ivu-form-item-content {

                    .login_btn.ivu-btn {
                        width: 100%;
                        height: 42px;
                        border-radius: 4px;
                        outline: none;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-size: 15px;
                        border: none;
                        color: #000;
                        font-weight: 600;
                        background-color: #00E700;

                        &:focus {
                            -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
                            -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
                            box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
                        }
                    }

                    .ivu-input-wrapper.ivu-input-type {
                        .ivu-input {
                            border: 1px solid red;
                        }
                    }

                    #sendCode {
                        position: absolute;
                        border: 1px solid #fff;
                        background: transparent;
                        top: -10px;
                        outline: none;
                        right: 0;
                        width: 30%;
                        color: #f0ac19;
                        cursor: pointer;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}

/* 验证码 */

.show {
    display: block;
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    // background: url(../assets/netmage/xxyz.png);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    color: $color-text-tag;
    z-index: 200;
    width: 100%;
}

/deep/ .ivu-input-group-prepend {
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    color: #fff;
}

.ivu-form-item-error .ivu-select-arrow {
    color: #808695;
}


.login_right .ivu-select-dropdown {
    background: #1c2a32;
}

/deep/ .ivu-select {
    color: #fff;
}

/deep/ .ivu-input {
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    color: #fff;
}

.hide {
    display: none;
}

#notice {
    color: red;
}

#wait {
    text-align: left;
    color: #666;
    margin: 0;
}

.ivu-btn-group > .ivu-btn.active, .ivu-btn-group > .ivu-btn:active, .ivu-btn-group > .ivu-btn:hover {
    background: transparent !important;
    border-color: transparent !important;
    color: #f0ac19 !important;
}

.ivu-btn-group > .ivu-btn:focus {
    box-shadow: none !important;
}

.ivu-btn-group > .ivu-btn.ivu-btn-default {
    background: transparent !important;
    color: #828ea1;
}

.ivu-btn-group > .ivu-btn {
    font-size: 16px;
}

/deep/ .ivu-form-item-content {
    border: 1px solid #2f4553;
    border-radius: 8px;
}
</style>
<script>

export default {
    data() {
        // const validateUser = (rule, value, callback) => {
        //   if (this.changeActive == 0) {
        //     var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        //     if (value == "") {
        //       callback(new Error(this.$t("uc.regist.teltip")));
        //     } else if (!reg.test(this.formInline.user)) {
        //       callback(new Error(this.$t("uc.regist.telerr")));
        //     } else {
        //       callback();
        //     }
        //   } else {
        //     var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
        //     reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/;
        //     if (value == "") {
        //       callback(new Error(this.$t("uc.regist.emailtip")));
        //     } else if (!reg.test(this.formInline.user)) {
        //       callback(new Error(this.$t("uc.regist.emailerr")));
        //     } else {
        //       callback();
        //     }
        //   }
        // };
        const validateRepassword = (rule, value, callback) => {
            if (value === "") {
                callback(new Error(this.$t("uc.forget.pwdvalidate1")));
            } else if (value !== this.formInline.password) {
                callback(new Error(this.$t("uc.forget.pwdvalidate2")));
            } else {
                callback();
            }
        };
        return {
            codedisabled: false,
            sendcodeValue: this.$t("uc.regist.sendcode"),
            captchaObj: null,
            modal1: false,
            country: "+855",
            country1: '',
            buttonLists: [
                {
                    text: this.$t("uc.forget.resettelpwd")
                },
                {
                    text: this.$t("uc.forget.resetemailpwd")
                }
            ],
            changeActive: 0,
            countdown: 60,
            formInline: {
                user: "",
                code: "",
                password: "",
                repassword: "",
                country: "",
            },
            ruleInline: {
                // user: [{ validator: validateUser, trigger: "blur" }],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}],
                password: [
                    {
                        required: true,
                        message: this.$t("uc.forget.newpwdtip"),
                        trigger: "blur"
                    },
                    {
                        type: "string",
                        min: 6,
                        message: this.$t("uc.forget.pwdvalidate3"),
                        trigger: "blur"
                    }
                ],
                repassword: [
                    {validator: validateRepassword, trigger: "blur"},
                    {
                        type: "string",
                        min: 6,
                        message: this.$t("uc.forget.pwdvalidate3"),
                        trigger: "blur"
                    }
                ]
            },
            key: "",
            code: "",
            timercode: ''
        };
    },
    watch: {
        changeActive: function () {
            this.$refs["formInline"].resetFields();
            // if (val == 0) this.initGtCaptcha();
        }
    },
    created: function () {
        this.init();
        // this.actives(this.changeActive);
    },
    computed: {
        isLogin: function () {
            return this.$store.getters.isLogin;
        }
    },
    methods: {
        goback() {
            //获取个人安全信息
            this.$router.back(-1)

        },
        init() {
            // if (this.isLogin) {
            //   this.$router.push("/");
            // }
            // this.initGtCaptcha();
        },
        // actives: function(index) {
        //   this.changeActive = index;
        //   if (this.changeActive == 0) {
        //     this.key = this.$t("uc.forget.telno");
        //     this.code = this.$t("uc.forget.smscode");
        //     this.ruleInline.user[0].message = this.$t("uc.forget.teltip");
        //     this.ruleInline.code[0].message = this.$t("uc.forget.smscodetip");
        //   } else {
        //     this.key = this.$t("uc.forget.email");
        //     this.code = this.$t("uc.forget.emailcode");
        //     this.ruleInline.user[0].message = this.$t("uc.forget.emailtip");
        //     this.ruleInline.code[0].message = this.$t("uc.forget.emailcodetip");
        //   }
        // },

        // initGtCaptcha() {
        //   var that = this;
        //   this.$http.get(this.host + this.api.uc.captcha).then(function(res) {
        //     window.initGeetest(
        //       {
        //         // 以下配置参数来自服务端 SDK
        //         gt: res.body.gt,
        //         challenge: res.body.challenge,
        //         offline: !res.body.success, //表示用户后台检测极验服务器是否宕机
        //         new_captcha: res.body.new_captcha, //用于宕机时表示是新验证码的宕机
        //         product: "popup",
        //         width: "100%"
        //       },
        //       function(captchaObj) {
        //         captchaObj.onSuccess(function() {
        //           that._captchaResult = captchaObj.getValidate();
        //           that.afterValidate();
        //         });
        //         // 将验证码加到id为captcha的元素里，同时会有三个input的值用于表单提交
        //         captchaObj.appendTo("#captcha");
        //         that.captchaObj = captchaObj;
        //         captchaObj.onReady(function() {
        //           $("#wait").hide();
        //         });
        //       }
        //     );
        //   });
        // },
        // initGtCaptcha() {
        //   this.$http.get(this.host + this.api.uc.captcha).then(function(res) {
        //     window.initGeetest(
        //       {
        //         // 以下配置参数来自服务端 SDK
        //         gt: res.body.gt,
        //         challenge: res.body.challenge,
        //         offline: !res.body.success, //表示用户后台检测极验服务器是否宕机
        //         new_captcha: res.body.new_captcha, //用于宕机时表示是新验证码的宕机
        //         product: "bind",
        //         width: "100%",
        // 	lang: "zh_CN"
        //       },
        //       this.handler
        //     );
        //   });
        // },

        emailReset() {
            this.modal1 = false;
            var params = {};

            let xy = this.country.substring(1, this.country.length)

            params["mobilePhone"] = this.formInline.user;
            params["areaCode"] = xy;
            params["sendType"] = 2;

            this.$http.post(this.host + this.api.uc.captcha, params).then(response => {
                var resp = response.body;
                if (resp.code == '0000') {
                    this.$Notice.success({
                        title: this.$t("common.tip"),
                        desc: resp.mesg
                    });

                } else {
                    this.countdown = 0
                    this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.mesg
                    });
                }
            });
        },
        afterValidate() {
            this.modal1 = false;

            var params = {};
            params["account"] = this.formInline.user;
            params["geetest_challenge"] = this._captchaResult.geetest_challenge; //极验验证二次验证表单数据 chllenge
            params["geetest_validate"] = this._captchaResult.geetest_validate; //极验验证二次验证表单数据 validate
            params["geetest_seccode"] = this._captchaResult.geetest_seccode; //极验验证二次验证表单数据 seccode
            this.$http.post(this.host + "/uc/mobile/reset/code", params).then(response => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.settime();
                    this.$Notice.success({title: this.$t("common.tip"), desc: resp.message});
                } else {
                    this.$Notice.error({title: this.$t("common.tip"), desc: resp.message});
                }
            });

        },

// areaCode (string, optional): 手机区号 ,
// code (string, optional): 验证码 ,
// mobile (string, optional): 手机号 ,
// newPwd (string, optional): 新密码

        handleSubmit(name) {
            this.$refs[name].validate(valid => {
                if (valid) {
                    var params = {};

                    let xy = this.country.substring(1, this.country.length)

                    params["mobile"] = this.formInline.user;
                    params["code"] = this.formInline.code;
                    params["areaCode"] = xy;
                    params["newPwd"] = this.formInline.password;

                    this.$http.post(this.host + this.api.uc.announcement, params)
                        .then(response => {

                            var resp = response.body;
                            if (resp.code == '0000') {
                                this.$Notice.success({
                                    title: this.$t("common.tip"),
                                    desc: '重置成功'
                                });
                                clearInterval(this.timercode);
                                this.$router.push("/");
                            } else {
                                this.$Notice.error({
                                    title: this.$t("common.tip"),
                                    desc: resp.mesg
                                });
                            }
                        });

                    // this.$Message.success(this.$t('uc.forget.resetpwdsuccess'));
                }
            });
        },
        settime() {
            this.sendcodeValue = this.countdown;
            this.codedisabled = true;
            let that = this
            this.timercode = setInterval(() => {
                this.countdown--;
                this.sendcodeValue = this.countdown;
                if (this.countdown <= 0) {
                    clearInterval(that.timercode);
                    this.codedisabled = false;
                    this.sendcodeValue = this.$t("uc.regist.sendcode");
                    this.countdown = 60;
                }
            }, 1000);
        },
        sendCode() {
            this.settime();
            var mobilePhone = this.formInline.user;
            if (mobilePhone == "") {
                this.countdown = 0;
                this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: this.ruleInline.user[0].message
                });
                return;
            }
            this.emailReset();
        },

        // openValidateModal() {
        //   if (this.captchaObj != null && this.captchaObj.reset) {
        //     this.captchaObj.reset();
        //   } else {
        //     this.initGtCaptcha();
        //   }
        //   this.modal1 = true;
        // }
    }
};
</script>
