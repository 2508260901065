import { render, staticRenderFns } from "./singlePage.vue?vue&type=template&id=cf1d824a&scoped=true"
import script from "./singlePage.vue?vue&type=script&lang=js"
export * from "./singlePage.vue?vue&type=script&lang=js"
import style0 from "./singlePage.vue?vue&type=style&index=0&id=cf1d824a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf1d824a",
  null
  
)

export default component.exports