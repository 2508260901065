<template>

<div class="login_form ">

<div class="headBox">
  <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
    {{$t('uc.finance.promotion')}}
  </span>
  <span>{{  }}</span>
</div>
<Card style="width:90%;margin-left: 5%;margin-top: 50px;height: 300px;">
        <p slot="title" style="font-size: small;">
          {{$t('uc.finance.xx40')}}
        </p>

        <div class="login_right">

          <div class="uclass">
                        <span>{{appSrc}}</span>
                      
                      <!-- <Button  disabled style="border-radius: 25px;background-color: transparent;">{{appSrc}}</Button>                        -->
                   <img src="../assets/logo/share.png" width="20" alt="" v-clipboard:copy="appSrc" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">
                      <!-- <Button style="font-size: 14px;color: #000; font-weight: bold; border-radius: 25px;  background-color: #e5b932; border: none;" v-clipboard:copy="appSrc" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button> -->
                    
                    </div>
    <vue-qr :logo-src=logoSrc
     :size="100"
     :margin="10"
     :auto-color="true"
     :dot-scale="1"
     :text="appSrc"
     colorDark="black"
     colorLight="white"
     ref='Qrcode'
     style="background-color:azure;border-radius: 15px;margin-top: 20px;margin-bottom: 20px;"
/>

<div>
  <Button  @click="downloadImg">{{$t('uc.finance.promotion2')}}</Button>
</div>

<!-- <div style="margin-top:20px; color:aliceblue; font-size: larger;">
  <span>{{$t('uc.finance.promotion1')}}</span>
  <br>
  <span>{{membercode}}</span>
</div> -->

  </div>
      </Card>

      <Card style="width:90%;margin-left: 5%;margin-top: 10px;">
        <p slot="title" style="font-size: small;">
          {{$t('uc.finance.xx41')}}
        </p>
        <List item-layout="vertical" size="small">
        <ListItem v-for="item in urlList" :key="item.title">
            <!-- <ListItemMeta  :title="item.title"/>
            <template slot="action">
              <Button type="primary"></Button>       
            </template> -->
            <div  style="display: flex;justify-content: space-between;align-items: center;color: darkgray;">
              <span>{{ item.title }}</span>
            <Button type="primary" @click="chooseUrl(item)">{{$t('uc.finance.choose')}}</Button> 
            </div>
            
        </ListItem>
    </List>
      </Card>



</div>

 
</template>

<script>
  import VueQr from 'vue-qr'
  export default {
    components: {VueQr},

      data () {
  return{
    logoSrc: require("../assets/netmage/1024.png"),
    urlList:[
    {
                        title: 'https://www.jb.bet/?',
                      
                    },
                    {
                        title: 'http://test.jb88.bet/?',
                      
                    },
    ],
  appSrc: '',
  membercode: ''
  }
        
      },
  
     
      created() {
      this.membercode =  this.$store.getters.member.userNo

      this.appSrc = 'http://test.jbbet.io/?agentId='+this.membercode
  // this.logoSrc = '../assets/netmage/1024.png'
  },

methods: {
  goback() {
      //获取个人安全信息
      this.$router.back(-1)
     
    },
    chooseUrl(data){
console.log(data.title);

this.appSrc = data.title + 'agentId='+this.membercode
    },
  downloadImg (link_id) {
    console.log(link_id)
    const iconUrl = this.$refs['Qrcode'].$el.src
    const a = document.createElement('a')
    const event = new MouseEvent('click')
    a.download = link_id
    a.href = iconUrl
    a.dispatchEvent(event)
  },
  onCopy(e) {
      this.$Message.success(
        this.$t("uc.finance.copysuccess") + e.text
      );
    },
    onError() {
      this.$Message.error(this.$t("uc.finance.copyfailed"));
    },

  }


  }
  </script>

<style lang="scss" scoped>
.login_form {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  }

   .login_right {
    
    position: absolute;
    background: #fff;
    width: 100%;
  
  }

  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;

  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}

.uclass {
  color:rgb(133, 128, 128);
  font-size: 10px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  // background:url(../assets/netmage/usdt_bb.png); 
  // background-size: contain;
  // width: 100%;
}

</style>