<template>
    <div class="login_form">
        <div class="headB">
            <img src="../assets/logo/back_1.png" alt="" style="width:20px;height:20px;" @click="goback">
            <span style="font-size:20px;"></span>
        </div>
<!--        <div style="color: #fff;margin-top:50px; font-size: x-large;margin-left: -40%;">{{ $t('uc.finance.xx162') }}-->
<!--        </div>-->
        <div class="login_right">
<!--            <img src="../assets/logo/jinbet.png" width="120" style="margin-top: 40px;">-->
            <p class="reg"> {{ $t('uc.regist.regist') }}</p>
            <Form v-if="allowRegister" ref="formInline" :model="formInline" :rules="ruleInline" inline
                  style="margin-top: 5%;">
                <FormItem prop="user" class="captcha">
                    <Input type="text" size="large" v-model="formInline.user" :placeholder="$t('uc.login.usertip')">
                        <Select v-model="country" slot="prepend" style="width:85px;">
                            <Option value="+855" label="+855"><span>+855</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.cambodia') }}</span></Option>
                            <Option value="+86" label="+86"><span>+86</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.china') }}</span></Option>
                            <Option value="+65" label="+65"><span>+65</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.singapore') }}</span></Option>
                            <Option value="+82" label="+82"><span>+82</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.korea') }}</span></Option>
                            <Option value="+81" label="+81"><span>+81</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.japan') }}</span></Option>
                            <Option value="+66" label="+66"><span>+66</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.thailand') }}</span></Option>
                            <Option value="+44" label="+44"><span>+44</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.uk') }}</span></Option>
                            <Option value="+84" label="+84"><span>+84</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.vietnam') }}</span></Option>
                            <Option value="+62" label="+62"><span>+62</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.india') }}</span></Option>
                            <Option value="+39" label="+39"><span>+39</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.italy') }}</span></Option>
                            <Option value="+852" label="+852"><span>+852</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.hk') }}</span></Option>
                            <Option value="+60" label="+60"><span>+60</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.malaysia') }}</span></Option>
                            <Option value="+886" label="+886"><span>+886</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.taiwan') }}</span></Option>
                            <Option value="+673" label="+673"><span>+673</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.wenlai') }}</span></Option>
                        </Select>
                    </Input>
                </FormItem>

                <FormItem prop="code" class="captcha" v-show="showCode">
                    <Input type="text" size="large" v-model="formInline.code" :placeholder="$t('uc.regist.smscode')">
                    </Input>
                    <input id="sendCode" @click="sendCode();" type="Button"
                           style="margin-top:10px;height: 100%;color:#fff;border:none"
                           shape="circle" :value="sendcodeValue" :disabled='codedisabled'/>

                </FormItem>
                <FormItem prop="password" class="captcha">
                    <Input type="password" size="large" v-model="formInline.password" password
                           :placeholder="$t('uc.regist.pwd')">
                    </Input>
                </FormItem>
                <FormItem prop="repassword" class="captcha">
                    <Input type="password" size="large" v-model="formInline.repassword" password
                           :placeholder="$t('uc.regist.repwd')">
                    </Input>
                </FormItem>
                <FormItem prop="promotion" class="captcha">
                    <Input type="text" size="large" v-model="formInline.promotion">
                        <span slot="prepend">{{ $t('uc.regist.promotion') }} :</span>
                    </Input>
                </FormItem>
                <!-- <div class="check-agree" style="">
                  <label>
                    <Checkbox v-model="agree">{{$t('uc.regist.agreement')}}</Checkbox>
                  </label>
                  <a v-if="lang=='zh_CN'" href="/helpdetail?cate=1&id=5&cateTitle=常见问题" target="_blank" style="">《{{$t('uc.regist.userprotocol')}}》</a>
                  <a v-if="lang=='zh_CN'" href="/helpdetail?cate=1&id=35&cateTitle=Privacy Policy" target="_blank" style="">《{{$t('uc.regist.userprotocol')}}》</a>
                </div> -->
                <FormItem>
                    <Button class="register_btn" @click="handleSubmit('formInline')" :disabled="registing">
                        {{ $t('uc.regist.regist') }}
                    </Button>
                </FormItem>

                <div style="margin-top: 5px;margin-left: -10px;">
                    <span style="color: #b1bad3;">{{ $t('common.login1') }}</span>
                    <router-link to="/login" style="color: #fff;">{{ $t('uc.login.login') }}
                    </router-link>

                </div>
            </Form>
        </div>

    </div>
</template>
<style scoped lang="scss">
#page4 {
    background: transparent;
    padding: 80px 0 80px 0;

    ul {
        width: 99%;
        margin: 0 auto;

        li {
            flex: 0 0 25%;
            display: inline-block;
            width: 100%;
            padding: 0 15px;

            div {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                vertical-align: middle;
                text-align: center;
                margin: 0 auto;

                img {
                    height: 125px;
                    margin-top: 8px;
                }
            }

            p {
                font-size: 14px;
                margin: 20px 0;
                text-align: center;
                color: #828ea1;
            }

            p.title {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}

/deep/ .ivu-input-group-prepend {
    color: #fff;
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    font-size: 12px;
}

.ivu-form-item-error .ivu-select-arrow {
    color: #808695;
}

/deep/ .ivu-select {
    color: #fff;
}

/deep/ .ivu-input-wrapper .ivu-input-wrapper-large .ivu-input-type-text {
    border-radius: 8px;
    border: 0px;
}

.login_right .ivu-select-dropdown {
    background: #1c2a32;
}

/deep/ .ivu-input {
    // border: 1px;
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    color: #fff;
}

/deep/ .ivu-input::-moz-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

/deep/ .ivu-input::-webkit-input-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

/deep/ .ivu-input::-ms-input-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

.captcha {
    width: 85%;
    margin-left: 10px;
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    justify-content: space-between;

    color: $color-text-tag;
    z-index: 200;
    width: 100%;
}

.cardx {
    width: 30%;
    background-color: $color-text-l;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.memo1text {
    color: aliceblue;
    font-size: medium;
    font-weight: 600;
}

.login_form {
    background-image: url(../assets/logo/login_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .login_right {
        background-color: #1A2C38;
        padding: 10px;
        width: 94%;
        border-radius: 10px;
        position: absolute;
        height: 500px;
        .reg{
            color: #fff;
            font-weight: 600;
            font-size: 18px;
        }
        form.ivu-form.ivu-form-label-right.ivu-form-inline {
            .ivu-form-item {
                width: 85%;
                .ivu-form-item-content {
                    .register_btn.ivu-btn {
                        width: 100%;
                        height: 42px;
                        border-radius: 4px;
                        outline: none;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                        font-size: 15px;
                        border: none;
                        color: #000;
                        font-weight: 600;
                        background-color: #00E700;

                        &:focus {
                            -moz-box-shadow: 2px 2px 5px transparent, -2px -2px 4px transparent;
                            -webkit-box-shadow: 2px 2px 5px transparent, -2px -2px 4px transparent;
                            box-shadow: 2px 2px 5px transparent, -2px -2px 4px transparent;
                        }
                    }

                    .ivu-input-wrapper.ivu-input-type {
                        .ivu-input {
                            border: 1px solid red;
                        }
                    }

                    #sendCode {
                        position: absolute;
                        //border: 1px solid #fff;
                        background: transparent;
                        top: -10px;
                        outline: none;
                        right: 0;
                        width: 30%;
                        color: #f0ac19;
                        cursor: pointer;
                        border-radius: 8px;
                    }
                }
            }

            .check-agree {
                color: #979797;
                display: inline-block;
                line-height: 30px;
                font-size: 12px;
                cursor: default;

                a {
                    color: #f0ac19;
                    margin-left: -10px;
                }

                .ivu-checkbox-wrapper.ivu-checkbox-wrapper-checked {
                    .ivu-checkbox.ivu-checkbox-checked {
                        .ivu-checkbox-inner {
                            border: 1px solid #f0ac19;
                            background-color: #f0ac19;
                        }
                    }
                }
            }
        }
    }
}

.login_title {
    text-align: center;
    height: 80px;
    font-size: 25px;
}

.headB {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: $head;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    z-index: 200;
    width: 100%;
}

#captcha {
    width: 100%;
    display: inline-block;
}

.show {
    display: block;
}

.hide {
    display: none;
}

#notice {
    color: red;
}

#wait {
    text-align: left;
    color: #666;
    margin: 0;
}

.tel-title {
    font-size: 25px;
}

.login_left {
    display: none;
}
/deep/ .ivu-form-item-content {
    border: 1px solid #2f4553;
    border-radius: 8px;
}
</style>
<script>
//   import gtInit from '../../assets/js/gt.js';

export default {
    data() {
        const validateUser = (rule, value, callback) => {
            if (this.changeActive == 0) {
                // var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (value == "") {
                    callback(new Error(this.$t("uc.regist.teltip")));
                } else {
                    callback();
                }
            } else {
                var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
                reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/;
                if (value == "") {
                    callback(new Error(this.$t("uc.regist.emailtip")));
                } else if (!reg.test(this.formInline.user)) {
                    callback(new Error(this.$t("uc.regist.emailerr")));
                } else {
                    callback();
                }
            }
        };
        const validateRepassword = (rule, value, callback) => {
            if (value === "") {
                callback(new Error(this.$t("uc.regist.confirmpwdtip")));
            } else if (value !== this.formInline.password) {
                callback(new Error(this.$t("uc.regist.confirmpwderr")));
            } else {
                callback();
            }
        };
        return {
            country: "+855",
            country1: "台湾",
            codedisabled: false,
            sendcodeValue: this.$t("uc.regist.sendcode"),
            isRegister: false,
            ticket: "",
            randStr: "",
            registing: false,
            captchaObj: null,
            modal1: false,
            agree: true,
            allowRegister: true,
            buttonLists: [
                {
                    text: this.$t("uc.regist.telregist")
                },
                {
                    text: this.$t("uc.regist.emailregist")
                }
            ],
            areas: [],
            changeActive: 0,
            showCode: true,
            countdown: 60,
            formInline: {
                username: "",
                country: "",
                user: "",
                code: "",
                areaCode: "",
                password: "",
                repassword: "",
                promotion: ""
            },
            ruleInline: {
                user: [{validator: validateUser, trigger: "blur"}],
                code: [
                    {
                        required: true,
                        message: this.$t("uc.regist.smscodetip"),
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: this.$t("uc.regist.pwdtip"),
                        trigger: "blur"
                    },
                    {
                        type: "string",
                        min: 6,
                        message: this.$t("uc.regist.pwdmsg"),
                        trigger: "blur"
                    }
                ],
                repassword: [{validator: validateRepassword, trigger: "blur"}]
            },
            key: "",
            code: "",
            timercode: ''
        };
    },
    watch: {
        changeActive: function () {
            this.$refs["formInline"].resetFields();
            // if (val == 0) this.initGtCaptcha();
        },
        lang: function () {
            this.updateLangData();
        }
    },
    computed: {
        lang: function () {
            return this.$store.state.lang;
        },
        isLogin: function () {
            return this.$store.getters.isLogin;
        }
    },
    created: function () {
        window.scrollTo(0, 0);
        // var oDiv = document.getElementById("example-navbar-collapse");
        // oDiv && (oDiv.className = "navbar-collapse collapse");
        this.init();
        this.actives(this.changeActive);
        if (this.$store.getters.currencyType != undefined && this.$store.getters.currencyType != "" && this.$store.getters.currencyType != null) {
            this.formInline.promotion = this.$store.getters.currencyType;
        } else {
            this.formInline.promotion = "";
        }


    },
    methods: {
        goback() {
            //获取个人安全信息
            this.$router.back(-1)

        },
        updateLangData() {
            this.buttonLists = [
                {
                    text: this.$t("uc.regist.telregist")
                },
                {
                    text: this.$t("uc.regist.emailregist")
                }
            ];

            if (this.changeActive == 0) {
                this.key = this.$t("uc.regist.telno");
            } else {
                this.key = this.$t("uc.regist.email");
            }
        },
        init() {
            // this.$store.commit("navigate", "nav-other");
            // this.$store.state.HeaderActiveName = "0";
            // if (this.isLogin) {
            //   this.$router.push("/");
            // }
            // window.document.title = (this.lang == "zh-CN" ? "新用户注册 - " : "New Register - ");
            // this.getAreas();
            // this.initGtCaptcha();
        },

        onAreaChange(value) {
            for (var i = 0; i < this.areas.length; i++) {
                if (this.areas[i].zhName == value) {
                    this.formInline.areaCode = this.areas[i].areaCode;
                }
            }
        },
        getAreas() {
            this.$http.post(this.host + this.api.common.area).then(response => {
                var resp = response.body;
                this.areas = resp.data;
                this.formInline.country = this.areas[0].zhName;
                this.formInline.areaCode = this.areas[0].areaCode;
            });
        },
        actives: function (index) {
            this.changeActive = index;
            if (this.changeActive == 0) {
                this.showCode = true;
                this.key = this.$t("uc.regist.telno");
                this.ruleInline.code = [
                    {
                        required: true,
                        message: this.$t("uc.regist.smscodetip"),
                        trigger: "blur"
                    }
                ];
            } else {
                this.showCode = false;
                this.key = this.$t("uc.regist.email");
                this.ruleInline.code = [];
            }
        },


        handleSubmit(name) {

            let that = this

            this.$refs[name].validate(valid => {
                if (valid) {
                    if (this.agree == true) {
                        if (this.changeActive == 1) {
                            this.openValidateModal();
                        } else {
                            // if (this.isRegister) {
                            this.registing = true;
                            var params = {};
                            let xy = this.country.substring(1, this.country.length)
                            params["mobile"] = this.formInline.user;
                            // params["username"] = this.formInline.username + this.formInline.user;
                            params["password"] = this.formInline.password;
                            params["agentId"] = this.formInline.promotion
                            // 邀请码
                            params["mask"] = this.formInline.code;
                            params["code"] = xy;//"中国";
                            params["deviceInfo"] = "3e3c41b2-62fd-48b3-b51e-fd202c19b456";
                            params['ccy'] = 'USDT'

                            this.$http
                                .post(this.host + this.api.uc.register, params)
                                .then(response => {
                                    this.registing = false;
                                    var resp = response.body;
                                    if (resp.code == "0000") {
                                        // if (
                                        //   this.formInline.superType == "1" ||
                                        //   this.formInline.superType == "2"
                                        // ) {
                                        //   this.$Notice.success({
                                        //     title: this.$t("common.tip"),
                                        //     desc: resp.mesg
                                        //   });
                                        //   var that = this;
                                        //   // setTimeout(() => {
                                        //     that.$router.push("/about");
                                        //   // }, 3000);
                                        // } else {
                                        this.$Notice.success({
                                            title: this.$t("common.tip"),
                                            desc: resp.mesg
                                        });
                                        this.$store.commit("setMember", resp.data);
                                        this.$store.commit('setCode', xy);
                                        localStorage.setItem('TOKEN', resp.data.token);
                                        // if (this.$route.query.key != null && this.$route.query.key != "") {
                                        //   localStorage.setItem("USERKEY", this.$route.query.key);
                                        // }
                                        // var that1 = this;
                                        clearInterval(that.timercode);
                                        // setTimeout(() => {
                                        // this.$http.post(this.host + this.api.uc.joinList).then(res =>{  //请求活动接口
                                        //     let result = res.body
                                        //     if (result.code == '0000') {
                                        //         if(result.data != null && result.data.length > 0){
                                        //             let activityId =  result.data[0].activityId
                                        //             let activityType =  result.data[0].activityType
                                        //             that1.$router.push({name:"About", params:{activityId, activityType}}); //添加红包判断
                                        //         }else

                                        that.$router.replace({path: '/'})
                                        //     } else {
                                        //         that1.$Notice.error({
                                        //             title: this.$t("common.tip"),
                                        //             desc:resp.mesg
                                        //         });
                                        //     }

                                        // })
                                        // that1.$router.push("/about");
                                        // }, 3000);
                                        // }
                                    } else {
                                        this.$Notice.error({
                                            title: this.$t("common.tip"),
                                            desc: resp.mesg
                                        });
                                    }
                                });
                            // }
                            //  else {
                            //   this.$Notice.error({
                            //     title: this.$t("common.tip"),
                            //     desc: "请输入正确的验证码"
                            //   });
                            // }
                        }
                    } else {
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: this.$t("uc.regist.agreementtip")
                        });
                    }
                } else {
                    console.log("999");
                }
            });
        },
        settime() {
            this.sendcodeValue = this.$t("uc.regist.resendcode") + this.countdown + ")";
            this.codedisabled = true;
            var _this = this;
            _this.timercode = setInterval(() => {
                _this.countdown--;
                _this.sendcodeValue = _this.$t("uc.regist.resendcode") + _this.countdown + ")";
                if (this.countdown <= 0) {
                    clearInterval(_this.timercode);
                    _this.codedisabled = false;
                    _this.sendcodeValue = _this.$t("uc.regist.sendcode");
                    _this.countdown = 120;
                }
            }, 1000);
        },
        // sendCode() {
        //   var mobilePhone = this.formInline.user;
        //   let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        //   // if (mobilePhone == "" || !reg.test(mobilePhone)) {
        //   if (mobilePhone == "") {
        //     this.$Message.error(this.$t("uc.regist.teltip"));
        //     return;
        //   } else {
        //     this.initGtCaptcha();
        //   }
        // },


//     areaCode (string, optional): 手机号区号 ,
// mobilePhone (string): 手机号码 ,
// sendType
        sendCode() {
            var params = {};

            let xy = this.country.substring(1, this.country.length)

            params["mobilePhone"] = this.formInline.user;
            params["areaCode"] = xy;
            params["sendType"] = 0;
            // var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            // reg.test(params["phone"]) && this.$http.post(this.host + "/uc/mobile/code", params).then(response => {
            this.$http.post(this.host + this.api.uc.captcha, params).then(response => {
                var resp = response.body;
                resp.code == "0000" && this.$Notice.success({title: this.$t("common.tip"), desc: resp.mesg});
                resp.code == "0000" && this.settime();
                resp.code != "0000" && this.$Notice.error({title: this.$t("common.tip"), desc: resp.mesg});
            });
            // !reg.test(params["phone"]) &&this.$Notice.error({title: this.$t("common.tip"),desc: this.$t("uc.finance.withdraw.telerr")});
        }
    }
};
</script>
