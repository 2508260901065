<template>
  <div class="login_form">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
        {{$t('uc.finance.xx43')}}
      </span>
      <span>{{  }}</span>
    </div>


    <div class="body">
      <Collapse v-model="value1" accordion>
        <Panel name="1">
          <span>
            2023年8月
          </span>
          
          <div slot="content">
            <Row >

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">
  
  <div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx48')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx49')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>

</div>

</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px; width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx50')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx51')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>



<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx52')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx53')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx54')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx55')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>
<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx56')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>

</Row>

<Row style="margin-top: 20px;">

<div style="margin-left: 40%;">
<Button type="primary" @click="gotoDetail" size="small">{{ $t('uc.finance.xx57') }}</Button>
</div>

</Row>

</div>

        </Panel>
        <Panel name="2">
          <p slot="content">
            <Row >

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">
  
  <div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx48')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx49')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>

</div>

</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px; width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx50')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx51')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>



<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx52')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx53')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx54')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx55')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>
<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx56')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>

</Row>

<Row style="margin-top: 20px;">

<div style="margin-left: 40%;">
<Button type="primary" @click="gotoDetail" size="small">{{ $t('uc.finance.xx57') }}</Button>
</div>

</Row>

          </p>
        </Panel>
        <Panel name="3">

          <p slot="content">
            <Row >

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">
  
  <div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx48')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx49')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>

</div>

</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px; width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx50')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx51')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>



<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx52')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx53')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx54')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>

<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx55')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>


</Row>
<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">


<div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx56')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>

</Row>

<Row style="margin-top: 20px;">

<div style="margin-left: 40%;">
<Button type="primary" @click="gotoDetail" size="small">{{ $t('uc.finance.xx57') }}</Button>
</div>

</Row>

          </p>
           </Panel>
    </Collapse>

    </div>
   
  </div>
</template>

<script>
export default {

data(){
  return{
    value1:'1',
  }
},

  methods: {
    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },

  gotoDetail(){
    this.$router.push("/commisionDetailPage");
  }
}
}
</script>

<style lang="scss" scoped>
.login_form {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  }

 

  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;

  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}
.information{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
}
.body{
  margin-top: 60px;
  width: 90%;
  margin-left: 5%;
}
</style>

<style lang="scss">
  .ivu-icon .ivu-icon-ios-arrow-forward:before {
    margin-left: -200px;
  }
</style>
