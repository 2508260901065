<template>
    <div class="match-list-box">
        <template v-if="tab == 1">
            <div class="match-list" v-for="(m,i) in matchListGun" :key="i">
                <Card :cardInfo="m"></Card>
            </div>
        </template>
        <template v-else-if="tab == 3">
            <div class="match-list" v-for="(m,i) in matchListFeature" :key="i">
                <CardToday :cardInfo="m"></CardToday>
            </div>
        </template>
    </div>
</template>
<script>
import Card from "@/components/Card.vue";
import CardToday from "@/components/CardToday.vue";

export default {
    name: "",
    components: {
        Card,
        CardToday
    },
    props: ['matchListGun', 'matchListFeature', 'tab'],
    created() {
        console.log(this.tab)
    },
    watch:{
        tab(newVal,old){
            console.log( newVal)
        }
    }
}
</script>

<style scoped lang="scss">
.match-list-box {
    padding-bottom: 100px;
}

.match-list {
    display: flex;
    flex-direction: column;
}
</style>
