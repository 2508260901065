<template>
  <div class="login_form" >

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span  >
        {{$t('uc.finance.gamedetail')}}
      </span>
      <div style="display: flex;align-items: center;">
        <img src="../assets/logo/ma11.png" width="20" alt="" @click="otherpicke">
     </div>
      <!-- <img src="../assets/logo/date.png" width="20" alt="" @click="popdata"> -->
    </div>
<!--
    <div style="margin-top:100px">
  <Button style="width:40%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="popdata">{{$t('common.date')}}</Button>
</div> -->

<div class="sports">

  <!-- <Button @click="sportRecord" style="border-radius: 20px;" :class="[isActive == 0?'active':'disActive']" >{{$t('uc.finance.xx131')}}</Button>

  <Button @click="gameRecord" style="border-radius: 20px;" :class="[isActive == 1?'active':'disActive']" >{{$t('uc.finance.xx132')}}</Button>

<Button @click="results" style="border-radius: 20px;" :class="[isActive == 2?'active':'disActive']" >{{$t('uc.finance.xx133')}}</Button> -->

<Tabs :value="currentab" @on-click="handletabs">
        <TabPane :label="$t('uc.finance.xx131')" name="cmd" style="padding-left: 10px;padding-right: 10px;">

          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage"  :height=realHeight >
            <div dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">

<div style="width: 100%;display: flex;flex-direction: row;">
  <div class="xxx1">
    <div>
      <span style="font-size: x-small;color: #4b4b4b">{{ item.thirdPlatform }}/{{ handleSports(item)  }}</span>

      </div>

      <div>
        <span style="font-size: x-small;color: #4b4b4b">{{item.startTime}}</span>

        </div>

          </div>
          <div class="xxx2">
            <div>
                  <span style="color: #4b4b4b;font-size: x-small;">{{  $t('uc.finance.validBet') }} </span>
                  <span style="color: #8d8d8d;font-size: x-small;margin-right: 5px;">{{ item.bet }}</span>
                  <span style="color: #4b4b4b;font-size: x-small;">{{ item.ccy }}</span>
            </div>
            <div>
                <span style="font-size: x-small;color: #4b4b4b;">{{ $t('uc.finance.xx75') }} : {{ item.thirdNo }} </span>
                <img src="../assets/logo/copy.png" width="10" height="10" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" alt="">
            </div>
          </div>
          <div class="xxx3">
            <div>
              <span style="font-size: x-small;color: #4b4b4b;margin-right: 1px;">{{ handleStatus(item) }}</span>
                <span v-if="Number(item.payout) < 0" style="color: #0db76d;font-size: x-small;"> {{ Number(item.payout).toFixed(2) }}</span>
                 <span v-else style="color: #b7240d;font-size: x-small;">{{ Number(item.payout).toFixed(2) }}</span>

            </div>


          </div>
  </div>


</div>
        </Scroll>

        </TabPane>
        <TabPane :label="$t('uc.finance.xx132')" name="leyou" style="padding-left: 10px;padding-right: 10px;">

          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage" :height=realHeight>
            <div dis-hover v-for="(item, index) in datalist1" :key="index"  :class="index%2 == 0? 'active': 'notactive'">



          <div style="width: 100%;display: flex;flex-direction: row;">
  <div class="xxx1">
    <div >
                  <span style="font-size:x-small; color: #4b4b4b;">{{ gamePlatform(item.gamePlatform, item.gameId) }}{{ gameType(item.gamePlatform,item.gameId) }}</span>
                  </div>
      <div>
        <span style="font-size: x-small;color: #4b4b4b">{{item.startTime}}</span>

        </div>
          </div>
          <div class="xxx2">
            <div>
                  <span style="color: #4b4b4b;font-size: x-small;">{{  $t('uc.finance.validBet') }} </span>
                  <span style="color: #8d8d8d;margin-right: 5px;font-size: x-small;">{{ item.bet }}</span>
                  <span style="color: #4b4b4b;font-size: x-small;">{{ item.ccy }} </span>
                </div>
            <div>
              <span style="font-size: x-small;color: #4b4b4b;">{{ $t('uc.finance.xx75') }} : {{ item.thirdNo }} </span>
              <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
            </div>
          </div>
          <div class="xxx3">

                <div v-if="Number(item.payout) - Number(item.bet) < 0" >
                  <span style="color: #4b4b4b;font-size: x-small;margin-right: 1px;">输</span>
                  <span style="color: #0db76d;font-size: x-small;">{{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                   </div>
                <div v-else >
                  <span style="color: #4b4b4b;font-size: x-small;margin-right: 1px;">赢</span>
                  <span style="color: #b7240d;font-size: x-small;"> {{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                 </div>


          </div>
  </div>


          <!-- <div style="display: flex; justify-content: flex-end; font-size: small;color: gray;">
            <span>{{item.startTime}}</span>
           </div> -->
          </div>
        </Scroll>


        </TabPane>


        <TabPane :label="$t('uc.finance.xx133')" name="bingon">
          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>
        </TabPane>
    </Tabs>
</div>


<!--
<div class="sports" v-if="isActive == 0">
  <Tabs :value="sportTab"  @on-click="getsubData" border="false" style="width: 100%;">
        <TabPane :label="$t('uc.finance.xx149')" :index='1' name="sp1" style="margin-top: -20px;">
          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div class="head_box" style="width: 100%;">

                <div style="width: 30%;text-align: left;font-size:x-small;">
                  <span>{{ item.thirdPlatform }}/{{ handleSports(item)  }}</span>
                </div>
                <div style="width: 30%;text-align: left;margin-left: 15%;font-size: x-small;">
                  <span>{{  $t('uc.finance.validBet') }} {{ item.bet }}</span>

                </div>

                <div style="width: 30%;text-align:left;margin-left: 10%;font-size: x-small;">
                  <span>{{ handleStatus(item) }}</span>
                  <span v-if="Number(item.payout) < 0" style="color: #0db76d;"> {{ Number(item.payout).toFixed(2) }}</span>
                 <span v-else style="color: #b7240d;">{{ Number(item.payout).toFixed(2) }}</span>

                </div>

          </div>
          <div style="margin-top:5px;">
            <div class="body_box1">

              <span style="font-size: x-small;">{{item.startTime}}</span>
              <span style="font-size: x-small;margin-left: 8%;">{{ $t('uc.finance.xx75') }} : {{ item.thirdNo }} </span>





          </div>

          </div>
            </Card>
        </Scroll>
        </TabPane>
        <TabPane :label="$t('uc.finance.xx150')" :index='2' name="sp2" @click="subSaba"></TabPane>


    </Tabs>


</div> -->

<!-- <div class="gamerecords" v-if="isActive == 1" >
  <Tabs :value="gameTab" border="false" @on-click="getsubData" style="width: 100%;">
    <div style="display: flex;flex-direction: column; width: 100%;z-index: 200;position: fixed;height: 50px;background-color: aliceblue;"  v-if="isGameTouched == true">
      <div style="width:100%;display: flex;justify-content: space-between;padding-left: 10px;padding-right: 10px;">

      </div>


    </div>
        <TabPane :label="$t('uc.finance.xx151')" :index='1' name="g1" @click="subLeyou" style="margin-top: -20px;">
          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index"  :class="index%2 == 0? 'active': 'notactive'">
              <div class="head_box">
                <span style="font-weight: 600;">{{ gamePlatform(item.gamePlatform, item.gameId) }}</span>
              <span style="font-weight: 600;">{{ gameType(item.gamePlatform,item.gameId) }}</span>



          </div>
          <div style="margin-top:5px;">
            <div class="body_box">
              <div style="display:flex;justify-content: space-between; width: 100%;font-size: small;">

                <div>
                <span>{{ item.ccy }} : </span>
                <span v-if="Number(item.payout) - Number(item.bet) < 0" style="color: #0db76d;">{{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                <span v-else style="color: #b7240d;">{{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                </div>

                <span>{{item.startTime}}</span>
              </div>

          </div>

          </div>
            </Card>
        </Scroll>
        </TabPane>

    </Tabs>

</div> -->


<Drawer :title="$t('uc.finance.xx3')" placement="bottom"  v-model="value8" height="30" >

<RadioGroup v-model="rechargestatus"  v-if="currentName == 'CMD体育'">
      <Radio label="0" >{{ $t('common.exchangeAll') }}</Radio>
      <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
      <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
      <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
      <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>

      <Radio label="5" >{{ $t('uc.finance.xx193') }}</Radio>
      <!-- <Radio label="6" >{{ $t('uc.finance.xx194') }}</Radio> -->
      <Radio label="7" >{{ $t('uc.finance.xx195') }}</Radio>
      <!-- <Radio label="8" >{{ $t('uc.finance.xx196') }}</Radio> -->
      <Radio label="9" >{{ $t('uc.finance.xx197') }}</Radio>
      <Radio label="10" >{{ $t('uc.finance.xx198') }}</Radio>
  </RadioGroup>

  <RadioGroup v-model="withdrawstatus" v-else-if="currentName == 'saba体育'">

      <Radio label="0" >{{$t('common.exchangeAll')  }}</Radio>
      <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
      <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
      <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
      <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>
  </RadioGroup>

  <RadioGroup v-model="commonstatus" v-else>
      <Radio label="0" >{{$t('common.exchangeAll')  }}</Radio>
      <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
      <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
      <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
      <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>
  </RadioGroup>

  <Button type="primary" long style="margin-top: 20px;" @click="closebtm">{{ $t('common.ok') }}</Button>

  </Drawer>

<calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange" style="color: #0073ee;"/>

  </div>
</template>

<script>

// import TodoList from '@/components/TodoList'
import dayjs from 'dayjs';
import {checkIsJSON} from '@/config/index'
import JDB from '../../public/JDB.json'
import PG from '../../public/PG.json'
import PP from '../../public/pp.json'
import evo from '../../public/evoL.json'
import ppl from '../../public/ppl.json'

// import inobounce from 'inobounce'

export default {

 data() {
return{
datalist:[],
datalist1: [],
startDate: '',
      endDate : '',
      defaultDate:[ dayjs(), dayjs().add(-7, 'day') ],
      totalregist:'',
      totolwin: 0,
      totalbet: 0,
      totalValidBet: 0,
      calendarShow:false,
      currentPage: 1,
      totalPage: 0,
      isActive: 0,
      iSportTouched: false,
      isGameTouched: false,
      iSportSubActive: 0,
      isGameSubActive: 2,
      realHeight: 0,
      isReachBottom: false,
      sportTab:'sp1',
      gameTab:'g1',
      isSport:  false,
      windowHeight: 0,
      currentName: '',
      value8:false,
      rechargestatus: '',
      withdrawstatus: '',
      commonstatus: '',
      currentab: '',
      showxxy: ''
}
 },

 mounted(){

  this.windowHeight = document.documentElement.clientHeight;
  console.log('yyyyyy '+this.windowHeight);

  this.realHeight = Number(this.windowHeight) - 100
  console.log('xcxcxx '+this.realHeight);
    // if (browser().ios) {
      document.getElementById('outbody').style.overflow = 'hidden'
    // }
  },

created() {



    // if (browser().ios) {
    //   document.getElementById('outbody').style.overflow = 'hidden'
    // }
  // this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  // this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  // this.currentPage = 1
    // this.initGtCaptcha();
    // this.getPokeGameList()

    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  this.currentName = 'CMD体育'
  this.currentab = 'cmd'
  this.initGtCaptcha('CMD体育')
  this.iSportSubActive = 0
  },

  beforeDestroy() {
    // inobounce.disable()
    document.getElementById('outbody').style.overflow = ''
  },

methods: {

  closebtm(){
    this.value8 = false
    this.currentPage = 1
    this.isReachBottom = false
    if (this.currentName == 'CMD体育') {
      this.initGtCaptcha('CMD体育')
    }
  },

  handletabs(data){
    this.currentab = data
    if (data == 'cmd') {
      this.getsubData('sp1')
    }else if (data == 'leyou') {
      this.getsubData('g1')
    }
  },

  otherpicke(){
        this.value8 = true
    },
  selfchoose(){
    this.isGameSubActive = 4
    this.popdata()
  },

  gotoDetail(item){
    // window.location.href = item.betDetail
          // window.open(item.betDetail,"self")

    this.getHomeName(item)



  },

  onCopy(e) {
    console.log(''+e )
      this.$Message.success(

        this.$t("uc.finance.copysuccess")
      );
    },
    onError() {
      this.$Message.error(this.$t("uc.finance.copyfailed"));
    },

gameOddType(item){
  let xx = ''
    let param = JSON.parse(item.betDetail)
    switch (param.OddsType) {
      case 'MY':
        xx = '马来盘'
        break;
      case 'ID':
        xx =  '印度尼西亚盘'
        break;
      case 'HK':
        xx =  '香港盘'
        break;
      case 'DE':
        xx =  '欧洲盘'
        break;
      case 'US':
        xx =  '美国盘'
        break;
      default:
        break;
    }

    return xx
},

handlePPL(game){
  let xx = ''


  if (ppl[game]) {
            xx += '/'+ppl[game].GameName_cn

          }
  return xx
},
handleEVO(game){
let xx = ''



if (evo[game]) {
            xx += '/'+evo[game].GameName_cn

          }
return xx
},

handleSports(item){
  let xx = ''

  let param = JSON.parse(item.betDetail)
  switch (param.SportType) {
    case 'S' :
      xx = '足球'
      break;
    case 'MT' :
      xx = '泰拳'
      break;
    case 'ES' :
      xx = '电子竞技'
      break;
    case 'WS' :
      xx = '赛道'
      break;
    case 'WP' :
      xx = '水球'
      break;
    case 'WI' :
      xx = '冬季运动'
      break;
    case 'WG' :
      xx = '举重'
      break;
    case 'TT' :
      xx = '乒乓球'
      break;
    case 'RU' :
      xx = '橄榄球联盟'
      break;
    case 'MN' :
      xx = '游泳'
      break;
    case 'QQ' :
      xx = '壁球'
      break;
    case 'PO' :
      xx = '政治'
      break;
    case 'OT' :
      xx = '其他'
      break;
    case 'OL' :
      xx = '奥林匹克'
      break;
    case 'NT' :
      xx = 'Novelties'
      break;
    case 'FL' :
      xx = '地板球'
      break;
    case 'GM' :
      xx = '体操'
      break;
    case 'HK' :
      xx = '曲棍球'
      break;
    case 'CY' :
      xx = '骑自行车'
      break;
    case 'CS' :
      xx = '格斗体育'
      break;
      case 'CN' :
      xx = '皮划艇'
      break;
      case 'ET' :
      xx = '综艺'
      break;
      case 'EQ' :
      xx = '马术'
      break;
      case 'DV' :
      xx = '跳水'
      break;
      case 'CH' :
      xx = '棋'
      break;
      case 'AR' :
      xx = '射箭'
      break;
      case 'AT' :
      xx = '田径'
      break;
      case 'BX' :
      xx = '拳击'
      break;
      case 'DT' :
      xx = '飞镖'
      break;
      case 'GP' :
      xx = '汽车运动'
      break;
      case 'RB' :
      xx = '橄榄球'
      break;
      case 'NS' :
      xx = '桌球'
      break;
      case 'BL' :
      xx = '台球'
      break;
      case 'PL' :
      xx = '撞球'
      break;
      case 'HB' :
      xx = '手球'
      break;
      case 'VB' :
      xx = '排球'
      break;
      case 'CK' :
      xx = '板球'
      break;
      case 'GF' :
      xx = '高尔夫球'
      break;
      case 'BA' :
      xx = '羽毛球'
      break;
      case 'FB' :
      xx = '金融投注'
      break;
      case 'TN' :
      xx = '网球'
      break;
      case 'IH' :
      xx = '冰球'
      break;
      case 'BE' :
      xx = '棒球'
      break;
      case 'UF' :
      xx = '美式足球'
      break;
      case 'BC' :
      xx = '海滩足球'
      break;
      case 'FS' :
      xx = '室内足球'
      break;
      case 'BB' :
      xx = '篮球'
      break;
    default:
      break;
  }

  return xx
},

handleStatus(item){
  let xx = ''
    let param = JSON.parse(item.betDetail)

  switch (param.WinLoseStatus) {
    case 'WA':
    xx = this.$t('uc.finance.xx193')
      break;
    case 'WH':
    xx = this.$t('uc.finance.xx194')
      break;
    case 'LA':
    xx = this.$t('uc.finance.xx195')
      break;
    case 'LH':
    xx = this.$t('uc.finance.xx196')
      break;
    case 'D':
    xx = this.$t('uc.finance.xx197')
      break;
    case 'P':
    xx = this.$t('uc.finance.xx198')
      break;
    default:
      break;
  }
  return xx
},

gamePlay(item){
  let xx = ''
    let param = JSON.parse(item.betDetail)
    item.play = ''
        if (param.IsFirstHalf == true) {
          item.play = '上半场' + ' '
        }
    switch (item.gamePlatform) {
      case 'CNS':
        xx = '零失球'
        item.play += this.handleCNS(param.Choice)+ ' @ ' + param.Odds
        break;
      case 'HP3':
        xx = '三项让分投注'
        item.play += this.handleHP3(param.Choice)+ ' @ ' + param.Odds
        break;
      case 'ATG':
        xx = '客队准确总进球'
        item.play += this.handleATG(param.Choice)+ ' @ ' + param.Odds
        break;
      case 'HTG':
        xx = '主队准确总进球'
        item.play += this.handleHTG(param.Choice)+ ' @ ' + param.Odds
        break;
      case 'ETG':
        xx = '准确总进球'
        item.play += this.handleETG(param.Choice)+ ' @ ' + param.Odds
        break;
      case 'X2':
        xx = '双重机会(DC) 下注 X2'
        item.play += this.handleDC(param.Choice)+ ' @ ' + param.Odds
        break;
      case '12':
        xx = '双重机会(DC) 下注 12'
        item.play += this.handleDC(param.Choice)+ ' @ ' + param.Odds
        break;
      case '1X':
        xx = '双重机会(DC) 下注 1X'
        item.play += this.handleDC(param.Choice)+ ' @ ' + param.Odds
        break;
        case 'X':
        xx = '独赢'

        item.play += '和' + ' '
item.play += ' @ ' + param.Odds
        break;
        case 'TG':
        xx = '总进球'
        item.play += this.handleTG(param.Choice)+ ' @ ' + param.Odds
        break;
        case 'PAR':
        xx = '混合过关'
        item.play += this.handlePAR(param.Choice)+ ' @ ' + param.Odds
        break;
        case 'OUT':
        xx = '优胜冠军'
        item.play += this.handleOUT(param.Choice)+ ' @ ' + param.Odds
        break;
        case 'OU':
        xx = '大/小'


item.play += this.handOU(param.Choice)+ '('+param.Hdp+')' + ' @ ' + param.Odds
        break;
        case 'OE':
        xx = '单/双'
        item.play +=this.handOE(param.Choice) + ' @ ' + param.Odds
        break;
        case 'HFT':
        xx = '半场/全场'
        item.play +=this.handHFT(param.Choice) + ' @ ' + param.Odds
        break;
        case 'HDP':
        xx = '让球'

if (param.IsBetHome == true ) {
  item.play += item.home + ' '
}else item.play += item.away + ' '
item.play += this.handleHDP(param.Choice) + ' @ ' + param.Odds

        break;
        case 'FLG':
        xx = '最先/最后进球'
        item.play +=this.handFLG(param.Choice) + ' @ ' + param.Odds
        break;
        case 'CS':
        xx = '波胆'

    item.play += this.handleCS(param.Choice) + ' @ ' + param.Odds
        break;
        case '2':
        xx = '独赢'

        item.play += item.away + ' '
item.play += ' @ ' + param.Odds
        break;
        case '1':
        xx = '独赢'

       item.play += item.home + ' '
item.play += ' @ ' + param.Odds
        break;
      default:
        break;
    }

    return xx
},

handOU(ou){
  let xxy = ''
switch (ou) {
  case '1':
    xxy = '大'
    break;
  case '2':
    xxy = '小'
    break;
  default:
    break;
}
return xxy
},


handleHDP(hdp){
  let xxy = ''
switch (hdp) {
  case '1':
    xxy = '主队'
    break;
    case '2':
    xxy = '客队'
    break;
  default:
    break;
}
return xxy
},

handOE(oe){
  let xxy = ''
switch (oe) {
  case '1':
    xxy = '单'
    break;
    case '2':
    xxy = '双'
    break;
  default:
    break;
}

  return xxy
},

handFLG(flg){
  let cnss = ''
  switch (flg) {
    case '1':
      cnss = '主先'
      break;
    case '2':
      cnss = '主后'
      break;
      case '3':
      cnss = '客先'
      break;
      case '4':
      cnss = '客后'
      break;
      case '5':
      cnss = '无进球'
      break;
    default:
      break;
  }

  return cnss
},


handHFT(hft){
  let xxy = ''
  switch (hft) {
    case '1':
      xxy = '主主'
      break;
      case '2':
      xxy = '主和'
      break;
      case '3':
      xxy = '主客'
      break;
      case '4':
      xxy = '和主'
      break;
      case '5':
      xxy = '和和'
      break;
      case '6':
      xxy = '和客'
      break;
      case '7':
      xxy = '客主'
      break;
      case '8':
      xxy = '客和'
      break;
      case '9':
      xxy = '客客'
      break;
    default:
      break;
  }

  return xxy
},


handleCNS(cns){
  let cnss = ''
  switch (cns) {
    case '1':
      cnss = '主队 是'
      break;
    case '2':
      cnss = '主队 否'
      break;
      case '3':
      cnss = '客队 是'
      break;
      case '4':
      cnss = '客队 否'
      break;
    default:
      break;
  }

  return cnss
},

handleATG(atg){
  let xxy = ''
  switch (atg) {
    case '1':
      xxy = '0'
      break;
      case '2':
      xxy = '1'
      break;
      case '3':
      xxy = '2'
      break;
      case '4':
      xxy = '3+'
      break;
    default:
      break;
  }

  return xxy
},


handleHP3(hp3){
  let xxy = ''
  switch (hp3) {
    case '1':
      xxy = '主队（+1）'
      break;
      case '2':
      xxy = '和局（主队+1）'
      break;
      case '3':
      xxy = '客队（-1）'
      break;
    default:
      break;
  }
  return xxy
},


handleHTG(htg){
let xxy = ''
switch (htg) {
  case '1':
      xxy = '0'
      break;
      case '2':
      xxy = '1'
      break;
      case '3':
      xxy = '2'
      break;
      case '4':
      xxy = '3+'
      break;
    default:
      break;
}
return xxy
},

handleETG(etg){
  let xxy = ''
switch (etg) {
  case '1':
      xxy = '0'
      break;
      case '2':
      xxy = '1'
      break;
      case '3':
      xxy = '2'
      break;
      case '4':
      xxy = '3'
      break;
      case '5':
      xxy = '4'
      break;
      case '6':
      xxy = '5'
      break;
      case '7':
      xxy = '6+'
      break;
    default:
      break;
}
return xxy
},

handleTG(tg){
  let xxy = ''
switch (tg) {
  case '1':
    xxy = '0-1'
    break;
    case '2':
    xxy = '2-3'
    break;
    case '3':
    xxy = '4-6'
    break;
    case '4':
    xxy = '7+'
    break;

  default:
    break;
}
return xxy
},
handlePAR(par){
  let xxy = ''
switch (par) {
  case '1':
    xxy = ''
    break;

  default:
    break;
}
return xxy
},
handleOUT(out){
  let xxy = ''
switch (out) {
  case '1':
    xxy = ''
    break;

  default:
    break;
}
return xxy
},

handleDC(dc){
  let xxy = ''
  switch (dc) {
    case '1':
      xxy = '主/和'
      break;
    case '2':
      xxy = '主/客'
      break;
    case '3':
      xxy = '客/和'
      break;
    default:
      break;
  }
  return xxy
},

handleOE(oe){
  let sz =''
  switch (oe) {
    case '1':
      sz = '单'
      break;
      case '2':
      sz = '双'
      break;
    default:
      break;
  }
  return sz
},

handleCS(cs){
  let xy = ''
switch (cs) {
  case '1' :
  xy =  '1-0'
    break;
  case '2' :
  xy =  '2-0'
    break;
  case '3' :
  xy =  '2-1'
    break;
  case '4' :
  xy =  '3-0'
    break;
  case '5' :
  xy =  '3-1'
    break;
  case '6' :
  xy =  '3-2'
    break;
  case '7' :
  xy =  '4-0(FT)'
    break;
  case '8' :
  xy =  '4-1(FT)'
    break;
  case '9' :
  xy =  '4-2(FT)'
    break;
  case '10' :
  xy =  '4-3(FT)'
    break;
  case '11' :
  xy =  '5-0 UP(Disable)'
    break;
  case '12' :
  xy =  '0-5 UP(Disable)'
    break;
  case '13' :
  xy =  '0-0'
    break;
    case '14' :
    xy =  '1-1'
    break;
    case '15' :
    xy =  '2-2'
    break;
    case '16' :
    xy =  '3-3'
    break;
    case '17' :
    xy =  '4-4(FT)'
    break;
    case '18' :
    xy =  '0-1'
    break;
    case '19' :
    xy =  '0-2'
    break;
    case '20' :
    xy =  '1-2'
    break;
    case '21' :
    xy =  '0-3'
    break;
    case '22' :
    xy =  '1-3'
    break;
    case '23' :
    xy =  '2-3'
    break;
    case '24' :
    xy =  '0-4(FT)'
    break;
    case '25' :
    xy =  '1-4(FT)'
    break;
    case '26' :
    xy =  '2-4(FT)'
    break;
  case '27' :
  xy =  '3-4(FT)'
    break;
    case '-99' :
    xy =  'AOS'
    break;
  default:
    break;
}

return xy
},

  getHomeName(item){

    let params = {}
    let param = JSON.parse(item.betDetail)
    params.ID = ""+param.HomeTeamId
    params.Type = 0

    let that = this

    let xx = ''
    this.$http.get(this.host + this.api.uc.cmdLang, {params: params}).then(response => {
       var resp = response.body;

                    if (resp.code == 0) {
                      let jss = JSON.parse(resp.data)
xx = jss[that.$i18n.locale]
                     item.home = xx
                     item.HomeScore = param.HomeScore
                     that.getAwayName(item)
                    }else
                        that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });


  },
  getAwayName(item){
    let params = {}
    let param = JSON.parse(item.betDetail)
    params.ID = ''+param.AwayTeamId
    params.Type = 0

    let that = this
    let xx = ''
    this.$http.get(this.host + this.api.uc.cmdLang, {params: params}).then(response => {
       var resp = response.body;

                    if (resp.code == 0) {
                      let jss = JSON.parse(resp.data)
                      xx = jss[that.$i18n.locale]
                     item.away = xx
                     item.AwayScore = param.AwayScore
                     that.getLeagueName(item)
                    }else
                        that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
},
  getLeagueName(item){
    let params = {}
    let param = JSON.parse(item.betDetail)
    params.ID = '' + param.LeagueId
    params.Type = 1
let xx = ''
    let that = this
    this.$http.get(this.host + this.api.uc.cmdLang, {params: params}).then(response => {
       var resp = response.body;

                    if (resp.code == 0) {
                      let jss = JSON.parse(resp.data)
                      xx = jss[that.$i18n.locale]
                    item.league = xx
                    item.hasDetail = 1
                    item.matchTime = item.MatchDate
                    that.$forceUpdate()
                    }else
                        that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
  },
  getMatchName(match){
    let params = {}
    params.ID = ''+ match
    params.Type = 2
let xx = ''
    let that = this
    this.$http.get(this.host + this.api.uc.cmdLang, {params: params}).then(response => {
       var resp = response.body;

                    if (resp.code == 0) {
                      xx = resp.data[that.$i18n.locale]
                     return xx

                    }else
                        that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
  },

  getsubData(name){

    switch (name) {
      case 'sp1':

        this.rechargestatus = ''
        this.withdrawstatus = ''
        this.commonstatus = ''
        this.currentName = 'CMD体育'
        this.subCmd()
        break;
      case 'sp2':
      this.rechargestatus = ''
        this.withdrawstatus = ''
        this.commonstatus = ''
        this.currentName = 'saba体育'
        this.subSaba()
        break;
      case 'g1':
      this.rechargestatus = ''
        this.withdrawstatus = ''
        this.commonstatus = ''
        this.currentName = '乐游'
        this.subLeyou()

        break;
      default:
        break;
    }
  },


  subCmd(){
    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  this.initGtCaptcha('CMD体育')
  this.iSportSubActive = 0
  },
  subSaba(){
    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  // this.initGtCaptcha('saba体育')
  this.iSportSubActive = 1
  },

  subLeyou(){
    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  this.initGtCaptcha('乐游')
  this.isGameSubActive = 0
  },


  subAll(){
    this.iSportSubActive = 0
  },
  subSingle(){
    this.iSportSubActive = 1
  },
  subMore(){
    this.iSportSubActive = 2
  },
  subChamp(){
    this.iSportSubActive = 3
  },



  touchSportBut(){
    this.iSportTouched = !this.iSportTouched
  },

  touchGameBut(){
    this.isGameTouched = !this.isGameTouched
  },
  sportRecord(){
    this.isActive = 0
    this.iSportTouched =false
    this.isGameTouched = false
    this.sportTab = 'sp1'
    this.currentName = 'CMD体育'
    this.rechargestatus = ''
    this.withdrawstatus = ''
    this.commonstatus = ''
    this.subCmd()
  },
  gameRecord(){
    this.isActive = 1
    this.iSportTouched = false
    this.isGameTouched = false
    this.rechargestatus = ''
    this.withdrawstatus = ''
    this.commonstatus = ''
    this.gameTab = 'g1'
    this.currentName = '乐游'

    this.subLeyou()
  },
  results(){
    this.isActive = 2
    this.iSportTouched =false
    this.isGameTouched = false
  },

  nextPage(){

    if (this.isReachBottom == true) {
      return
    }
    let that = this
  let params = {}
     params.beginDateStr = this.startDate
     params.endDateStr = this.endDate
     this.currentPage += 1
     params.pageNum = this.currentPage
     params.pageSize = 20
     params.thirdPlatform = this.currentName

     if(this.currentName == 'CMD体育') {
      switch (this.rechargestatus) {
        case 5:
        params.status = 'WA'
          break;
        case 6:
        params.status = 'WH'
          break;
        case 7:
        params.status = 'LA'
          break;
        case 8:
        params.status = 'LH'
          break;
        case 9:
        params.status = 'D'
          break;
        case 10:
        params.status = 'P'
          break;
        default:
          break;
      }

     }

       this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
       var resp = response.body;

                    if (resp.code == "0000") {
                      let xyz = resp.data.list
                      if (xyz.length < 10) {
                        this.$Notice.info({
                        title: that.$t("uc.finance.xx14"),

                      });

                      that.isReachBottom = true
                      }


                      if(that.currentName == 'CMD体育') {
                        xyz.forEach(element => {
                        that.datalist.push(element)
                      })
                    }else if (that.currentName == '乐游') {
                        xyz.forEach(element => {
                        that.datalist1.push(element)
                      });
    }

                      // that.datalist = xyz
                      if (that.currentPage == 1) {
                        that.totalPage = Number(resp.data.total)
                      }

                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
  },


  goback() {
      //获取个人安全信息
      document.getElementById('outbody').style.overflow = ''
      this.$router.back(-1)

    },
    onChange(date){
if (date[0] != null && date[1] != null) {
  this.startDate = date[0].format('YYYY-MM-DD')

  this.endDate = date[1].format('YYYY-MM-DD')


this.initGtCaptcha()
}

    },

 initGtCaptcha(platform) {
  let that = this
  let params = {}

     params.pageNum = 1
     this.currentPage = 1
     params.pageSize = 20
     params.thirdPlatform = platform
     this.isReachBottom = false
     if(platform == 'CMD体育') {
      this.datalist = []

      if (this.rechargestatus == '1'){
        this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }
    else if (this.rechargestatus == '2'){
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().format('YYYY-MM-DD')

    }else if (this.rechargestatus == '3'){
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-7, 'day').format('YYYY-MM-DD')

    }else{
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-30, 'day').format('YYYY-MM-DD')
      switch (this.rechargestatus) {
        case '5':
        params.status = 'WA'
          break;
        case '6':
        params.status = 'WH'
          break;
        case '7':
        params.status = 'LA'
          break;
        case '8':
        params.status = 'LH'
          break;
        case '9':
        params.status = 'D'
          break;
        case '10':
        params.status = 'P'
          break;
        default:
          break;
      }

    }

     }else if (platform == 'saba体育') {
      console.log('xxx');
     }else if (platform == '乐游') {
      this.datalist1 = []
      if (this.commonstatus == '1'){
        this.endDate= dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }
    else if (this.commonstatus == '2'){
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().format('YYYY-MM-DD')

    }else if (this.commonstatus == '3'){
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-7, 'day').format('YYYY-MM-DD')

    }else {
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-30, 'day').format('YYYY-MM-DD')

    }
     }

     params.beginDateStr = this.startDate
     params.endDateStr = this.endDate
       this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
       var resp = response.body;

                    if (resp.code == "0000") {
                      if(platform == 'CMD体育') {
                        that.datalist = resp.data.list
                        if (that.datalist.length <= 0) {
                          that.showxxy = true
                        }else
                          that.showxxy = ''
    }else if (platform == '乐游') {
      that.datalist1 = resp.data.list
      if (that.datalist1.length <= 0) {
                          that.showxxy = true
                        }else
                          that.showxxy = ''
    }



    that.totalbet = resp.data.totalBet
    that.totolwin = resp.data.totalWin
    that.totalValidBet = resp.data.totalValidBet
    that.totalPage = Number(resp.data.total)
                    }else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    popdata(){
        this.calendarShow = !this.calendarShow
    },


    handledetail(str, ele1){
      let arr1 = str.split("}-");
      arr1.forEach(element => {
        if (checkIsJSON(element+'}')) {
          let js1 = JSON.parse(element+'}')
          Object.getOwnPropertyNames(js1).forEach(function(key){
    ele1[key] = js1[key]
});

        }else{
          let arr2 = element.split('-')

         let aar21 = arr2[0].split(':')
         ele1.betNo = aar21[1]
          let aar22 = arr2[1].split(':')
          ele1.playID = aar22[1]
          let aar23 = arr2[2].split(':')
          ele1.table = aar23[1]
        }

      });
      console.log("xxx" + ele1);
    },

    handleAGdetail(str, ele1){
      let arr1 = str.split("=");
      arr1 = arr1[1]
      arr1 = arr1.split('-')
      for (let index = 0; index < arr1.length; index++) {
        const element = arr1[index];
        if (index == 0) {
          let arr2 = element.split(',')
          ele1.Playerbillno = arr2[0]
          ele1.sport = arr2[1]
        }else if (index == 1) {
          ele1.Extbillno = element
        }
      }
    },
    // "BJ Baccarat-25,20-Settled."

    handleGBdetail(str, ele1){
        console.log(str, ele1);
    },

    handleIBdetail(str, ele1){
      let arr1 = str.split("-");
      // MatchId -LeagueName                    -HomeIDName  -AwayIDName    -HomeScore-AwayScore-
      for (let index = 0; index < arr1.length; index++) {
        const element = arr1[index];
        if (index == 0) {
          ele1.MatchId = element
        }else if (index == 1) {
          ele1.LeagueName = element
        }else if (index == 2) {
          ele1.HomeIDName = element
        }else if (index == 3) {
          ele1.AwayIDName = element
        }else if (index == arr1.length - 3) {
          ele1.HomeScore = element
        }else if (index == arr1.length - 2) {
          ele1.AwayScore = element
        }
      }
    },

    gamePlatform(platform, gid){

      let xx = ''
      switch (platform) {
        case '1085':
          xx = 'JDB'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '电子'
}
          break;

          case '1046':
            xx = 'IBC'

            if (this.$i18n.locale == 'zh-CN') {
          xx += '体育'
}
            break
            case '1002':
          xx = 'Evo'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '真人'
}
            break
          case '1003':
          xx = 'All Bet'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '真人'
}
            break
            case '1020':
          xx = 'WM'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '/真人'
}
            break
            case '1109':
          xx = 'Netent'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '电子'
}
            break
          case '1006':
          xx = 'PP'
            if (this.$i18n.locale == 'zh-CN' ) {
              if ( !PP[gid]) {
                xx += '/真人'
              }else
              xx += '电子'

}
            break

            case '1007':
          xx = 'PG'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '电子'
}
            break

            case '1001':
              xx = 'AG/真人'
              break

        default:
          break;
      }
      return xx
    },

    gameType(platform, game) {


      let xx = ''
      switch (platform) {
        case '1001': {
          xx +=  this.handleAg(game)

        }
        break

        case '1085':{
          let slotjdb = ''
      JDB.forEach(element2 => {
    if (element2.gType == this.gameConfig.JDB.SLOT) {
      slotjdb = element2
      if (this.$i18n.locale == 'zh-CN') {
          xx += '/'+slotjdb.list[game].name_cn
}
    }

  })
        }
break
        case '1046':
            xx += ''

          break;
        case '1007':{
          if (PG[game]) {
            xx += '/'+PG[game].gn_cn
          }
        }


          break;
        case '1006':{
          if (PP[game]) {
            xx += PP[game].name_cn

          }else if (ppl[game]) {
            xx += this.handlePPL(game)
          }
        }


          break;
          case '1020':
            xx += this.handleWM(game)
break
case '1002':
            xx += this.handleEVO(game)
break
          case '1003':{
            switch (game) {
              case '101':
                case '102':
                case '103':
                case '104':
                case '110':
              xx = ' Baccarat'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/百家乐'
}
                break;
                case '201':
              xx = ' sicbo(Hilo)'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/骰宝'
}
                break;
                case '301':
              xx = ' Dragon Tiger'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/龙虎'
}
                break;
                case '401':
              xx = ' Roulette'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/轮盘'
}
                break;
                case '501':
              xx = ' Classic/Two sides Pok Deng'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/经典/双面博丁'
}
                break;
                case '601':
              xx = ' Rock Paper Scissors'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/剪刀石头布'
}
                break;
                case '801':
              xx = ' Bull Bull'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/牛牛'
}
                break;
                case '901':
              xx = ' Win Three Cards/Three Pictures'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/斗三张/三公'
}
                break;
                case '602':
              xx = ' Andar Bahar'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/內外注(印度)'
}
                break;
                case '702':
              xx = ' Ultimate Texas Holdem'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/终极德州扑克'
}
                break;
                case '603':
              xx = ' Teen Patti 20-20'
              if (this.$i18n.locale == 'zh-CN') {
          xx = '/印度扎金花'
}
                break;
              default:
                break;
            }
          }


            break

        default:
          break;
      }

      return xx


},


handleWM(xxxu){
  let xx = ''
  switch (xxxu) {
    case 'Baccarat':
      xx = '/百家乐'
      break;
    case 'Dragon&Tiger':
      xx = '/龙虎'
      break;
    case 'NiuNiu':
      xx = '/牛牛'
      break;
      case 'Sedie':
      xx = '/色碟'
      break;
      case 'fish-prawn-crab':
      xx = '/鱼虾蟹'
      break;
      case 'Fantan':
      xx = '/番摊'
      break;
      case 'AndarBahar':
      xx = '/安達巴'
      break;
      case 'Sicbo':
      xx = '/骰宝'
      break;
    default:
      break;
  }

  return xx
},


handleAg(xxx){
  let xx = ''
  switch (xxx) {
    case 'BAC':
      xx = '/百家乐'
      break;
    case 'ROU':
      xx = '/轮盘'
      break;
      case 'ZJH':
      xx = '/扎金花'
      break;
      case 'BJ':
      xx = '/21点'
      break;
      case 'NN':
      xx = '/牛牛'
      break;
      case 'SG':
      xx = '/三公'
      break;
      case 'DT':
      xx = '/龙虎'
      break;
      case 'SHB':
      xx = '/筛宝'
      break;
    default:
      break;
  }

  return xx
},

billType(status){
let xyz = ''
switch (status) {
        case 100:
          xyz = this.$t('uc.finance.xx71')
          break;
          case 101:
          xyz = this.$t('uc.finance.xx70')
          break;
          case 102:
          xyz = this.$t('uc.finance.xx72')
          break;
        default:
          break;
      }
return xyz
    },
    getPokeGameList(){
let param = {};



     param["gameType"] = 2;
     param["productID"] = 1020;
     param['languageCode'] = 3
     param['operatorCode'] = "E503"
     param['platform'] = 0


       this.$http
       .post(this.host + this.api.uc.mdboGameList, param)
       .then(response => {
         let resp = response.body

         if (resp.ErrorCode == 0) {
            let temp = JSON.parse(resp.data)


console.log(temp);
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
      },
},





}
</script>

<style lang="scss" scoped>


.login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
   background-color: $color-background;
}

.head_box{
      display: flex;
      align-items: center;
      width: 100%;
      color: #fff;
      font-size: small;
      height: auto;
      // padding-top: 10px;
    }


    .body_box{
       display: flex;
      color: #fff;
      font-size: 15px;
      width: 100%;
justify-content: space-between;
align-items: center;
      // margin-left: 5%;
      // margin-top: 20px;
      height: auto;

    }

    .body_box1{
       display: flex;
      color: #fff;
      font-size: 15px;
      width: 100%;
align-items: center;

      height: auto;

    }

    /deep/ .ivu-card-body{
  padding:  10px 10px;

}

.head2{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top:60px;
  width: 100%;

}
.bodyhead{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/deep/ .ivu-card-bordered{
  border: none;
}



/deep/ .ivu-btn{
  border: none
}

.head22{
display: flex;
flex-direction: column;
font-size: medium;
}

.sports{

  margin-top:45px;
}
.gamerecords{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: baseline;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
 /deep/ .ivu-tabs-bar{
  border-bottom: 0px solid #dcdee2;
  margin-bottom: 10px;
  background-color: #1B2C38;
}

.active{
  height: 60px;
  padding: 8px 10px;
  background-color:#1B2C38;
  border-radius:5px;
}

.notactive{
  height: 60px;
  padding: 8px 10px;
  background-color:transparent;
  border-radius:5px;
}

/deep/ .ivu-tabs-nav-container{
  font-size: 12px;
}

/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color: #d2b965;
}


/deep/ .ivu-tabs-tab{
  color: #fff;
  padding: 12px 16px
}



/deep/ .ivu-tabs-ink-bar{
  background-color: #d2b965;
}

.xxx1{
display: flex;
flex-direction: column;
align-items: flex-start;
width: 34%;
}

.xxx2{
  display: flex;
flex-direction: column;
align-items: flex-start;
margin-left: 1px;
width: 45%;
}
.xxx3{
  display: flex;
align-items: flex-start;
width: 20%;
}
</style>



