<template>
  <div class="form">

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
    {{$t('common.aboutus')}}
  </span>
  <span>{{  }}</span>
</div>

<div class="body">
  <div class="section">
    <div class="title">{{ $t('uc.finance.xx101') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx102') }}</div>

    <div class="title">{{ $t('uc.finance.xx103') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx104') }}</div>

    <div class="title">{{ $t('uc.finance.xx105') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx106') }}</div>

    <div class="title">{{ $t('uc.finance.xx107') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx108') }}</div>

    <div class="title">{{ $t('uc.finance.xx109') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx110') }}</div>

    <div class="title">{{ $t('uc.finance.xx111') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx112') }}</div>

    <div class="title">{{ $t('uc.finance.xx113') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx114') }}</div>

    <div class="title">{{ $t('uc.finance.xx115') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx116') }}</div>

    <div class="title">{{ $t('uc.finance.xx117') }}</div>
    <div style="margin: 20px;">{{ $t('uc.finance.xx118') }}</div>

    <div style="margin: 20px;">{{ $t('uc.finance.xx119') }}</div>
  </div>
 

</div>

  </div>
</template>

<script>
export default {


  methods: {
    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },
}

}
</script>

<style lang="scss" scoped>

.form {
	position:relative;
	overflow:hidden;
}
.form:before {
	position:fixed;
	left:0;
	top:0;
	display:block;
  background-image: url(../assets/logo/aboutUsB.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
}



.body{
background: transparent;
  margin-top: 60px;
  color: $color-text;
  padding: 10px;
}

.section{
  background: transparent;
}

.title{
  background-color: rgb(234, 234, 232);
  border-radius: 15px;
  padding: 10px;
  font-weight: 600;
  font-size: medium;
}
</style>
