/*赛事阶段 */
export const sportsStatus = {
    '1000': {
        'en': 'SoccerRealTime', 'cn': '足球实时'
    }, '1001': {
        'en': 'SoccerFullTime', 'cn': '足球全场'
    }, '1002': {
        'en': 'SoccerFistHalf', 'cn': '足球上半场'
    }, '1003': {
        'en': 'SoccerSecondHalf', 'cn': '足球下半场'
    }, '1004': {
        'en': 'SoccerExtraFirstHalf', 'cn': '足球加时上半场'
    }, '1005': {
        'en': 'SoccerExtraSecondHalf', 'cn': '足球加时下半场'
    }, '1006': {
        'en': 'SoccerPenaltyKick', 'cn': '足球点球罚球'
    }, '1007': {
        'en': 'Soccer 0-15Min', 'cn': '足球0-15min'
    }, '1008': {
        'en': 'Soccer 15-30Min', 'cn': '足球15-30min'
    }, '1009': {
        'en': 'Soccer 30-45Min', 'cn': '足球30-45min'
    }, '1010': {
        'en': 'Soccer 45-60Min', 'cn': '足球45-60min'
    }, '1011': {
        'en': 'Soccer 60-75Min', 'cn': '足球60-75min'
    }, '1012': {
        'en': 'Soccer 75-90Min', 'cn': '足球75-90min'
    }, '1013': {
        'en': 'SoccerExtraFullTime', 'cn': '足球加时全场'
    }, '1014': {
        'en': 'Soccer Full Time(Incl ET)', 'cn': '足球全场包含加时'
    }, '1015': {
        'en': 'Soccer PenaltyKick(first 5 rounds)', 'cn': '足球点球大战前5回合'
    }, '3001': {
        'en': 'BasketballFullTime', 'cn': '篮球全场（包含加时）'
    }, '3002': {
        'en': 'BasketBallRegularTime', 'cn': '篮球常规时间'
    }, '3003': {
        'en': 'BasketballFirstHalf', 'cn': '篮球上半场'
    }, '3004': {
        'en': 'BasketballSecondHalf', 'cn': '篮球下半场'
    }, '3005': {
        'en': 'BasketballFirstQuarter', 'cn': '篮球第一节'
    }, '3006': {
        'en': 'BasketballSecondQuarter', 'cn': '篮球第二节'
    }, '100999': {
        en: 'Olympic Outright Period', cn: '奥林匹克冠军阶段',
    }, '92999': {
        en: 'Formula 1 Outright Period', cn: 'F1赛车冠军阶段',
    }, '10999': {
        en: 'Floorball Outright Period', cn: '地板球冠军阶段',
    }, '14999': {
        en: 'Cricket Outright Period', cn: '板球冠军阶段',
    }, '18999': {
        en: 'MMA Outright Period', cn: '格斗冠军阶段',
    }, '25999': {
        en: 'Cycling Outright Period', cn: '自行车冠军阶段',
    }, '51999': {
        en: 'Beach Volleyball Outright Period', cn: '沙滩排球冠军阶段',
    }, '94999': {
        en: 'Stock Car Racing Outright Period', cn: '赛车冠军阶段',
    }, '12999': {
        en: 'Golf Outright Period', cn: '高尔夫冠军阶段',
    }, '17999': {
        en: 'Futsal Outright Period', cn: '五人制足球冠军阶段',
    }, '19999': {
        en: 'Boxing Outright Period', cn: '拳击冠军阶段',
    }, '20999': {
        en: 'Dart Outright Period', cn: '飞镖冠军阶段',
    }, '21999': {
        en: 'Bowls Outright Period', cn: '草地滚球冠军阶段',
    }, '24999': {
        en: 'Water Polo Outright Period', cn: '水球冠军阶段',
    }, '4999': {
        en: 'Rugby Outright Period', cn: '橄榄球冠军阶段',
    }, '95999': {
        en: 'Motorcycle Racing Outright Period', cn: '摩托车赛冠军阶段',
    }, '101999': {
        en: 'Asian Game', cn: '亚运会冠军阶段',
    }, '1001001': {
        en: 'Virtual Soccer Full Time', cn: '虚拟足球全场',
    }, '1001002': {
        en: 'Virtual Soccer First Half', cn: '虚拟足球上半场',
    }, '1001003': {
        en: 'Virtual Soccer Extra First Half', cn: '虚拟足球加时上半场',
    }, '1001004': {
        en: 'Virtual Soccer Extra Full Time', cn: '虚拟足球加时全场',
    }, '1001005': {
        en: 'Virtual Soccer Penalty', cn: '虚拟足球点球大战',
    }, '1020001': {
        en: 'Virtual Horse Default Period', cn: '虚拟赛马默认阶段',
    }, '1021001': {
        en: 'Virtual Greyhounds Default Period', cn: '虚拟赛狗默认阶段',
    }, '1022001': {
        en: 'Virtual Speedway Default Period', cn: '虚拟沙地摩托车默认阶段',
    }, '1023001': {
        en: 'Virtual Motorbike Default Period', cn: '虚拟摩托车默认阶段',
    }
}
