<template>
  <div class="login_form">
    <div class="headBox">

      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">

      <span >
        {{$t('uc.finance.recordetail')}}
      </span>
      <div style="display: flex;align-items: center;">
        <!-- <img src="../assets/logo/date.png" style="margin-right: 20px;" width="20" alt="" @click="datepicke"> -->
        <img src="../assets/logo/ma11.png" width="20" alt="" @click="otherpicke">
     </div>



    </div>


<div style="margin-top: 45px;">
  <Tabs border="false" v-model="activeName" :animated="false">
        <TabPane :label="$t('uc.finance.xx124')"  name="0" style="padding-left: 10px;padding-right: 10px;">
          <!-- <todo-list style="height:100%;" message='33' :records="datalist" /> -->
          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b;">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card  dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div style="display:flex; justify-content: space-between;">

              <div style="font-size: x-small;color: #4b4b4b;" >{{ paserReceipt(item.tranCode) }}</div>
              <div style="color: #4b4b4b;font-size: x-small;">
                <span>{{ item.ccy }} :
              </span>
              <span style="color: #8d8d8d">
                {{ Number(item.tradeAmount).toFixed(2)}}</span>
              </div>
              <!-- <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.tradeAmount).toFixed(2)}}</span> -->


              <!-- <div v-if="item.tranCode == 'CGMY'" style="display:flex; align-items:center;color:#fff; margin-left:200px">
                <div >
                  USDT
                <img src="../assets/logo/exxx.png" width="20" alt="">
                <span>
                  {{ item.ccy }}
                </span>
                </div>


                <div>

                </div>
              </div>   -->

          </div>
          <div class="body_box1">
              <!-- <div style="display: flex;justify-content: space-between;width: 100%;color: #fff;margin-top:5px">
                <span>{{ item.ccy }} : {{ Number(item.tradeAmount).toFixed(2)}}
              </span>
              </div> -->
              <div style="display: flex; justify-content: flex-start; width: 100%; font-size: x-small;color: #4b4b4b;margin-top:5px">
                <span> {{ item.tradeTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>


          </div>
            </Card>
        </Scroll>
        </TabPane>
        <TabPane :label="$t('uc.finance.xx125')" name="1" style="padding-left: 10px;padding-right: 10px;">

          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div  style="width:100%;display:flex;justify-content:space-between;color: #4b4b4b;">
              <!-- <span style="font-weight: 600;" v-if="item.depositType == '0'">{{ $t('uc.finance.xx19') }}</span>
              <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx20') }}</span> -->
              <span style="font-size: x-small;" >{{ handledep(item.depositType) }}</span>
              <span v-if="item.status == 1" style="color: green;font-size: x-small;">{{ parseStatus(item.status) }}</span>
              <span v-else style="color: red;font-size: x-small;">{{ parseStatus(item.status) }}</span>

          </div>
          <div class="body_box1">
            <div style="display: flex; justify-content: flex-end; font-size: x-small;color: #4b4b4b;">
                <span> {{ item.createTime}}</span>
              </div>
<div>
  <span style="font-size:x-small;color: #4b4b4b;" >{{ item.ccy }} : </span>
            <span style="font-size:x-small;color: #8d8d8d">{{ Number(item.depositAmount).toFixed(2)}}</span>
  </div>


              <!-- <div style="display: flex;justify-content: space-between;width: 100%;">
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span> -->

              <!-- </div> -->
              <!-- <span>{{ $t('uc.finance.invitingmining') }} : {{ Number(item.balance).toFixed(2)}}</span> -->


          </div>
            </Card>
        </Scroll>
        </TabPane>
        <TabPane :label="$t('uc.finance.xx126')" name="2" style="padding-left: 10px;padding-right: 10px;">
          <!-- <todo-list style="height:100%;" message='32' :records="datalist" /> -->
          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div style="width:100%;display:flex;justify-content:space-between;font-size: x-small; color: #4b4b4b;">
              <!-- <span style="font-weight: 600;" v-if="item.withdrawType == '0'">{{ $t('uc.finance.xx19') }}</span>
              <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx22') }}</span>
              <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.withdrawalAmount).toFixed(2)}}</span>
              <span>{{ parseStatus(item.status) }}</span> -->
              <span style="font-size: x-small;color: #4b4b4b;" >{{ handlewith(item.withdrawType) }}</span>
              <span v-if="item.status == 1" style="color: green;font-size: x-small;">{{ parseStatus(item.status) }}</span>
              <span v-else style="color: red;font-size: x-small;">{{ parseStatus(item.status) }}</span>

          </div>
          <div class="body_box1">
              <!-- <div style="display: flex;justify-content: space-between;width: 100%;">
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span>
               -->
               <div style="display: flex; justify-content: flex-end; font-size: x-small;color: #4b4b4b;">
                <span> {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>
              <div>
                <span style="font-size: x-small;color: #4b4b4b;" >{{ item.ccy }} : </span>
                <span style="font-size: x-small;color: #8d8d8d;">{{ Number(item.withdrawalAmount).toFixed(2)}}</span>

              </div>

              <!-- </div> -->
              <!-- <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.balance).toFixed(2)}}</span> -->


          </div>
            </Card>
        </Scroll>
        </TabPane>
        <TabPane :label="$t('common.exchange')" name="3" style="padding-left: 10px;padding-right: 10px;">
          <!-- <todo-list style="height:100%;" message='32' :records="datalist" /> -->
          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div style="width:100%;display:flex;justify-content:space-between;color: #fff;">
              <!-- <span style="font-weight: 600;" v-if="item.withdrawType == '0'">{{ $t('uc.finance.xx19') }}</span>
              <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx22') }}</span>
              <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.withdrawalAmount).toFixed(2)}}</span>
              <span>{{ parseStatus(item.status) }}</span> -->
              <span style="font-size:x-small;color: #4b4b4b;" >{{ item.remark}}</span>
              <span v-if="item.status == 1" style="color: green;font-size: x-small;">{{ parseStatus(item.status) }}</span>
              <span v-else style="color: red;font-size: x-small;">{{ parseStatus(item.status) }}</span>
              <div>
                <span style="font-size:x-small;color: #4b4b4b;" >{{ item.ccy }} : </span>
                <span style="font-size:x-small;color: #8d8d8d;"> {{ Number(item.tradeAmount).toFixed(2)}} </span>
                </div>

          </div>
          <div class="body_box1">
              <!-- <div style="display: flex;justify-content: space-between;width: 100%;">
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span>
               -->


              <!-- </div> -->
              <!-- <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.balance).toFixed(2)}}</span> -->
              <div style="display: flex; justify-content: flex-end; font-size: x-small;color: gray;">
                <span> {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>

          </div>
            </Card>
        </Scroll>
        </TabPane>
        <TabPane :label="$t('uc.finance.trans')" name="4" style="padding-left: 10px;padding-right: 10px;">
          <!-- <todo-list style="height:100%;" message='32' :records="datalist" /> -->
          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div style="width:100%;display:flex;justify-content:space-between;font-size:x-small;color: #4b4b4b;">

              <div >
                <span style="font-size:x-small;color: #4b4b4b;" v-if="item.tradeAmount < 0">转出 </span>
                <span style="font-size:x-small;color: #4b4b4b;" v-else>转入 </span>
                </div>
<div>
  <span style="font-size:x-small;color: #4b4b4b;" >{{ item.ccy }} : </span>
                <span style="font-size:x-small;color: #8d8d8d;"> {{ Number(item.tradeAmount).toFixed(2)}}</span>

  </div>

          </div>
          <div class="body_box1">
              <!-- <div style="display: flex;justify-content: space-between;width: 100%;">
                <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
              </span>
               -->


              <!-- </div> -->
              <!-- <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.balance).toFixed(2)}}</span> -->
              <div style="display: flex; justify-content: flex-end; font-size: x-small;color: #4b4b4b;">
                <span> {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss')}}</span>
              </div>

          </div>
            </Card>
        </Scroll>
        </TabPane>


    </Tabs>

</div>
<!-- <Page :total="totalPage" size="small" @on-change ='nextPage'/> -->

<calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange" style="color: #0073ee;"/>
<Drawer :title="$t('uc.finance.xx3')" placement="bottom"  v-model="value8" height="25" >

  <RadioGroup v-model="rechargestatus" size="large" v-if="activeName == '1'">

        <Radio label="0" >{{ $t('uc.finance.xx63') }}</Radio>
        <Radio label="1" >{{ $t('uc.finance.xx64') }}</Radio>
        <Radio label="2" >{{ $t('uc.finance.xx65') }}</Radio>
        <Radio label="3" >{{ $t('common.exchangeAll') }}</Radio>
        <Radio label="4" >{{$t('uc.finance.xx137')  }}</Radio>
        <Radio label="5" >{{$t('uc.finance.xx138')  }}</Radio>
        <Radio label="6" >{{$t('uc.finance.xx139')  }}</Radio>
        <Radio label="7" >{{$t('uc.finance.xx140')  }}</Radio>
    </RadioGroup>

    <RadioGroup v-model="withdrawstatus" size="small" v-else-if="activeName == '2'">
        <Radio label="0" >{{ $t('uc.finance.xx128') }}</Radio>
        <Radio label="1" >{{ $t('uc.finance.xx129') }}</Radio>
        <Radio label="2" >{{ $t('uc.finance.xx130') }}</Radio>
        <Radio label="3" >{{ $t('uc.finance.xx65') }}</Radio>
        <Radio label="4" >{{$t('common.exchangeAll')  }}</Radio>
        <Radio label="5" >{{$t('uc.finance.xx137')  }}</Radio>
        <Radio label="6" >{{$t('uc.finance.xx138')  }}</Radio>
        <Radio label="7" >{{$t('uc.finance.xx139')  }}</Radio>
        <Radio label="8" >{{$t('uc.finance.xx140')  }}</Radio>
    </RadioGroup>

    <RadioGroup v-model="commonstatus" size="large" v-else>
        <Radio label="0" >{{$t('common.exchangeAll')  }}</Radio>
        <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
        <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
        <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
        <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>
    </RadioGroup>

    <Button type="primary" long style="margin-top: 20px;" @click="closebtm">{{ $t('common.ok') }}</Button>

    </Drawer>
    <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
  </div>
</template>



<script>

import dayjs from 'dayjs';
export default {

 data() {
return{
datalist:[],
defaultDate:[ dayjs(), dayjs().add(-7, 'day') ],
activeName: "",
currentPage: 1,
      totalPage: 0,
      calendarShow:false,
      eloading: false,
      value8:false,
      rechargestatus: '3',
      withdrawstatus: '4',
      commonstatus: '0',
      isReachBottom: false,
      realHeight: 0,
      startDate:'',
      endDate: '',
      showxxy: ''

}
 },

 watch: {

  activeName: {
      handler(newVal, oldVal) {
        console.log(`Dog changed: ${oldVal}`);
        this.currentPage = 1
        this.isReachBottom = false
        if (newVal == '0') {
          this.getTrans(null)

      }else if (newVal == '1') {
        this.getDeposits()

      }else if (newVal == '2') {
        this.getWithDraw()
      }else if (this.activeName == '3') {
      this.getTrans('CGMY')
    }else if (this.activeName == '4') {
      this.getTrans('HYZZ')
    }
      },
      immediate: true
    }
  },

  mounted(){
    const windowHeight = document.documentElement.clientHeight;
    this.realHeight = (Number(windowHeight)) * (1 - (100/windowHeight))

    // if (browser().ios) {
    //    console.log('xy')
    // }
    window.addEventListener('resize', this.renderResize, false);

  },

created: function() {
    this.endDate  = this.defaultDate[0].format('YYYY-MM-DD')
    this.startDate  = this.defaultDate[1].format('YYYY-MM-DD')
    // this.getTrans();
    this.activeName = '0'
  },


methods: {


  handlewith(xy){
    let xx = ''
    switch (xy) {
  case 0:
    xx = this.$t('uc.finance.xx188')
    break;
  case 1:
    xx = this.$t('uc.finance.xx187')
    break;
  case 2:
    xx = this.$t('uc.finance.xx185')
    break;
  case 3:
    xx = this.$t('uc.finance.xx180')
    break;
  case 4:
    xx = this.$t('uc.finance.xx181')
    break;
  case 5:
    xx = this.$t('uc.finance.xx179')
    break;
  case 6:
    xx = this.$t('uc.finance.xx182')
    break;
  default:
    break;
}

return xx
  },
  handledep(xy){
    let xx = ''
switch (xy) {
  case 0:
    xx = this.$t('uc.finance.xx188')
    break;
  case 1:
    xx = this.$t('uc.finance.xx187')
    break;
  case 2:
    xx = this.$t('uc.finance.xx185')
    break;
  case 3:
    xx = this.$t('uc.finance.xx180')
    break;
  case 4:
    xx = this.$t('uc.finance.xx181')
    break;
  case 5:
    xx = this.$t('uc.finance.xx179')
    break;
  case 6:
    xx = this.$t('uc.finance.xx182')
    break;
  default:
    break;
}

return xx
  },
  closebtm(){
    this.value8 = false
    this.currentPage = 1
    this.isReachBottom = false
    if (this.activeName == '0') {
      this.getTrans()
    }else if (this.activeName == '1') {
      this.getDeposits()
    }else if (this.activeName == '2') {
      this.getWithDraw()
    }else if (this.activeName == '3') {
      this.getTrans('CGMY')
    }else if (this.activeName == '4') {
      this.getTrans('HYZZ')
    }
  },

  parseStatus(status){
      let xyz = ''
      switch (status) {
        case 0:
          xyz = this.$t('uc.finance.xx63')
          break;
          case 1:
          xyz = this.$t('uc.finance.xx5')
          break;
          case 2:
          xyz = this.$t('uc.finance.xx6')
          break;
        default:
          break;
      }

      return xyz
    },



    paserReceipt(code){
      let xyz = ''
      switch (code) {
        case 'CS':
          xyz = this.$t('uc.finance.xx147')
          break;

        case 'CGMY':
          xyz = this.$t('uc.finance.xx165')
          break
        case 'CAMTX':
          xyz = this.$t('uc.finance.xx62')
          break
        case 'ZCHB':
          xyz = this.$t('uc.finance.xx166')
          break
        case 'XTDZ':
          xyz = this.$t('uc.finance.xx167')
          break
        case 'YXMD':
          xyz = this.$t('uc.finance.xx168')
          break
        case 'YXMR':
          xyz = this.$t('uc.finance.xx169')
          break
          case 'LJJJ':
          xyz = this.$t('uc.finance.xx170')
          break
          case 'YXJJ':
          xyz = this.$t('uc.finance.xx171')
          break
          case 'QXTZ':
          xyz = this.$t('uc.finance.xx172')
          break
          case 'HGTZ':
          xyz = this.$t('uc.finance.xx173')
          break
          case 'YXJS':
          xyz = this.$t('uc.finance.xx174')
          break
          case 'YXXZ':
          xyz = this.$t('uc.finance.xx175')
          break
          case 'TXSB':
          xyz = this.$t('uc.finance.xx176')
          break
          case 'HYZZ':
          xyz = this.$t('uc.finance.xx177')
          break
          case 'KSTX':
          xyz = this.$t('uc.finance.xx178')
          break
          case 'FSCZ':
          xyz = this.$t('uc.finance.xx179')
          break
          case 'BFCZ':
          xyz = this.$t('uc.finance.xx180')
          break
          case 'CJCZ':
          xyz = this.$t('uc.finance.xx181')
          break
          case 'CAMCZ':
          xyz = this.$t('uc.finance.xx182')
          break
          case 'HOCZ':
          xyz = this.$t('uc.finance.xx183')
          break
          case 'UDCZ':
          xyz = this.$t('uc.finance.xx184')
          break
          case 'SDCZ':
          xyz = this.$t('uc.finance.xx185')
          break
          case 'SDTX':
          xyz = this.$t('uc.finance.xx186')
          break
          case 'HOTX':
          xyz = this.$t('uc.finance.xx187')
          break
          case 'UDTX':
          xyz = this.$t('uc.finance.xx188')
          break
        default:
          break;
      }

      return xyz
    },

goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },

    onChange(date){

if (date[0] != null && date[1] != null) {
  this.currentPage = 1
    this.isReachBottom = false
  this.startDate = date[0].format('YYYY-MM-DD')

  this.endDate = date[1].format('YYYY-MM-DD')

  if (this.activeName == '0') {
    this.getTrans()
  }else if (this.activeName == '1') {
    this.getDeposits()
  }else if (this.activeName == '2') {
    this.getWithDraw()
  }else if (this.activeName == '3') {
      this.getTrans('CGMY')
    }else if (this.activeName == '4') {
      this.getTrans('HYZZ')
    }

}

    },

    datepicke(){
      this.calendarShow = !this.calendarShow
    },

    otherpicke(){
        this.value8 = true
    },

    nextPage(){
      if (this.isReachBottom == true) {
      return
    }
      this.currentPage += 1
      if (this.activeName == '0') {
    this.getTrans(null)
  }else if (this.activeName == '1') {
    this.getDeposits()
  }else if (this.activeName == '2') {
    this.getWithDraw()
  }
  else if (this.activeName == '3') {
      this.getTrans('CGMY')
    }else if (this.activeName == '4') {
      this.getTrans('HYZZ')
    }
    },

    getTrans(code){
      let that = this
      this.eloading = true
      let params = {}
params.pageNum = this.currentPage
if (this.currentPage == 1) {
  this.datalist = []
}
     params.pageSize = 10
     params.startTime = this.startDate
     params.endTime = this.endDate
     params.tranCode = code


     if (this.commonstatus == '1'){
      params.endTime= dayjs().format('YYYY-MM-DD')
      params.startTime = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }
    else if (this.commonstatus == '2'){
      params.endTime= dayjs().format('YYYY-MM-DD')
      params.startTime  = dayjs().format('YYYY-MM-DD')

    }else if (this.commonstatus == '3'){
      params.endTime= dayjs().format('YYYY-MM-DD')
      params.startTime  = dayjs().add(-7, 'day').format('YYYY-MM-DD')

    }else if (this.commonstatus == '4'){
      params.endTime= dayjs().format('YYYY-MM-DD')
      params.startTime  = dayjs().add(-30, 'day').format('YYYY-MM-DD')

    }



       this.$http.post(this.host + this.api.uc.memberactivity, params).then(response => {
       let resp = response.body;
       that.eloading = false
                    if (resp.code == "0000") {
                      let xyz = resp.data

                      if (xyz.length < 10) {
                        this.$Notice.info({
                        title: that.$t("uc.finance.xx14"),

                      });

                      that.isReachBottom = true
                      }

                      xyz.forEach(element => {
                        that.datalist.push(element)
                      });

                      if (that.datalist.length <= 0) {
                        that.showxxy = true
                      }else
                        that.showxxy = ''
                      that.total = resp.data.total
                    }else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    getDeposits() {
let that = this
let params = {}
this.eloading = true
params.pageNum = this.currentPage
if (this.currentPage == 1) {
  this.datalist = []
}
     params.pageSize = 10
     params.beginDateStr  = this.startDate
     params.endDateStr  = this.endDate
    //  0支付中；1完成支付；2支付失败
    if (this.rechargestatus == '4'){
      params.endDateStr= dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }
    else if (this.rechargestatus == '5'){
      params.endDateStr= dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().format('YYYY-MM-DD')

    }else if (this.rechargestatus == '6'){
      params.endDateStr= dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().add(-7, 'day').format('YYYY-MM-DD')

    }else if (this.rechargestatus == '7'){
      params.endDateStr= dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().add(-30, 'day').format('YYYY-MM-DD')

    }else if (this.rechargestatus == '3') {
      console.log('');
    }else
    params.orderStatus = this.rechargestatus

       this.$http.post(this.host + this.api.uc.getDepositList, params).then(response => {
       let resp = response.body;
       that.eloading = false
                    if (resp.code == "0000") {
                      let xyz = resp.data.list

                      if (xyz.length < 10) {
                        this.$Notice.info({
                        title: that.$t("uc.finance.xx14"),

                      });

                      that.isReachBottom = true
                      }

                      xyz.forEach(element => {
                        that.datalist.push(element)
                      });
                      if (that.datalist.length <= 0) {
                        that.showxxy = true
                      }else
                        that.showxxy = ''
that.total = resp.data.total
                    }else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    getWithDraw() {
let that = this
let params = {}
this.eloading = true
params.pageNum = this.currentPage
if (this.currentPage == 1) {
  this.datalist = []
}
     params.pageSize = 10
     params.beginDateStr = this.startDate
     params.endDateStr = this.endDate
    //  0-申请中,1-已确认,2-已驳回,3-支付失败


    if (this.withdrawstatus == '4'){
      params.endDateStr = dayjs().format('YYYY-MM-DD')
      params.beginDateStr = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }
    else if (this.withdrawstatus == '5'){
      params.endDateStr = dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().format('YYYY-MM-DD')

    }else if (this.withdrawstatus == '6'){
      params.endDateStr= dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().add(-7, 'day').format('YYYY-MM-DD')

    }else if (this.withdrawstatus == '7'){
      params.endDateStr= dayjs().format('YYYY-MM-DD')
      params.beginDateStr  = dayjs().add(-30, 'day').format('YYYY-MM-DD')

    }else if (this.withdrawstatus == '4') {
      console.log('');
    }else
    params.orderStatus = this.withdrawstatus

       this.$http.post(this.host + this.api.uc.getWithdrawList, params).then(response => {
       let resp = response.body;
       that.eloading = false
                    if (resp.code == "0000") {
                      let xyz = resp.data.list

                      if (xyz.length < 10) {
                        this.$Notice.info({
                        title: that.$t("uc.finance.xx14"),

                      })

                      that.isReachBottom = true
                      }

                      xyz.forEach(element => {
                        that.datalist.push(element)
                      });
                      if (that.datalist.length <= 0) {
                        that.showxxy = true
                      }else
                        that.showxxy = ''
that.total = resp.data.total
                    }else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

}


}
</script>

<style lang="scss" scoped>

// .login_form {
//   background: #0b1520;
//   height: 56rem;
//   position: relative;
//   overflow: hidden;
//   }

  .login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.body_box1{
       display: flex;
      justify-content: space-between;
      align-items:flex-start;
      color: #fff;
      font-size: small;
      height: auto;

    }

/deep/ .ivu-card-bordered{
  border: none;
}

.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: center;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
   justify-content: space-between;
}
.head_box{
  width: 100%;

}
.header{
  position: fixed;
  top: 0;
  left: 0;
	right: 0;
  height: 60px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items:center;
  background:url(../assets/logo/barBottom.png);
  transform: rotate(0.5turn);
}

/deep/ .ivu-tabs-bar{
  border-bottom: 0px solid #dcdee2;
  margin-bottom: 10px;
  background-color: #1B2C38;
}


/deep/ .ivu-tabs-nav-container{
  font-size: 12px;
}

/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color: #d2b965;
}


/deep/ .ivu-tabs-tab{
  color: #fff;
  padding: 12px 16px
}



/deep/ .ivu-tabs-ink-bar{
  background-color: #d2b965;
}

/deep/ .ivu-card-body{
  padding:  5px 10px;

}

.active{
  background-color:#1B2C38;
  border-radius:5px;
  height: auto;

}

.notactive{
  background-color:transparent;
  border-radius:10px;
  height: auto;

}


</style>



