<template>
    <div id="tab-bar">
        <slot></slot>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

#tab-bar {
    display: flex;
    background-color: #0f212e;

    /*让他到下面  */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    /* 看起来有阴影 */
    // box-shadow: 0px -3px 1px rgba(100,100,100,.2)
    // box-shadow:
    // 0 0 2.0em rgb(192, 192, 190);

}


</style>
