<template>
    <div class="nav-rights">
        <div class="box222">
            <img v-if="country_en != 'zh-CN'" src="../assets/logo/logojb.png" style="width: 100px;height: 20px;" alt="">
            <img src="../assets/logo/logojb1.png" style="width: 100px;height: 20px;" alt="" v-else>
            <!-- <div>
                <div style="display: flex;align-items: center;" @click="showxx">
                    <img :src="backImg" alt="" width="30">
                    <img src="../assets/logo/dwo11.png" width="20" style="margin-left: 5px;" alt="">
                </div>
            </div> -->
            <div>
                <!-- <div style="display: flex;align-items: center;" @click="showxx">
                    <img :src="backImg" alt="" width="30">
                    <img src="../assets/logo/dwo11.png" width="20" style="margin-left: 5px;" alt="">
                </div> -->
                <Button type="primary" style="width: 80px;height: 30px;  margin-right: 15px;" @click="gotoLogin">{{$t('uc.login.login')}}</Button>
                <Button type="primary" style="width: 80px;height: 30px; margin-right: 15px" @click="gotoReg">{{$t('common.register')}}</Button>
            </div>
        </div>
        <div class="banner-panel">
            <Banner :picList="picList"></Banner>
        </div>
        <div class="paomadeng">
            <img src="../assets/logo/bba.png" style="width:20px;margin:4px">
            <PaoMaDeng style="margin-right: 5px; height: 25px;background-color: transparent;color: #fff;" :delay="0.5"
                       :speed="30" :content="msgList">
                <span v-for="(item, index) in msgList" :key="index">{{ item.content }}</span>
            </PaoMaDeng>
        </div>
        <div style="display: flex;padding-right: 10px;">
            <div class="rightBar">
                <Scroll :height="scrollHeight">
                    <div style="margin-top: -10px;">
                        <!-- <span style="margin-left: 10px;">{{ this.$t("common.categoryLive") }}</span> -->
                        <SingleCardList @select="selectItem" :list="liveList"></SingleCardList>
                    </div>
                </Scroll>
            </div>
        </div>
        <Modal
            :ok-text="$t('common.ok')"
            :cancel-text="$t('common.close')"
            v-model="modalx"
            :title="$t('common.tip')"
            @on-ok="moneyOK"
            @on-cancel="moneyCancel">
            <p>{{ $t('uc.finance.xx120') }}</p>
        </Modal>
        <Modal
            v-model="modal1"
            :title="$t('uc.finance.xx81')"
            @on-ok="changelanguage(vertical)"
            @on-cancel="cancel">
            <RadioGroup v-model="vertical" vertical>
                <Radio label="zh-CN" style="display: flex;align-items: center;">

                    <img src="../assets/logo/china.png" width="20" height="20">
                    <span>{{ $t('uc.regist.china') }}</span>
                </Radio>
                <Radio label="th-TH" style="display: flex;align-items: center;">
                    <img src="../assets/logo/thai.png" width="20" height="20">
                    <span>{{ $t('uc.regist.thailand') }}</span>
                </Radio>
                <Radio label="vi-VN" style="display: flex;align-items: center;">
                    <img src="../assets/logo/vietnam.png" width="20" height="20">
                    <span>{{ $t('uc.regist.vietnam') }}</span>
                </Radio>
                <Radio label="ma-MA" style="display: flex;align-items: center;">
                    <img src="../assets/logo/mala.png" width="20" height="20">

                    <span>{{ $t('uc.regist.malaysia') }}</span>
                </Radio>
                <Radio label="ca-CB" style="display: flex;align-items: center;">
                    <img src="../assets/logo/cambodia.png" width="20" height="20">

                    <span>{{ $t('uc.regist.cambodia') }}</span>
                </Radio>
                <Radio label="in-IN" style="display: flex;align-items: center;">
                    <img src="../assets/logo/indiaN.png" width="20" height="20">

                    <span>{{ $t('uc.regist.india') }}</span>
                </Radio>
                <Radio label="en-US" style="display: flex;align-items: center;">
                    <img src="../assets/logo/usa.png" width="20" height="20">

                    <span>{{ $t('uc.regist.uk') }}</span>
                </Radio>
                <Radio label="ja-JP" style="display: flex;align-items: center;">
                    <img src="../assets/logo/japan.png" width="20" height="20">

                    <span>{{ $t('uc.regist.japan') }}</span>
                </Radio>
                <Radio label="ko-KR" style="display: flex;align-items: center;">
                    <img src="../assets/logo/korea.png" width="20" height="20">

                    <span>{{ $t('uc.regist.korea') }}</span>
                </Radio>
            </RadioGroup>
        </Modal>
        <Main-tab-bar></Main-tab-bar>
    </div>

</template>
<script>

import MainTabBar from '../components/tabbar/MainTabBar'
import PaoMaDeng from 'heyushuo-marquee';
import SingleCardList from '../components/singlecard-list.vue'
import Sticky from 'vuejs-sticky-directive'
import Banner from "@/components/Banner.vue";

export default {
    components: {
        Banner,
        MainTabBar,
        PaoMaDeng,
        SingleCardList,
    },
    directives: {Sticky},
    data() {

        return {
            offset: {
                top: 55, // 顶部偏移数
            },
            offset1: {
                top: 130, // 顶部偏移数
            },
            modal1: false,
            show: false,
            iwidth: '',
            iHeight: '',
            country: "台湾",
            vertical: '',
            loading: false,
            fGetBackFundpwd: false,
            imgPreview: "",
            imgNext: "",
            imgLast: "",
            scale: 0.3,
            translateX: -250,
            translateY: -100,
            loginmsg: this.$t("common.logintip"),
            memberlevel: "",
            canShow: false,
            activeIdSlot: 0,
            uploadHeaders: {"x-auth-token": localStorage.getItem("TOKEN")},
            form: {
                transferPass: "",
            },
            scrollHeight: 0,
            chineseLogo: false,
            eloading: false,
            slot6List: [],
            slot5List: [],
            slot4List: [],
            slot3List: [],
            slot2List: [],
            slot1List: [],

            choice: 2,
            lottoList2: [],
            lottoList1: [],
            activeIdLotto: 0,
            lastLottoTabIndex: 0,
            activeIdFish: 0,
            pokeList1: [],
            activeIdPoke: 0,
            lastPokeTabIndex: 0,
            operatorCode: '',
            languageCode: '',
            liveList: [
                // { url: 'BI-', pic:'live_single_1.jpg', desc:'LC' },
                // { url: 'DG-', pic:'live_single_2.jpg', desc:'LC', pID:1052, gType:2, lang: this.$t("common.dgLive")},
                {
                    url: 'AG-',
                    pic: 'live_single_3.png',
                    desc: 'LC',
                    pID: 1001,
                    gType: 2,
                    lang: this.$t("common.agLive"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'PP-',
                    pic: 'live_single_4.png',
                    desc: 'LC',
                    pID: 1006,
                    gType: 2,
                    lang: this.$t("common.gbLive"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'EVO-',
                    pic: 'live_single_5.png',
                    desc: 'LC',
                    pID: 1002,
                    gType: 2,
                    lang: this.$t("common.E0Live"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'WM-',
                    pic: 'live_single_6.png',
                    desc: 'LC',
                    pID: 1020,
                    gType: 2,
                    lang: this.$t("common.wcLive"),
                    state: 0,
                    notice: ''
                },


            ],

            fishList: [
                {
                    url: 'JDB_FISH',
                    pic: 'logo-jdb-fh.png',
                    desc: 'FH',
                    gType: 4,
                    lang: this.$t("uc.finance.xx161"),
                    state: 0,
                    notice: ''
                },
                // { url: 'BI-', pic:'4.png', desc:'FH',isActive:false },
            ],

            // slotList:[
            //   { url: 'JD-', pic:'logo-jdb.png', desc:'SL', isActive:true},
            //   { url: 'PG-', pic:'logoS-pg.png', desc:'SL',isActive:false },
            //   // { url: 'PL-', pic:'logo-Ag.png', desc:'SL',isActive:false },
            //   { url: 'PP-', pic:'logo-Ag.png', desc:'SL',isActive:false },
            //   { url: 'NT-', pic:'logo-Ag.png', desc:'SL',isActive:false },
            // ],

            slotList: [
                {
                    url: 'JDB_SLOT',
                    pic: 'logo-jdb.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx157"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'PG_SLOT',
                    pic: 'logoS-pg.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx158"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'PP_SLOT',
                    pic: 'PPslot.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx159"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'NT_SLOT',
                    pic: 'NEtslot.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx160"),
                    state: 0,
                    notice: ''
                },
            ],
            lottoList: [
                // { url: 'JD-', pic:'4.png', desc:'LK' },
                // { url: 'BI-', pic:'4.png', desc:'LK' },
                // { url: 'GB-', pic:'4.png', desc:'LK' },
            ],

            sportsList: [
                {
                    url: 'AG-',
                    pic: 'sports_single_1.png',
                    desc: 'cmd',
                    pID: 1078,
                    gType: 1,
                    lang: this.$t("common.agSport"),
                    state: 0,
                    notice: ''
                },
                // { url: 'SB-', pic:'sports_single_3.jpg', desc:'SB', lang: this.$t("common.ibSport")},
                // { url: 'BI-', pic:'sports_single_4.jpg', desc:'saba', pID:1046, gType:3,lang: this.$t("common.sbSport")},

            ],

            pokeList: [
                // { url: 'JD-', pic:'4.png', desc:'PK' },

            ],
            hotList: [
                {
                    url: 'CMD-',
                    pic: 'sports_single_1.png',
                    desc: 'cmd',
                    pID: 1078,
                    gType: 1,
                    lang: this.$t("common.agSport"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'AG-',
                    pic: 'live_single_3.png',
                    desc: 'LC',
                    pID: 1001,
                    gType: 2,
                    lang: this.$t("common.agLive"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'PP-',
                    pic: 'live_single_4.png',
                    desc: 'LC',
                    pID: 1006,
                    gType: 2,
                    lang: this.$t("common.gbLive"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'EVO-',
                    pic: 'live_single_5.png',
                    desc: 'LC',
                    pID: 1002,
                    gType: 2,
                    lang: this.$t("common.E0Live"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'WM-',
                    pic: 'live_single_6.png',
                    desc: 'LC',
                    pID: 1020,
                    gType: 2,
                    lang: this.$t("common.wcLive"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'JDB_SLOT',
                    pic: 'logo-jdb.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx157"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'PG_SLOT',
                    pic: 'logoS-pg.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx158"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'PP_SLOT',
                    pic: 'PPslot.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx159"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'NT_SLOT',
                    pic: 'NEtslot.png',
                    desc: 'SL',
                    gType: 3,
                    lang: this.$t("uc.finance.xx160"),
                    state: 0,
                    notice: ''
                },
                {
                    url: 'JDB_FISH',
                    pic: 'logo-jdb-fh.png',
                    desc: 'FH',
                    gType: 4,
                    lang: this.$t("uc.finance.xx161"),
                    state: 0,
                    notice: ''
                },
            ],
            tabList: [
                {url: '首页', pic: 'hot1.png', pic1: 'hot.png', desc: this.$t("common.exchangeAll"), index: 0},
                {url: '首页', pic: 'sport1.png', pic1: 'sport.png', desc: this.$t("common.categorySport"), index: 1},
                {url: '首页', pic: 'live1.png', pic1: 'live.png', desc: this.$t("common.categoryLive"), index: 2},
                {url: '首页', pic: 'slot1.png', pic1: 'slot.png', desc: this.$t("common.categorySlot"), index: 3},
                {url: '首页', pic: 'fish1.png', pic1: 'fish.png', desc: this.$t("common.categoryFish"), index: 4},
                {url: '首页', pic: 'lotto1.png', pic1: 'lotto.png', desc: this.$t("common.categoryBingo"), index: 5},
                {url: '首页', pic: 'poke1.png', pic1: 'poke.png', desc: this.$t("common.categoryPoke"), index: 6},
            ],


            activeId: 0,
            realHeight: 0,
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            rules: {
                transferPass: {
                    required: true,
                    message: this.$t("general.inputTip"),
                    trigger: "blur"
                },
            },


            dialogTitle: '资金密码',
            usernameS: "",
            user: {},
            choseItem: 0,
            accountValue: "1",
            status: 0,
            time1: 60, // 发送验证码倒计时
            time2: 60, // 发送验证码倒计时
            time3: 60, // 发送验证码倒计时
            time5: 60, // 发送验证码倒计时
            isActive: false,
            sendMsgDisabled1: false,
            sendMsgDisabled2: false,
            sendMsgDisabled3: false,
            sendMsgDisabled5: false,
            moneyList: {},
            coinType: '',
            balanceType: 0,
            sliderSellLimitPercent: 0,
            sliderSellMarketPercent: 120,
            currencyNum: '',
            gameUrl: '',
            hallUrl: '',
            isTg: false,
            transferNo: '',
            transferPass: '',
            disableBtn: false,
            currentGType: 0,
            tabBarValue: '1',
            countdown: 60,
            picShow: false,
            modalx: false,
            picList: [],
            msgList: [],
            moneybab: '',
            timercode: '',
            modoGameCode: '',
            lastFishTabIndex: 0,
            currentGameType: '',
            lastSlotTabIndex: 0,
            country_en: '',
            backImg: '',
            slotLsit: [],
            attendants: [
                {
                    app: 'whatsapp',
                    label: 'JinBei 1',
                    name: 'Jay Jay',
                    number: '855962802755',
                    avatar: {
                        src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
                        alt: 'Alan Ktquez avatar'
                    }
                },
                {
                    app: 'telegram',
                    label: 'JinBei 2',
                    name: 'zou zou',
                    username: '@jinbeiyulekefu',
                    avatar: {
                        src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
                        alt: 'Alan Ktquez avatar'
                    }
                },
                // ...
            ],

            tabBarItems: [
                {title: '首页', icon: 'ly-icon ly-icon-home', value: 'about', name: '1'},
                {title: '充值', icon: 'ly-icon ly-icon-icon--', value: 'charge', name: '2'},
                {
                    title: '提现',
                    icon: 'ly-icon ly-icon-sousuo',
                    value: 'withdraw',
                    name: '3'
                },
                {title: '我', icon: 'ly-icon ly-icon-wode', value: 'mine', name: '4'}
            ],
            startRecPacket: false,
            modal: false,
            redData: {},
            activityObj: {},
        };
    },
    methods: {
        showxx() {
            this.modal1 = true
        },
        handleItem(item) {
            this.choice = item.index
        },


        cancel() {
            this.modal1 = false
        },

        moneyOK() {
            this.$router.push('/wallet')
            this.modalx = false
        },

        moneyCancel() {
            this.modalx = false
        },

        changelanguage: function (name) {
            let tempname = name
            if (name == 'en-US-cam') {
                tempname = 'en-US'
            }

            this.$store.commit("setlang", tempname);
            this.$i18n.locale = tempname;
            this.country_en = tempname

            if (name == 'zh-HK') {
                this.backImg = require('@/assets/logo/hk.png')
                this.chineseLogo = true
            } else if (name == 'zh-CN') {
                this.backImg = require('@/assets/logo/china.png')
                this.chineseLogo = true
            } else if (name == 'en-US') {
                this.backImg = require('@/assets/logo/usa.png')
                this.chineseLogo = false
            } else if (name == 'vi-VN') {
                this.backImg = require('@/assets/logo/vietnam.png')
                this.chineseLogo = false
            } else if (name == 'ca-CB') {
                this.backImg = require('@/assets/logo/cambodia.png')
                this.chineseLogo = false
            } else if (name == 'th-TH') {
                this.backImg = require('@/assets/logo/thai.png')
                this.chineseLogo = false
            } else if (name == 'ja-JP') {
                this.backImg = require('@/assets/logo/japan.png')
                this.chineseLogo = false
            } else if (name == 'ko-KR') {
                this.backImg = require('@/assets/logo/korea.png')
                this.chineseLogo = false
            } else if (name == 'ma-MA') {
                this.backImg = require('@/assets/logo/mala.png')
                this.chineseLogo = false
            } else if (name == 'in-IN') {
                this.backImg = require('@/assets/logo/indiaN.png')
                this.chineseLogo = false
            }

            this.configLang(this.country_en)

            this.tabList[0].desc = this.$t("common.exchangeAll")
            this.tabList[1].desc = this.$t("common.categorySport")
            this.tabList[2].desc = this.$t("common.categoryLive")
            this.tabList[3].desc = this.$t("common.categorySlot")
            this.tabList[4].desc = this.$t("common.categoryFish")
            this.tabList[5].desc = this.$t("common.categoryBingo")
            this.tabList[6].desc = this.$t("common.categoryPoke")


            // this.liveList[0].lang = this.$t("common.dgLive")
            this.liveList[0].lang = this.$t("common.agLive")
            this.liveList[1].lang = this.$t("common.gbLive")
            this.liveList[2].lang = this.$t("common.E0Live")
            this.liveList[3].lang = this.$t("common.wcLive")


            this.sportsList[0].lang = this.$t("common.agSport")

            this.fishList[0].lang = this.$t("uc.finance.xx161")

            this.slotList[0].lang = this.$t("uc.finance.xx157")
            this.slotList[1].lang = this.$t("uc.finance.xx158")
            this.slotList[2].lang = this.$t("uc.finance.xx159")
            this.slotList[3].lang = this.$t("uc.finance.xx160")


            this.hotList[0].lang = this.$t("common.agSport")
            this.hotList[1].lang = this.$t("common.agLive")
            this.hotList[2].lang = this.$t("common.gbLive")
            this.hotList[3].lang = this.$t("common.E0Live")
            this.hotList[4].lang = this.$t("common.wcLive")
            this.hotList[5].lang = this.$t("uc.finance.xx157")
            this.hotList[6].lang = this.$t("uc.finance.xx158")
            this.hotList[7].lang = this.$t("uc.finance.xx159")
            this.hotList[8].lang = this.$t("uc.finance.xx160")
            this.hotList[9].lang = this.$t("uc.finance.xx161")

        },


        setCurrency() {

            this.liveList.forEach(element => {
                if (!element.url.includes(this.coinType)) {
                    element.url = element.url + this.coinType
                }

            });

            this.fishList.forEach(element => {
                if (!element.url.includes(this.coinType)) {
                    element.url = element.url + this.coinType
                }
            });

            this.slotList.forEach(element => {
                if (!element.url.includes(this.coinType)) {
                    element.url = element.url + this.coinType
                }
            });

            this.lottoList.forEach(element => {
                if (!element.url.includes(this.coinType)) {
                    element.url = element.url + this.coinType
                }
            });

            this.pokeList.forEach(element => {
                if (!element.url.includes(this.coinType)) {
                    element.url = element.url + this.coinType
                }
            });

            this.sportsList.forEach(element => {
                if (!element.url.includes(this.coinType)) {
                    element.url = element.url + this.coinType
                }
            })


            this.hotList.forEach(element => {
                    if (!element.url.includes(this.coinType)) {
                        element.url = element.url + this.coinType
                    }
                }
            )

        },

        gotoLogin() {

            this.$router.push("/login");
        },

        gotoReg() {
            this.$router.push("/MobileRegister");
        },


        jumpSlot(item) {

            if (!this.isLogin) {
                this.gotoLogin()
                return
            }

            let param = {}
            param.desc = item.url
            param.langgg = this.languageCode
            param.opp = this.operatorCode
            switch (param.desc) {
                case 'JDB_SLOT':
                    param.pid = '1085'
                    break;
                case 'PG_SLOT':
                    param.pid = '1007'
                    break;
                case 'PP_SLOT':
                    param.pid = '1006'
                    break;
                case 'NT_SLOT':
                    param.pid = '1109'
                    break;
                default:
                    break;
            }
            let xxxyx = JSON.stringify(param)
            this.$router.push({path: '/singlePage', query: {gid: xxxyx}})
        },
        jumpFish(item) {
            if (!this.isLogin) {
                this.gotoLogin()
                return
            }
            let param = {}
            param.desc = item.url
            param.langgg = this.languageCode
            param.opp = this.operatorCode
            switch (param.desc) {
                case 'JDB_FISH':
                    param.pid = '1085'
                    break;

                default:
                    break;
            }
            let xxxyx = JSON.stringify(param)

            this.$router.push({path: '/singlePage', query: {gid: xxxyx}})
        },

        getActiveTabFish(data) {
            console.log(data)
            let that = this
            this.activeIdFish = data.id || 0

            if (this.activeIdFish == this.lastFishTabIndex) {
                return
            } else {
                this.lastFishTabIndex = this.activeIdFish
                if (this.activeIdFish == 0) {
                    let temp = 'JD-' + (this.coinType || 'USDT')

                    that.getFishGameList(temp)

                } else if (this.activeIdFish == 1) {
                    // that.getFishGameList('BI-USD')
                    let item = {}
                    item.url = 'BI-' + (this.coinType || 'USDT')

                    item.desc = 'FH'
                    that.selectItem(item)
                }
// else if (this.activeIdFish == 2) {
//   let temp = 'BI-'+this.coinType

//    that.getFishGameList(temp)
// }
            }

        },

        getActiveTabPoke(data) {
            let that = this
            this.activeIdLotto = data.id || 0

            if (this.activeIdPoke == this.lastPokeTabIndex) {
                return
            } else {
                this.lastPokeTabIndex = this.activeIdPoke
                if (this.activeIdPoke == 0) {
                    let temp = 'JD-' + (this.coinType || 'USDT')

                    that.getPokeGameList(temp)

                }
            }
        },

        getActiveTabLotto(data) {
            let that = this
            this.activeIdLotto = data.id || 0

            if (this.activeIdLotto == this.lastLottoTabIndex) {
                return
            } else {
                this.lastLottoTabIndex = this.activeIdLotto
                if (this.activeIdLotto == 0) {
                    let temp = 'JD-' + (this.coinType || 'USDT')
                    that.getLottoGameList(temp)

                } else if (this.activeIdLotto == 1) {

                    let item = {}
                    item.url = 'BI-' + this.coinType
                    item.desc = 'LK'
                    that.selectItem(item)

                }
            }
        },


        getActiveTabSlot(data) {
            let that = this
            this.activeIdSlot = data.id || 0

            if (this.activeIdSlot == this.lastSlotTabIndex) {
                return
            } else {
                this.lastSlotTabIndex = this.activeIdSlot
                if (this.activeIdSlot == 0) {
                    let temp = 'JD-' + (this.coinType || 'USDT')
                    let pid = 1085
                    that.getSlotGameList(temp, pid)
                    that.slotList[0].isActive = true
                    that.slotList[1].isActive = false
                    that.slotList[2].isActive = false
                    that.slotList[3].isActive = false
                }
// else if (this.activeIdSlot == 4) {
//   let temp = 'PL-'+this.coinType
//   let pid = 1011
//   that.getSlotGameList(temp,pid)
//   that.slotList[0].isActive = false
//    that.slotList[1].isActive = false
//    that.slotList[2].isActive = true
//    that.slotList[3].isActive = false

// }
                else if (this.activeIdSlot == 1) {
                    let temp = 'PG-' + (this.coinType || 'USDT')
                    let pid = 1007
                    that.getSlotGameList(temp, pid)
                    that.slotList[0].isActive = false
                    that.slotList[1].isActive = true
                    that.slotList[2].isActive = false
                    that.slotList[3].isActive = false
                } else if (this.activeIdSlot == 2) {
                    let temp = 'PP-' + this.coinType
                    let pid = 1006
                    that.getSlotGameList(temp, pid)
                    that.slotList[0].isActive = false
                    that.slotList[1].isActive = false
                    that.slotList[2].isActive = true
                    that.slotList[3].isActive = false
                } else if (this.activeIdSlot == 3) {
                    let temp = 'NT-' + this.coinType
                    let pid = 1109
                    that.getSlotGameList(temp, pid)
                    that.slotList[0].isActive = false
                    that.slotList[1].isActive = false
                    that.slotList[2].isActive = false
                    that.slotList[3].isActive = true
                }
            }

        },

        getActiveTab(data) {
            console.log(data);
            this.activeId = data.id || 0;
            if (this.activeId == 3) {
                this.activeIdFish = 0
                this.lastFishTabIndex = 0

                let temp = 'JD-' + (this.coinType || 'USDT')
                let pid = 1085
                this.$nextTick(() => {
                    this.$refs.scrollTab2.calcWidth()

                });
                this.getFishGameList(temp, pid)
            } else if (this.activeId == 2) {
                this.activeIdSlot = 0
                this.lastSlotTabIndex = 0
                let temp = 'JD-' + (this.coinType || 'USDT')
                let pid = 1085
                this.slotList[0].isActive = true
                this.slotList[1].isActive = false
                this.slotList[2].isActive = false
                this.slotList[3].isActive = false

                this.getSlotGameList(temp, pid)

                this.$nextTick(() => {
                    this.$refs.scrollTab1.calcWidth()

                });


            } else if (this.activeId == 1) {

                // this.checkLiveGameStatus()

                this.$nextTick(() => {
                    this.realHeight = '80rem'

                });


            }
                // else if (this.activeId == 4) {
                //   this.activeIdLotto = 0;
                //   this.lastLottoTabIndex = 0
                //   let temp = 'JD-'+this.coinType
                //   this.$nextTick(() => {
                //     this.$refs.scrollTab3.calcWidth()

                // });
                //   this.getLottoGameList(temp)
                // }
                // else if (this.activeId == 5) {
                //   this.activeIdPoke = 0;
                //   this.lastPokeTabIndex = 0
                //   let temp = 'JD-'+this.coinType
                //   this.$nextTick(() => {
                //     this.$refs.scrollTab4.calcWidth()

                // });
                //   this.getPokeGameList(temp)
            // }
            else {
                this.realHeight = '50rem'
            }
        },


        selectSBSBItem() {


            this.eloading = true
            let curr = 96
            let param = {};
            let that = this
            switch (this.coinType) {
                case 'USD':
                    curr = 3
                    break;
                case 'THB':
                    curr = 4
                    break;
                case 'CNY':
                    curr = 13
                    break
                case 'IDR':
                    curr = 15
                    break
                case 'JPY':
                    curr = 32
                    break;
                case 'KRW':
                    curr = 45
                    break;
                case 'VND':
                    curr = 51
                    break
                case 'USDT':
                    curr = 96
                    break
                default:
                    break;
            }

            // username (string, optional),
// oddstype (string, optional),
// maxtransfer (number, optional),
// mintransfer (number, optional),
//       currency (integer, optional),
// vendor_member_id (string, optional)
            console.log(curr);
            param.currency = 20
            param.username = '' + this.$store.getters.member.userNo
            param.vendor_member_id = '' + this.$store.getters.member.userNo
            param.maxtransfer = 9999999999
            param.mintransfer = 1
            param.oddstype = '2'
            this.$http
                .post(this.host + this.api.uc.sbsbApi, param)
                .then(response => {
                    var resp = response.body;
                    that.eloading = false
                    if (resp.error_code == 0 || resp.error_code == 6) {
                        // self.gameUrl = resp.Url
                        that.getSabaUrl()

                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.ErrorMessage
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })

        },

        getSabaUrl() {
            let that = this
            let param = {}
            param.platform = 2
            param.vendor_member_id = '' + this.$store.getters.member.userNo
            this.$http
                .get(this.host + this.api.uc.sabaUrl, {params: param})
                .then(response => {
                    var resp = response.body;
                    that.eloading = false
                    if (resp.error_code == 0) {
                        that.gameUrl = resp.data
                        that.$router.push({path: '/GamePage', query: {gpage: that.gameUrl, gid: 'saba'}})

                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.ErrorMessage
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })

        },

        selectSBItem() {

            let that = this
            this.eloading = true
            let param = {};
            param['ccy'] = this.coinType
            let ccx = this.coinType
            if (this.coinType == 'VND') {
                ccx = 'VD'
            }
            param['CurrencyCode'] = ccx


            this.$http
                .get(this.host + this.api.uc.cmdSbExistApi, {params: {'ccy': this.coinType}})
                .then(response => {
                    var resp = response.body;
                    that.eloading = false
                    if (resp.code == 0) {

                        if (resp.data == 'true') {
                            let param1 = {}
                            param1.lang = that.country_en

                            param1.CurrencyCode = that.coinType
                            let cyz = param1.CurrencyCode
                            if (cyz == 'VND') {
                                cyz = 'VD'
                            }
                            // that.$router.push({ path: '/GamePage', query: { gpage: param1, gid:'cmd' } })
                            let user = that.$store.getters.member.userNo + '-' + param1.CurrencyCode
                            let url = 'https://jbsmart.1win888.net/auth.aspx?lang=' + param1.lang + '&user=' + user + '&token=' + localStorage.getItem('TOKEN') + '&currency=' + cyz + '&templatename=green&view=v1'

                            // https://jbsmart.1win888.net 测试
                            // https://zf806smart.fts368.com 正式
                            window.open(url, "_self")

                        } else {
                            that.$http
                                .get(that.host + that.api.uc.cmdSbApi, {params: param})
                                .then(response => {
                                    var resp = response.body;
                                    that.eloading = false
                                    if (resp.code == 0 || resp.code == -98) {
                                        let param1 = {}
                                        param1.lang = that.country_en
                                        param1.CurrencyCode = that.coinType
                                        let cyz = param1.CurrencyCode
                                        if (cyz == 'VND') {
                                            cyz = 'VD'
                                        }

                                        let user = that.$store.getters.member.userNo + '-' + param1.CurrencyCode
                                        let url = 'https://jbsmart.1win888.net/auth.aspx?lang=' + param1.lang + '&user=' + user + '&token=' + localStorage.getItem('TOKEN') + '&currency=' + cyz + '&templatename=green&view=v1'


//  window.location.href = url

                                        window.open(url, "_self")
                                        //  that.$router.push({ path: '/GamePage', query: { gpage: param1, gid:'cmd' } })
                                    } else {
                                        // self.$Message.error(resp.mesg);
                                        that.$Notice.error({
                                            title: that.$t("common.tip"),
                                            desc: resp.ErrorMessage || '不支持此币种'
                                        });

                                    }
                                })
                        }


                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.ErrorMessage
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })


        },

        selectItem_item(item) {
            if (!this.isLogin) {
                this.gotoLogin()
                return
            }

            if (item.desc == 'saba') {
                this.selectSBSBItem()

            } else if (item.desc == 'cmd') {
                this.selectSBItem()

            } else if (item.desc == 'LC') {
                this.eloading = true
                let param = {};

                param['gameType'] = item.gType
                param['languageCode'] = this.languageCode
                param['operatorCode'] = this.operatorCode
                param['platform'] = 1
                param['productID'] = item.pID

                let that = this
                this.$http
                    .post(this.host + this.api.uc.mdboLaunchGame, param)
                    .then(response => {
                        var resp = response.body;
                        that.eloading = false
                        if (resp.ErrorCode == 0) {
                            that.gameUrl = resp.Url

                            this.$router.push({path: '/GamePage', query: {gpage: that.gameUrl, gid: param.gameCode}})
                        } else {
                            // self.$Message.error(resp.mesg);
                            this.$Notice.error({
                                title: this.$t("common.tip"),
                                desc: resp.ErrorMessage
                            });
                            // this.$Message.error(this.$t('common.logintip'));
                        }
                    })
            } else if (item.desc == 'SL') {
                this.jumpSlot(item)
            } else if (item.desc == 'FH') {
                this.jumpFish(item)
            }
        },

        selectItem(item) {
            if (!this.isLogin) {
                this.gotoLogin()
                return
            }
            if (item.desc == 'saba') {
                this.selectSBSBItem()
                return
            } else if (item.desc == 'cmd') {
                this.selectSBItem()
                return
            }


            this.eloading = true
            let param = {};

            param['gameType'] = item.gType
            param['languageCode'] = this.languageCode
            param['operatorCode'] = this.operatorCode
            param['platform'] = 1
            param['productID'] = item.pID

            let that = this
            this.$http
                .post(this.host + this.api.uc.mdboLaunchGame, param)
                .then(response => {
                    var resp = response.body;
                    that.eloading = false
                    if (resp.ErrorCode == 0) {
                        that.gameUrl = resp.Url

                        this.$router.push({path: '/GamePage', query: {gpage: that.gameUrl, gid: param.gameCode}})
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.ErrorMessage
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                })


        },
        desRedPacket(val) {
            this.startRecPacket = false  //红包组建销毁
            this.modal = true   //唤醒弹窗
            this.redData = val
        },
        handleTabBarChange(value) {
            console.log('LyTabBar change:', value);

            if (value == 'charge') {
                this.$router.push("/Recharge");

            } else if (value == 'withdraw') {
                this.$router.push("/Withdraw");

            }

        },

        gotoService() {
            // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

            // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
            // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

            this.$router.push('/customservice')
        },

        colseWC() {
            this.show = false
            this.$store.commit('setIsGoWC', true)
        },

        silderGo(silder, val) {
            this[silder] = val;
        },
        tipFormat(val) {
            this.currencyNum = val
            return val;
        },
        beforeUpload(data) {
            if (data && data.size >= 1024000 * 2) {
                this.$Message.error("上传图片大小不能超过2M");
                return false;
            }
        },
        isPicNull(val) {
            if (val == null) {
                return true
            }

            return false;
        },

        makePath() {

            this.hotList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);

            });

            this.sportsList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);

            });
            this.liveList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);
            });
            this.slotList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);
            });
            this.fishList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);
            });
            this.lottoList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);
            });
            this.pokeList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);
            });
            this.tabList.forEach(element => {
                element.src = require('@/assets/logo/' + element.pic);
                element.src1 = require('@/assets/logo/' + element.pic1);
            });
        },


        track() {
            // document.getElementById('baidu').style.display = 'none'
            //       document.addEventListener('visibilitychange', this.checkBrowserChange);
        },
        checkBrowserChange() {

            if (document.hidden == false) {
                // this.getTime()
                this.countdown = 60
                clearInterval(this.timercode)
            } else {
                let that = this
                this.timercode = setInterval(() => {
                    that.countdown--;

                    if (that.countdown <= 0) {
                        clearInterval(that.timercode)
                        that.$store.commit("setMember", null);
                        localStorage.setItem("MEMBER", JSON.stringify(null));
                        localStorage.setItem("TOKEN", null);
                        localStorage.removeItem("USERKEY", null);
                        that.$router.push({path: '/'}).catch(() => {
                        });
                        that.countdown = 60;
                    }
                }, 1000);


            }

        },


//       getout(){
//         let yyy = []
//         xxy.forEach(element2 => {
//          let xx = element2.referer
//          let ccc = xx.split('.')

// if (ccc.length == 2 & !xx.includes('?')) {
//   let ccy = {'hhh':xx}
//   yyy.push(ccy)
// }
//   })

// let arr = yyy
// for(var i=0; i<arr.length; i++){
//             for(var j=i+1; j<arr.length; j++){
//                 if(arr[i].hhh==arr[j].hhh){         //第一个等同于第二个，splice方法删除第二个
//                     arr.splice(j,1);
//                     j--;
//                 }
//             }
//         }

//   console.log(yyy);
//       },


        getPokeGameList(platform) {
            this.loading = true
            let that = this
            let param = {};
            param["gameCode"] = platform
            param['Lang'] = this.$i18n.locale
            that.modoGameCode = platform
            this.currentGameType = 'PK'
            this.$http
                .get(this.host + this.api.uc.mdboGameList, {params: param})
                .then(response => {
                    var resp = response.body;
                    if (resp.mesg == "SUCCESS") {
                        let temp = JSON.parse(resp.data)

                        if (that.modoGameCode.includes('JD')) {
                            that.currentGType = that.gameConfig.JDB.CARD

                            temp.forEach(element => {
                                if (element.gType == that.gameConfig.JDB.CARD) {
                                    that.pokeList1 = element.list
                                }

                            });
                            that.loading = false
                        }
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                });
        },


        getLottoGameList(platform) {
            this.loading = true
            let that = this

            let param = {};
            param["gameCode"] = platform
            param['Lang'] = this.$i18n.locale
            that.modoGameCode = platform
            this.currentGameType = 'LK'
            this.$http
                .get(this.host + this.api.uc.mdboGameList, {params: param})
                .then(response => {
                    var resp = response.body;
                    if (resp.mesg == "SUCCESS") {
                        let temp = JSON.parse(resp.data)

                        if (that.modoGameCode.includes('JD')) {
                            that.currentGType = that.gameConfig.JDB.BINGO

                            temp.forEach(element => {
                                if (element.gType == that.gameConfig.JDB.BINGO) {
                                    that.lottoList1 = element.list
                                }

                            });
                            that.loading = false
                        } else if (that.modoGameCode.includes('BI')) {
                            temp.forEach(element => {
                                that.lottoList2 = element.list
                            });
                            that.loading = false
                        }
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                        that.loading = false
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                });
        },


        frontHandleSuccess(res, file, fileList) {
            this.$refs.upload1.fileList = [fileList[fileList.length - 1]];
            if (res.code == 0) {
                this.frontCardImg = this.imgPreview = res.data;
            } else {
                this.$Message.error(res.message);
            }
        },
        backHandleSuccess(res, file, fileList) {
            this.$refs.upload2.fileList = [fileList[fileList.length - 1]];
            if (res.code == 0) {
                this.backCardImg = this.imgNext = res.data;
            } else {
                this.$Message.error(res.message);
            }
        },
        handHandleSuccess(res, file, fileList) {
            this.$refs.upload3.fileList = [fileList[fileList.length - 1]];
            if (res.code == 0) {
                this.handCardImg = this.imgLast = res.data;
            } else {
                this.$Message.error(res.message);
            }
        },
        noPhone() {
            this.$Message.info(this.$t("uc.safe.bindphonetip"));
            this.showItem(3);
        },
        showItemFundpwd() {
            this.fGetBackFundpwd = false;
            this.handleReset("formValidate5");
            this.showItem(5);
        },
        renderPw() {
            this.$Modal.confirm({
                title: this.$t("uc.safe.resetfundpwd"),
                onOk: () => {
                    this.$Message.info("Clicked ok");
                },
                render: h => {
                    return h("Input", {
                        props: {
                            value: this.value,
                            autofocus: true
                        },
                        on: {
                            input: val => {
                                this.value = val;
                            }
                        }
                    });
                }
            });
        },

        handleSubmit(name) {
            this.$refs[name].validate(valid => {
                if (valid) {
                    this.submit(name);
                } else {
                    this.$Message.error(this.$t("uc.safe.save_failure"));
                }
            });
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        showItem(index) {
            this.choseItem = index;
        },

        getMoneyback() {

            let param = {};
            param["gameCode"] = this.$route.query.gid;
            let self = this;
            this.$http
                .get(this.host + this.api.uc.mdboMakeTransfer, {'params': param})
                .then(response => {
                    let resp = response.body;
                    if (resp.code == '0000') {
                        //  console.log('uuuuv');
                        console.log("");
                    } else if (resp.code == '5000') {
                        console.log("");
                    } else {
                        self.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },


        wcShow() {
            this.show = true
        },

        makeStatus(list) {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                if (element.status == '0') {
                    continue
                } else {
                    this.hotList.forEach(element1 => {
                        if (element1.url.includes(element.platformName) && Number(element.thirdBelong) == element1.gType) {
                            element1.state = Number(element.status)
                            element1.notice = element.remark

                        }
                    });
                    if (Number(element.thirdBelong) == 1) {
                        this.sportsList.forEach(element3 => {
                            if (element3.url.includes(element.platformName)) {
                                element3.state = Number(element.status)
                                element3.notice = element.remark
                            }
                        });
                    } else if (Number(element.thirdBelong) == 2) {
                        this.liveList.forEach(element7 => {
                            if (element7.url.includes(element.platformName)) {
                                element7.state = Number(element.status)
                                element7.notice = element.remark
                            }
                        });

                    } else if (Number(element.thirdBelong) == 3) {
                        this.slotList.forEach(element5 => {
                            if (element5.url.includes(element.platformName)) {
                                element5.state = Number(element.status)
                                element5.notice = element.remark
                            }
                        });

                    } else if (Number(element.thirdBelong) == 4) {
                        this.fishList.forEach(element6 => {
                            if (element6.url.includes(element.platformName)) {
                                element6.state = Number(element.status)
                                element6.notice = element.remark
                            }
                        });
                    } else if (Number(element.thirdBelong) == 5) {
                        this.lottoList.forEach(element4 => {
                            if (element4.url.includes(element.platformName)) {
                                element4.state = Number(element.status)
                                element4.notice = element.remark
                            }
                        });

                    } else if (Number(element.thirdBelong) == 6) {
                        this.pokeList.forEach(element2 => {
                            if (element2.url.includes(element.platformName)) {
                                element2.state = Number(element.status)
                                element2.notice = element.remark
                            }
                        });
                    }

                }
            }
        },

        checkPlatForm() {
            let that = this

            this.eloading = true
            this.$http
                .get(this.host + this.api.uc.platform)
                .then(response => {
                    var resp = response.body;
                    that.eloading = false
                    if (resp.code == "0000") {
                        let status = resp.data.list
                        that.makeStatus(status)
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },

        getmarquee() {
            var self = this;
            this.$http
                .post(self.host + this.api.uc.toppromotion)
                .then(response => {
                    var resp = response.body;
                    if (resp.code == "0000") {

                        self.msgList = resp.data
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },


        configCode(item) {

            if (item == 'USDT') {
                this.operatorCode = 'E502'

            } else if (item == 'USD') {
                this.operatorCode = 'E503'
            } else if (item == 'VND') {
                this.operatorCode = 'E505'
            } else if (item == 'CNY') {
                this.operatorCode = 'E504'
            } else if (item == 'IDR') {
                this.operatorCode = 'E507'
            } else if (item == 'JPY') {
                this.operatorCode = 'E508'
            } else if (item == 'KRW') {
                this.operatorCode = 'E509'
            } else if (item == 'THB') {
                this.operatorCode = 'E506'
            }

        },


        configLang(lang) {

            switch (lang) {
                case 'zh-CN':
                    this.languageCode = 3
                    break;
                case 'en-US':
                    this.languageCode = 1
                    break;
                case 'ca-CB':
                    this.languageCode = 35
                    break;
                case 'vi-VN':
                    this.languageCode = 8
                    break;
                case 'ja-JP':
                    this.languageCode = 6
                    break;
                case 'th-TH':
                    this.languageCode = 4
                    break;
                case 'ko-KR':
                    this.languageCode = 7
                    break;
                case 'ma-MA':
                    this.languageCode = 37
                    break;
                case 'in-IN':
                    this.languageCode = 40
                    break;
                default:
                    this.languageCode = 3
                    break;
            }

        },

        getMemberInfo() {
            //获取个人安全信息
            let self = this;
            this.eloading = true
            this.$http
                .post(this.host + this.api.uc.memberInfo)
                .then(response => {
                    var resp = response.body;
                    self.eloading = false
                    if (resp.code == "0000") {

                        localStorage.setItem("MEMBER", JSON.stringify(null));
                        self.$store.commit("setMember", resp.data);
                        self.coinType = resp.data.remark
                        //  self.setCurrency();
                        self.configCode(self.coinType)
                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },


        beforeunloadHandler() {
            this.iwidth = document.documentElement.clientWidth;
            this.iHeight = document.documentElement.clientHeight;
            this.realHeight = '50rem'
            this.loadPicData();
            this.getmarquee();
            this.makePath();

            if (this.isLogin) {
                this.getMemberInfo()
                //   if (this.$route.query.gid) {
                //   this.getMoneyback()
                // }
            }

            let langue = navigator.language ? navigator.language : navigator.userLanguage
            let lang = localStorage.getItem('LANGUAGE');
            this.country_en = langue || lang
            this.$i18n.locale = this.country_en;
            this.$store.commit("setlang", this.country_en);
            if (this.country_en == 'zh-HK') {
                this.backImg = require('@/assets/logo/hk.png')
                this.chineseLogo = true
            } else if (this.country_en == 'zh-CN') {
                this.backImg = require('@/assets/logo/china.png')
                this.chineseLogo = true
            } else if (this.country_en == 'en-US') {
                this.backImg = require('@/assets/logo/usa.png')
                this.chineseLogo = false
            } else if (this.country_en == 'vi-VN') {
                this.backImg = require('@/assets/logo/vietnam.png')
                this.chineseLogo = false
            } else if (this.country_en == 'ca-CB') {
                this.backImg = require('@/assets/logo/cambodia.png')
                this.chineseLogo = false
            } else if (this.country_en == 'th-TH') {
                this.backImg = require('@/assets/logo/thai.png')
                this.chineseLogo = false
            } else if (this.country_en == 'ja-JP') {
                this.backImg = require('@/assets/logo/japan.png')
                this.chineseLogo = false
            } else if (this.country_en == 'ko-KR') {
                this.backImg = require('@/assets/logo/korea.png')
                this.chineseLogo = false
            } else if (this.country_en == 'ma-MA') {
                this.backImg = require('@/assets/logo/mala.png')
                this.chineseLogo = false
            } else if (this.country_en == 'in-IN') {
                this.backImg = require('@/assets/logo/indiaN.png')
                this.chineseLogo = false
            }


            this.configLang(this.country_en)

            this.tabList[0].desc = this.$t("common.exchangeAll")
            this.tabList[1].desc = this.$t("common.categorySport")
            this.tabList[2].desc = this.$t("common.categoryLive")
            this.tabList[3].desc = this.$t("common.categorySlot")
            this.tabList[4].desc = this.$t("common.categoryFish")
            this.tabList[5].desc = this.$t("common.categoryBingo")
            this.tabList[6].desc = this.$t("common.categoryPoke")


            // this.liveList[0].lang = this.$t("common.dgLive")
            this.liveList[0].lang = this.$t("common.agLive")
            this.liveList[1].lang = this.$t("common.gbLive")
            this.liveList[2].lang = this.$t("common.E0Live")
            this.liveList[3].lang = this.$t("common.wcLive")


            this.sportsList[0].lang = this.$t("common.agSport")

            this.fishList[0].lang = this.$t("uc.finance.xx161")

            this.slotList[0].lang = this.$t("uc.finance.xx157")
            this.slotList[1].lang = this.$t("uc.finance.xx158")
            this.slotList[2].lang = this.$t("uc.finance.xx159")
            this.slotList[3].lang = this.$t("uc.finance.xx160")
            // this.sportsList[1].lang = this.$t("common.sbSport")


            this.hotList[0].lang = this.$t("common.agSport")
            this.hotList[1].lang = this.$t("common.agLive")
            this.hotList[2].lang = this.$t("common.gbLive")
            this.hotList[3].lang = this.$t("common.E0Live")
            this.hotList[4].lang = this.$t("common.wcLive")
            this.hotList[5].lang = this.$t("uc.finance.xx157")
            this.hotList[6].lang = this.$t("uc.finance.xx158")
            this.hotList[7].lang = this.$t("uc.finance.xx159")
            this.hotList[8].lang = this.$t("uc.finance.xx160")
            this.hotList[9].lang = this.$t("uc.finance.xx161")

        },

        loadPicData() {
            let param = {}
            param.adType = 'INDEX_BANNER'
            this.$http.get(this.host + this.api.uc.mycardlist, {params: param})
                .then(response => {
                    var result = response.body;
                    if (result.code == '0000') {
                        this.picList = result.data;
                        this.picShow = true;
                    } else {
                        this.picShow = false;
                    }
                });
        },

    },
    computed: {
        activeStyle() {
            return this.isActive ? {background: '#1f70ff'} : {background: '#f8faff'}
        },
        member: function () {
            return this.$store.getters.member;
        },
        lang() {
            return this.$store.state.lang;
        },
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
        sliderSellDisabled() {
            if (this.coinType !== '' && this.isTg != true) {
                return false
            }
            return true

        },

    },
    mounted() {
        this.iwidth = document.documentElement.clientWidth;
        this.iHeight = document.documentElement.clientHeight;
        console.log('sdfsdfsd    ' + this.iHeight)
        this.scrollHeight = this.iHeight * (1 - (280 / this.iHeight))
        console.log('ghghghghg    ' + this.scrollHeight)
    },
    created() {
        this.loadPicData();
        this.getmarquee();
        this.track();
        this.makePath();
        this.checkPlatForm()
        if (this.$store.getters.isGoWC == false) {
            this.wcShow()
        }
        if (this.isLogin) {
            this.getMemberInfo()
        }
        let langue = navigator.language ? navigator.language : navigator.userLanguage
        let lang = localStorage.getItem('LANGUAGE');
        this.country_en = lang || langue
        this.$i18n.locale = this.country_en;
        this.$store.commit("setlang", this.country_en);
        if (this.country_en == 'zh-HK') {
            this.backImg = require('@/assets/logo/hk.png')
            this.chineseLogo = true
        } else if (this.country_en == 'zh-CN') {
            this.backImg = require('@/assets/logo/china.png')
            this.chineseLogo = true
        } else if (this.country_en == 'en-US') {
            this.backImg = require('@/assets/logo/usa.png')
            this.chineseLogo = false
        } else if (this.country_en == 'vi-VN') {
            this.backImg = require('@/assets/logo/vietnam.png')
            this.chineseLogo = false
        } else if (this.country_en == 'ca-CB') {
            this.backImg = require('@/assets/logo/cambodia.png')
            this.chineseLogo = false
        } else if (this.country_en == 'th-TH') {
            this.backImg = require('@/assets/logo/thai.png')
            this.chineseLogo = false
        } else if (this.country_en == 'ja-JP') {
            this.backImg = require('@/assets/logo/japan.png')
            this.chineseLogo = false
        } else if (this.country_en == 'ko-KR') {
            this.backImg = require('@/assets/logo/korea.png')
            this.chineseLogo = false
        } else if (this.country_en == 'ma-MA') {
            this.backImg = require('@/assets/logo/mala.png')
            this.chineseLogo = false
        } else if (this.country_en == 'in-IN') {
            this.backImg = require('@/assets/logo/indiaN.png')
            this.chineseLogo = false
        }

        this.configLang(this.country_en)


        this.tabList[0].desc = this.$t("common.exchangeAll")
        this.tabList[1].desc = this.$t("common.categorySport")
        this.tabList[2].desc = this.$t("common.categoryLive")
        this.tabList[3].desc = this.$t("common.categorySlot")
        this.tabList[4].desc = this.$t("common.categoryFish")
        this.tabList[5].desc = this.$t("common.categoryBingo")
        this.tabList[6].desc = this.$t("common.categoryPoke")


        // this.liveList[0].lang = this.$t("common.dgLive")
        this.liveList[0].lang = this.$t("common.agLive")
        this.liveList[1].lang = this.$t("common.gbLive")
        this.liveList[2].lang = this.$t("common.E0Live")
        this.liveList[3].lang = this.$t("common.wcLive")


        this.sportsList[0].lang = this.$t("common.agSport")

        this.fishList[0].lang = this.$t("uc.finance.xx161")

        this.slotList[0].lang = this.$t("uc.finance.xx157")
        this.slotList[1].lang = this.$t("uc.finance.xx158")
        this.slotList[2].lang = this.$t("uc.finance.xx159")
        this.slotList[3].lang = this.$t("uc.finance.xx160")
        // this.sportsList[1].lang = this.$t("common.sbSport")
        // this.sportsList[2].lang = this.$t("common.sbSport")


        this.hotList[0].lang = this.$t("common.agSport")
        this.hotList[1].lang = this.$t("common.agLive")
        this.hotList[2].lang = this.$t("common.gbLive")
        this.hotList[3].lang = this.$t("common.E0Live")
        this.hotList[4].lang = this.$t("common.wcLive")
        this.hotList[5].lang = this.$t("uc.finance.xx157")
        this.hotList[6].lang = this.$t("uc.finance.xx158")
        this.hotList[7].lang = this.$t("uc.finance.xx159")
        this.hotList[8].lang = this.$t("uc.finance.xx160")
        this.hotList[9].lang = this.$t("uc.finance.xx161")


        window.addEventListener('load', e => this.beforeunloadHandler(e));
    },
    destroyed() {
        // clearInterval(this.timercode)
        // this.timercode = null
        // this.startRecPacket = false  //防止点击到其他位置 离开销毁
        window.removeEventListener('load', e => {
            console.log(e);
        })

    },

    checkLiveGameStatus() {

    }
};
</script>


<style scoped lang="scss">


.blackback {
    background: url(../assets/netmage/blackss.png);
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 10px;
}

.banner-panel {
    width: 96%;
    height: 170px;
    overflow: hidden;
    position: relative;
    margin: 60px auto 25px;
}

.textbox {
    display: flex;
    justify-content: space-around;
    color: #f4c46f;
    font-size: medium;
    font-weight: 600;
    margin-left: -10px;
}

.paomadeng {
    background-color: #66666646;
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
}


.box1 {
    // background: url(../assets/netmage/99.png);
    background-color: #000;
    width: 100%;
    height: 30%;
    //   margin-top: calc(iHeight / iwidth);

    //   @media screen and (max-height:1920px) {
    //   margin-top: 15vh;
    // }

    //  .account-item-in {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-between;
    //       margin-top: 30px;
    //       padding: 1rem;
    //       color: #fff !important;
    //       font-size: medium;
    // }

    .account-item-in_1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        color: #fff !important;
        font-size: medium;
    }

    .result-wrapper {

        width: 100%;
        height: 100vh;
        background-color: $color-background;
    }

}

.box21 {

    width: 100%;
    height: 250px;
    margin-left: 3%;

}


.safe {
    background-color: #252627;
}

button.ivu-btn {
    &:focus {
        box-shadow: 0 0 0 2px rgba(45, 140, 240, 0);
    }
}

button.ivu-btn.ivu-btn-primary {
    box-shadow: 0 0 0 2px rgba(45, 140, 240, 0);
}

.nav-rights {
    background: $color-background;
    height: $full-page;
    position: relative;
    overflow: hidden;
    // padding-left: 10px;
    // padding-right: 10px;
    text-align: center;

    .box222 {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 45px;
        z-index: 200;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        background: #2f4553;
        padding-right: 13px;
    }
}

.uploadimgtip {
    position: relative;
    top: -20px;
    color: #f0a70a;
}


.detail-list .input-control .ivu-input-group-prepend {
    width: 63px;
}

.detail-list .input-control .ivu-input {
    height: 45px;
}

.leftBar {
    width: 100px;
    color: #fff;
}

.rightBar {
    width: 90%;
    margin: auto;
}

.buy-input .ivu-input {
    color: #45b854;
    font-weight: bold;
    font-size: 22px;
    height: 40px;
}

/deep/ .vsc-popup {
    bottom: 100px;

}

.tips-g {
    color: #8994a3;
    font-size: 12px;
}

.gr {
    color: #b4b4b4;
}

.fourgame {
    width: 100%;
    height: 60vh;
    margin-top: -30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 5px;
    padding-right: 5px;
}

.fourgame1 {
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    padding-left: 5px;
    padding-right: 5px;
}


.user-right {
    width: 80%;
}

.result-list {
    padding: 5px 5px;
    height: 100%;
}

.liveshow {
    height: 200vh;
}

.user .top-icon {
    /* background: url("../../images/user/userplist.png") no-repeat 0 0; */
    width: 75px;
    height: 75px;
    display: inline-block;
}

.user .top-icon.intro {
    background-position: 0 -670px;
}

.user .user-info {
    padding: 0px 10px;
    background-color: #fff;
    color: #fff;
}

.user .user-info .user-info-top {
    border-bottom: 1px dashed #ebeff5;
    padding-bottom: 20px;
}

.user .user-info h5 {
    font-size: 18px;
}

.user .user-info h5 .iconfont {
    font-size: 20px;
    color: #e24a64;
    margin-left: 10px;
}

.user-avatar {
    display: flex;
    justify-content: space-between;
}

.user-icons {
    display: flex;
    align-self: center;
    width: 300px;
}


.user-icons .icons-in {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #00b5f6;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.user-icons .icons-in em {
    color: white;
    font-size: 20px;
    font-style: normal;
}

.user-icons .user-name {
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;
}


.lang-img {
    width: 20px;
    margin-left: 2px;
}

.user-icons .user-name span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 225px;
    height: 52px;
    overflow: hidden;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.user-top-icon .trade-info {
    width: 420px;
    padding-left: 30px;
    display: flex;
}

.user-top-icon .trade-info .item {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.hhhcc {
    .title {
        line-height: 16px;
        // overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 5px;
        padding-right: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 23px;
        z-index: 2;
        position: absolute;
        left: 20px;
        top: 85px;
        text-shadow: 0.1em 0.1em 0.2em rgb(0, 0, 0);
        font-weight: 500;

    }
}

.user-top-icon .trade-info .item.capital {
    width: 60%;
}

.user-icons .user-name span.uid {
    color: #8994a3;
    font-size: 12px;
}

.circle-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 2px solid #ebeff5;
    margin-left: 14px;
}

.horz {
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;
    padding: 5px;
}

.horz1 {
    display: flex;
    justify-content: space-evenly;
    // margin-top: 5px;
    padding: 5px;
}

.logowapper {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
}

.headrow {
    display: flex;
    height: 20vh;
    // align-items: center;
    // align-items: baseline;
}


.circle-info p.count {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.user-avatar-public {
    background: #df9a00;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 5px 0 rgba(71, 78, 114, 0.24);
    position: relative;
}

.user-avatar-public > .user-avatar-in {
    background: #f0a70a;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

/*新加样式*/
.router-link-active {
    color: red;
}

/* router-link-exact-active router-link-active */

.btn {
    color: #f0a70a;
}

.ivu-btn-primary {

    border-radius: 20px;
    background-image: url(../assets/logo/login_left.png);
    background-repeat: no-repeat;
    background-size: cover;
}

/deep/ .ivu-select {
    border-color: transparent;
    border: 0px solid transparent;
}

/deep/ .ivu-select-single {
    border-color: transparent;
    border: 0px solid transparent;
}

/deep/ .ivu-select-default {
    border-color: transparent;
    border: 0px solid transparent;
}

/deep/ .ivu-select-selected-value {
    width: 0;
    height: 0;
    color: transparent
}


/deep/ .ivu-select-selection {
    height: 20px;

    .ivu-icon {
        display: none;
        width: 0px;
        height: 0px;
    }

    .ivu-icon-ios-arrow-down {
        display: none;
        width: 0px;
        height: 0px;
    }

    .ivu-select-arrow {
        display: none;
        width: 0px;
        height: 0px;
    }
}

.ivu-spin-fix {
    position: fixed;
    height: 100vh;
    z-index: 400;
    background: transparent;
}


</style>



