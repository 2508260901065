<template>

    <div class="singlecard-list">
    
    <div class="v-card" @click="selectItem(item)" v-for="(item, index) in list" :key="index">
      <div class="card">
        
        <img :src="item.src"  class="pic"/>
       <div class="title">
        <span>{{ item.lang }}</span>
       </div>
       <div v-if="item.state != 0">
        <img src="../assets/logo/meng.png"  class="pic1"/>
        <div class="title1">
        <span>{{ item.notice }}</span>
       </div>
       </div>
      
      </div>
     
    </div>
  </div>
  
 
</template>

<script type="text/ecmascript-6">
// 由于接收的list有两种不同格式的数据，所以对两种格式做了部分兼容
export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  methods: {
    selectItem (item) {
      if (item.state != 0) {
        return
      }
      this.$emit('select', item)
    },
    goMore(){

    },
    nothing(){
      
    }
  },
 
}
</script>

<style lang="scss" scoped>

.singlecard-list {
  display: flex;
  flex-wrap: wrap;

  .v-card {
    // display: inline-block;
    box-sizing: border-box;
    // margin-top: 5px;
    margin-left: 5px;
    // padding-bottom: 5px;
    .card {
      position: relative;
      overflow: hidden;
      

      .pic {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 5px;
      }

      .pic1{
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 5px;
        z-index: 100;
        position: absolute;
        
      }

      // .count {
      //   display: flex;
      //   justify-content: space-between;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;
      //   padding: 5px 6px;
      //   box-sizing: border-box;
      //   color: #ffffff;
      //   font-size: 12px;
      //   // background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), transparent);

      //   .left {
      //     span {
      //       margin-right: 5px;
      //     }
      //   }

      //   i {
      //     margin-right: 5px;
      //   }
      // }
    }

    .title {
      line-height: 16px;
      color: #fff;
      text-overflow: ellipsis;
      padding-left: 5px;
      padding-right: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 15px;
      z-index: 2;
      position: absolute;
      left: 10px;
      top: 10px;
      text-shadow: 0.1em 0.1em 0.2em rgb(0, 0, 0);
      font-weight: 500;
      
    }

    .title1 {
      line-height: 16px;
      color: #fff;
      // overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 5px;
      padding-right: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 15px;
      z-index: 200;
      position: absolute;
      left: 50px;
      top: 50px;
      text-shadow: 0.1em 0.1em 0.2em rgb(0, 0, 0);
      font-weight: 500;
      
    }
  }
}
</style>
