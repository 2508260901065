<template>
   <div class="login_form">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
        {{$t('uc.finance.datapage')}}
      </span>
      <span>{{  }}</span>
    </div>
    <div style="margin-top: 60px;">
    <RadioGroup v-model="animal" type="button" size="small" @on-change="changeCurrency">
        <Radio label="USDT"></Radio>
        <Radio label="USD"></Radio>
        <Radio label="VND"></Radio>
        <Radio label="CNY"></Radio>
    </RadioGroup>
    </div>
    
    <Card class="header">
       <div style="color: darkgrey;">
        {{$t('uc.finance.xx33')}} {{ userNoxx }}

        <Row style="margin-top: 20px;">

          <div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">
            <div>
              <img src="../assets/logo/coin.png" width="20" alt="">
            </div>
            
            <div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx34')  }}</span>
<span style="color: hotpink;">{{ totalregist }}</span>
</div>
          </div>
      

<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>
<div style="display: flex;align-items: center;">
  <img src="../assets/logo/coin.png" width="20" alt="">
  <div class="information">
    <span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx35')  }}</span>
<span style="color: hotpink;">{{ totalregist }}</span>
  </div>
</div>
          
</Row>

<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px; width: 40%;">
  <div>
    <img src="../assets/logo/coin.png" width="20" alt="">
  </div>
  
  <div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx36')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>
<div style="display: flex;align-items: center;">
<img src="../assets/logo/coin.png" width="20" alt="">
<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx37')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>

</Row>



<Row style="margin-top: 20px;">

<div style="display: flex;align-items: center;margin-left: 20px;width: 40%;">
  <div>
    <img src="../assets/logo/coin.png" width="20" alt="">
  </div>
  
  <div class="information">
<span style="color: darkgrey;font-size: xx-small;">{{$t('uc.finance.xx38')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>


<Divider type="vertical" style="margin-left: 5%;height: 30px;"/>
<div style="display: flex;align-items: center;">
<img src="../assets/logo/coin.png" width="20" alt="">
<div class="information">
<span style="color: darkgrey;font-size:xx-small;">{{$t('uc.finance.xx39')  }}</span>
<span style="color: hotpink;">{{ 0 }}</span>
</div>
</div>

</Row>


       </div>
          
       
    </Card>

    <div class="body" style="margin-top:10px; width: 90%; margin-left: 5%;">

      <section class="celllall"  @click="gotoMembers">

<div style="display: flex; align-items: center;width: 40%; ">
<img src="../assets/logo/share.png" width="20" alt="">
<span style="margin-left: 10px;" class="textColor">
  {{$t('uc.finance.xx42')}}
</span >
</div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
</section>

<section class="celllall"  @click="gotoCommission">

<div style="display: flex; align-items: center;width: 40%; ">
<img src="../assets/logo/share.png" width="20" alt="">
<span style="margin-left: 10px;" class="textColor">
  {{$t('uc.finance.xx43')}}
</span >
</div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
</section>

 <section class="celllall" @click="gotoGameBill">

  <div style="display: flex; align-items: center;width: 40%; ">
      <img src="../assets/logo/zhangdan.png" width="20" alt="">
      <span style="margin-left: 10px;" class="textColor">
        {{$t('uc.finance.gamedetail')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>


     <section class="celllall"  @click="promotion">

      <div style="display: flex; align-items: center;width: 40%; ">
      <img src="../assets/logo/share.png" width="20" alt="">
      <span style="margin-left: 10px;" class="textColor">
        {{$t('uc.finance.promotion')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>
</div>

    
  </div>
</template>

<script>
  import dayjs from 'dayjs';

export default {
  data() {
    return{
      nicknamexx:'',
      userNoxx:'',
      dataList:[],
      startDate: '',
      endDate : '',
      defaultDate:[ dayjs(), dayjs().add(-30, 'day') ],
      totalregist:'',
      totolwin:'',
      totalbet:'',
      totalValidBet:'',
      animal: 'USDT'
    
    }
  },
  created() {
 
 this.getMemberInfo();
 this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')

},


  methods: {
    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },

  gotoGameBill(){
    
  
    this.$router.push("/gamedetail");
  
  },
  gotoMembers(){
    
  
    this.$router.push({name:"Members", params:{members: JSON.stringify(this.dataList)}}); //添加红包判断

  
  },
  changeCurrency(data){
    console.log(''+ data+this.animal);
    this.getAgentData();
  },
  gotoCommission(){
  
    this.$router.push("/commision");
  
  },

  promotion(){

this.$router.push("/promotion");
},

  getMemberInfo() {
      //获取个人安全信息
      var self = this;
     
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
              localStorage.setItem("MEMBER", JSON.stringify(null));
              self.$store.commit("setMember", resp.data);
              self.nicknamexx = self.$store.getters.member.nickname
              self.userNoxx = self.$store.getters.member.userNo

              self.getAgentData()
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

    getAgentData() {
      //获取个人安全信息
      var self = this;
     let params = {}
     params.startDate = this.startDate
     params.endDate = this.endDate
     params.ccy = this.animal
     params.userNo = this.userNoxx
   
      this.$http
        .post(self.host + this.api.uc.agentData, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
             self.totalregist = resp.data.totalRegister
             self.totalbet = resp.data.totalBet
              self.totolwin = resp.data.totalWin
              self.totalValidBet = resp.data.totalValidBet
              self.dataList = resp.data.userList
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

}

}
</script>

<style lang="scss" scoped>
.login_form {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  }

  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;

  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}


.textColor{
  color: $color-text;
  font-size:small;
}

.body{
  padding: 10px;
  background-color: $color-box-back; 
  border-radius: 10px;
}

.header{
  margin-top: 20px;
  height: auto;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}


.celllall{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.information{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
}
</style>
