<template>
    <div class="card-box">
        <div class="top">
            <div class="mTitle">{{ cardInfo.lg.na }}</div>
            <!--            <div class="sel">下拉</div>-->
        </div>
        <div class="con">
            <div class="t1">
                <div class="left">
                    <span class="sign">滚球</span>
                    <span>{{sportsStatus[pe] && sportsStatus[pe].cn }}</span>
                </div>
                <div class="right">
                  <span class="video-icon">
                    <Icon type="logo-youtube"/>
                  </span>
                    <span style="color: #557086">丨</span>
                    <span>+8</span>
                </div>
            </div>
            <div class="t2">
                <div class="left">
                    <span>{{ cardInfo.ts[0].na }}</span>
                    <span class="score">{{ score[0] }}</span>
                </div>
                <div class="right">
                    <span>{{ cardInfo.ts[1].na }}</span>
                    <span class="score">{{ score[1] }}</span>
                </div>
            </div>
            <!--            <div class="t3">-->
            <!--                <div class="left">-->
            <!--                    <span>托马斯.维拉/</span>-->
            <!--                    <span>T.普特字</span>-->
            <!--                </div>-->
            <!--                <div class="right">-->
            <!--                    <span>3</span>-->
            <!--                    <span>0</span>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="t4">-->
            <!--                <div class="left">-->
            <!--                    <span>托马斯.维拉/</span>-->
            <!--                    <span>T.普特字</span>-->
            <!--                </div>-->
            <!--                <div class="right">-->
            <!--                    <span>3</span>-->
            <!--                    <span>0</span>-->
            <!--                </div>-->
            <!--            </div>-->
            <p>胜 {{sid === 1 ? '平' :''}} 负</p>
            <div class="t4">
                <div>
                    <span class="name">{{cardInfo.ts[0].na}}</span>
                    <span class="odd">1.03</span>
                </div>
                <!--足球就有-->
                <div v-if="sid === 1">
                    <span class="name">平</span>
                    <span class="odd">2.20</span>
                </div>
                <div>
                    <span class="name">{{ cardInfo.ts[1].na }}</span>
                    <span class="odd">2.20</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { sportsStatus } from '../util/sportsConst'
export default {
    name: "",
    props: {
        cardInfo: {}
    },
    data() {
        return {
            sportsStatus,
            score: [], //比分
            pe: null, //滚球状态
            sid: null, //运动种类
        }
    },
    created() {
        this.initData()
    },
    methods: {
        initData() {
            if(this.cardInfo.mc && this.cardInfo.mc.pe){
                this.pe = this.cardInfo.mc.pe
            }
            if(this.cardInfo.lg && this.cardInfo.lg.sid){
                this.sid = this.cardInfo.lg.sid
            }
            this.cardInfo && this.cardInfo.nsg.forEach(item => {
                if (item.tyg === 5) {  //得分
                    this.score = item.sc
                }
            })
        }
    }
}
</script>


<style scoped lang="scss">
.card-box {
    width: 100%;
    //height: 300px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    color: #BAB1D3;
    border-bottom: 2px solid #2f4553;

    .top {
        height: 42px;
        padding: 6px;

        color: #d5dceb;
        display: flex;
        justify-content: space-between;

        .mTitle, .sel {
            padding: 0 6px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }
    }

    .con {
        padding: 0 6px;
        flex: 1;
        line-height: 30px;

        .t1 {
            height: 30px;
            display: flex;
            justify-content: space-between;

            .sign {
                padding: 2px;
                border-radius: 2px;
                font-size: 12px;
                color: #fff;
                background-color: #e9113c;
                margin-right: 4px;
                font-weight: 600;
            }

            .right {
                //position: relative;
                //.video-icon{
                //    position: absolute;
                //}
            }
        }

        .t2 {
            font-weight: 600;
            height: 60px;
            color: #fff;

            .right, .left {
                display: flex;
                justify-content: space-between;

                .score {
                    color: #ff9d00;
                }
            }
        }

        .t4 {
            display: flex;
            justify-content: space-evenly;

            & > div {
                display: flex;
                flex-direction: column;
                background-color: #071824;
                padding: 0 10px;
                width: 30%;
                border-radius: 6px;
                text-align: left;

                .name {
                    display: inline-block;
                    font-size: 12px;
                    height: 20px;
                    color: #fff;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .odd {
                    font-weight: 700;
                    color: #4391e7;
                }
            }
        }
    }
}
</style>
