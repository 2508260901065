<template>
  <div class="login_form">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px;">
        {{$t('uc.finance.xx42')}}
      </span>
      <span>
        {{  }}
      </span>
    </div>


    <div style="margin-top: 80px;width: 90%; margin-left: 5%;">
      <Input :placeholder="$t('uc.finance.xx44')" style="width: 100%" >
            <Icon type="ios-search" slot="prefix" />
        </Input>
    </div>

<div style="height: 100%; width: 90%; margin-left: 5%; margin-top: 20px;">
 
  <ve-table :columns="columns" style="width:100%"
        scroll-width="120%" :table-data="memberList"></ve-table>
</div>
    
    
   
  </div>
</template>

<script>
export default {
   name:'Members',
  data () {
  return{

//     isAgent
// : 
// 0
// totalBet
// : 
// "0.0"
// totalValidBet
// : 
// "0.0"
// totalWin
// : 
// "0.0"
// userNo
// : 
// "171830"
    columns: [
                        {
                            field: "userNo",
                            key: "a",
                            title: this.$t('uc.finance.xx45'),
                            align: "center",
                            fixed: "left",
                            width: "20%",
                        },
                        {
                          field: "totalBet",
                            key: "b",
                            title: this.$t('common.date3'),
                            align: "left",
                            width: "30%",
                        },
                        {
                          field: "totalValidBet",
                            key: "c",
                            title: this.$t('common.date8'),
                            align: "left",
                            width: "30%",
                        },
                        { field: "totalWin", key: "d", title: this.$t('common.date2'),width: "20%",align: "left" },
                    ],
    memberList:[],
 
  }
        
      },
  created(){
    this.memberList = JSON.parse(this.$route.params.members)
console.log(this.memberList);
  },
  methods: {
    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },
}
}
</script>

<style lang="scss" scoped>
.login_form {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  }


  .box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: flex-start;
  align-items: baseline;

  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}


</style>
