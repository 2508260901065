<template>
 <div class="login_form">
   <div class="headBox">
      <span style="color:aliceblue;font-size:20px;">
        {{$t('common.message')}}
      </span>
    </div>
  <Main-tab-bar></Main-tab-bar>

 </div>
</template>

<script>

import MainTabBar from '../components/tabbar/MainTabBar'
export default {
  components: {
MainTabBar
  },
}
</script>

<style lang="scss" scoped>

.login_form {
   background: #0b1520;
   height: $full-page;
  position: relative;
  overflow: hidden;
  }

  .box_21{
 position: fixed;
  top: 0;
   width: 100%;
  height: 50px;
  padding: 10px;
  align-items: center;
  background: url(../assets/netmage/xxyz.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  z-index: 200;
}

</style>

