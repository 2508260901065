<template>
   <div class="login_form">

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span >
        {{$t('uc.forget.personal')}}
      </span>
      <span>{{  }}</span>
    </div>


    <!-- <div class="middleBox">
      <Card  shadow style="width: 100%;">

      <div style="display: flex; justify-content: space-between; align-items: center;">
        {{ $t('common.name') }}
        <div>
          {{ realName }}<Button @click="handleName" :disabled="realName != ''" style="margin-left: 20px;">{{ $t('common.set') }}</Button>
        </div>
      </div>


      <Divider />

      <div style="display: flex; justify-content: space-between; align-items: center;">
        {{ $t('common.nickname') }}
        <div>
          {{ nickName }}<Button @click="handleNick" style="margin-left: 20px;">{{ $t('common.set') }}</Button>
        </div>

      </div>

    <Divider />
    <div style="display: flex; justify-content: space-between;align-items: center;">
      {{ $t('common.gender') }}
      <Select v-model="model6" style="width:200px" @on-change="submitGender">
        <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    </div>
    <Divider />

    <div style="display: flex; justify-content: space-between;align-items: center;">
      {{ $t('common.birthday') }}
      <DatePicker type="date" confirm :value="value1" placeholder="Select date" style="width: 200px" @on-change="time1"  format="yyyy-MM-dd" @on-ok="submitBirthday"></DatePicker>
    </div>


        </Card>
    </div> -->

    <div class="body" style="margin-top:60px; width: 95%; margin-left: 3%;">
  <section class="celllall" >
    <div style="display: flex; align-items: center;">
      <img src="../assets/logo/xu1.png" width="20" alt="">
<span style="margin-left: 10px;">
  {{$t('common.date4')}}
</span>
    </div>

<span>{{ model6}}</span>
     </section>

     <Divider />

       <section class="celllall">
        <div style="display: flex; align-items: center;">
          <img src="../assets/logo/xu2.png" width="20" alt="">
<span style="margin-left: 10px;">
  {{$t('uc.regist.telno')}}
</span>
        </div>
       <span>{{ value2 }}</span>
     </section>
     <Divider />
     <section class="celllall" >
      <div style="display: flex; align-items: center;">
        <img src="../assets/logo/xuu1.png" width="20" alt="">
<span style="margin-left: 10px; ">
  {{$t('uc.finance.xx200')}}
</span>
      </div>

      <Input type="text"  placeholder="请输入FB账号" style="width: 40%;"  v-model="nickName" />

     </section>
     <Divider />
     <section class="celllall" @click="gotoBill">
    <div style="display: flex; align-items: center;">
      <img src="../assets/logo/xu3.png" width="20" alt="">
<span style="margin-left: 10px;">
  {{$t('uc.forget.resettelpwd')}}
</span>
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>

     <Divider />
       <section class="celllall" @click="gotoSet">
        <div style="display: flex; align-items: center;">
          <img src="../assets/logo/picc2.png" width="20" alt="">
<span style="margin-left: 10px;">
  {{$t('uc.forget.resetemailpwd')}}
</span>
        </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>


</div>


<div style="margin-top:20px; width: 95%; margin-left: 3%;background-color: #1B2C38;padding: 5px;border-radius: 10px;">
  <div style="display: flex;justify-content: space-between; color: aliceblue;">
    <span>{{ $t('common.birthday') }}</span>
  <!-- <span>{{ huioneName }}</span> -->
  </div>

  <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 5px;">
    <Input type="number" size="large" style="width: 30%;"  v-model="year" />
    <Input type="number" size="large" style="width: 30%;"  v-model="month" />
    <Input type="number" size="large" style="width: 30%;"  v-model="day" />
  </div>
</div>


<div style="width: 100%; position: fixed; bottom: 0px; left: 0;right: 0;  display: flex; justify-content: space-between;background-color:#1B2C38 ;padding: 20px;">
  <Button class="xxu1" @click="goback">{{  $t('uc.finance.xx203') }}</Button>
  <Button class="xxu2" @click="submitNickName">{{  $t('uc.finance.xx204') }}</Button>
</div>

  </div>
</template>

<script>
export default {

  data () {
            return {
                value1: '0',
                value2: '0',
                model6: '',
                realName: '',
                nickName:'',
                huioneName: '',
                gender: '',
                year: '',
                month: '',
                day : '',
                cityList: [
                    {
                        value: 1,
                        label: this.$t('common.male')
                    },
                    {
                        value: 2,
                        label: this.$t('common.female')
                    },

                ],
            }
        },

  created() {

    this.getMemberInfo()

 this.realName = this.member.realName ||''
 this.nickName = this.member.nickname || ''
 let uu = this.member.birthday || ''
 if (uu != '') {
  let xxy = this.member.birthday.split('-')
  this.year = xxy[0]
  this.month = xxy[1]
  this.day = xxy[2]
 }

  },

  computed: {
    member: function() {
      return this.$store.getters.member;
    },
    lang() {
      return this.$store.state.lang;
    },
  },

  methods: {
    time1(e) {
                this.value1 = e;
            },
showDate(){

},
chooseGender(){

},

gotoSet(){

this.$router.push("/set");
},
gotoBill(){

this.$router.push("/loginset");

},

handleName () {
                this.$Modal.confirm({
                    render: (h) => {
                        return h('Input', {
                            props: {
                                value: this.realName,
                                autofocus: true,
                                placeholder: this.$t('common.realNameInput')
                            },
                            on: {
                                input: (val) => {
                                    this.realName = val;
                                }
                            }
                        })
                    },
                    onOk: () => {
                        this.$Message.info('Clicked ok');
                        this.submitRealName()

                    },
                    onCancel: () => {
                        this.$Message.info('Clicked cancel');
                    }

                })

            },


            handleHuiOneName () {
                this.$Modal.confirm({
                    render: (h) => {
                        return h('Input', {
                            props: {
                                value: this.huioneName,
                                autofocus: true,
                                placeholder: this.$t('common.huioneNameInput')
                            },
                            on: {
                                input: (val) => {
                                    this.huioneName = val;
                                }
                            }
                        })
                    },
                    onOk: () => {
                        this.$Message.info('Clicked ok');
                        this.submitHuioneName()

                    },
                    onCancel: () => {
                        this.$Message.info('Clicked cancel');
                    }

                })

            },


handleNick () {
                this.$Modal.confirm({
                    render: (h) => {
                        return h('Input', {
                            props: {
                                value: this.nickName,
                                autofocus: true,
                                placeholder: this.$t('common.nicknameInput')
                            },
                            on: {
                                input: (val) => {
                                    this.nickName = val;
                                }
                            }
                        })
                    },
                    onOk: () => {
                        this.$Message.info('Clicked ok');
                        this.submitNickName()

                    },
                    onCancel: () => {
                        this.$Message.info('Clicked cancel');
                    }
                })
            },


            submitHuioneName(){
              let param = {};
        param["huioneName"] = this.huioneName;
        param['id'] = this.$store.getters.member.userNo
    let that = this
        this.$http
          .post(this.host + this.api.uc.updatePersonalInfo, param)
          .then(response => {
            var resp = response.body;
            if (resp.code == '0000') {
              that.member.huioneName = that.huioneName;
              that.$store.commit("setMember", that.member);
              that.$Message.success(that.$t("uc.finance.xx5"));

            } else {
              that.$Message.error(resp.mesg);
            }
          });
      },


submitRealName() {
      //实名认证

        let param = {};
        param["realName"] = this.realName;
        param['userNo'] = this.$store.getters.member.userNo
    let that = this
        this.$http
          .post(this.host + this.api.uc.updatePersonalInfo, param)
          .then(response => {
            var resp = response.body;
            if (resp.code == '0000') {
              that.member.realName = that.realName;
              that.$store.commit("setMember", that.member);
              that.$Message.success(that.$t("uc.finance.xx5"));

            } else {
              that.$Message.error(resp.mesg);
            }
          });
      },

      getMemberInfo() {
      //获取个人安全信息
      var self = this;

      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {

           this.$store.commit("setMember", resp.data);
           self.model6 = resp.data.userNo
           self.value1 = resp.data.birthday
           self.value2 = resp.data.phone

          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },


    submitNickName() {
      //实名认证
  let that = this
        let param = {};
        param["nickname"] = this.nickName;
        param['userNo'] = this.$store.getters.member.userNo
        param['birthday'] = this.year+'-'+this.month+'-'+this.day
        this.$http
          .post(this.host + this.api.uc.updatePersonalInfo, param)
          .then(response => {
            var resp = response.body;
            if (resp.code == '0000') {
              that.value2 = that.member.nickName
              that.huioneName = that.member.birthday
              that.$store.commit("setMember", that.member);
              that.$Message.success(that.$t("uc.finance.xx5"));
  that.year = ''
  that.month = ''
  that.day = ''
  that.goback()
            } else {
              that.$Message.error(resp.mesg);
            }
          });
      },

      submitBirthday(){
        let that = this
        let param = {};
        param["birthday"] = this.value1;
        param['userNo'] = this.$store.getters.member.userNo
  // newGender --> ( 0, 1, 2 的字串)
        this.$http
          .post(this.host + this.api.uc.updatePersonalInfo, param)
          .then(response => {
            var resp = response.body;
            if (resp.code == '0000') {

              that.$Message.success(that.$t("uc.finance.xx5"));

            } else {
              that.$Message.error(resp.mesg);
            }
          });
      },

      submitGender() {
      //实名认证
  let that = this

        let param = {};

        param["sex"] = this.model6;
        param['userNo'] = this.$store.getters.member.userNo

  // newGender --> ( 0, 1, 2 的字串)
        this.$http
          .post(this.host + this.api.uc.updatePersonalInfo, param)
          .then(response => {
            var resp = response.body;
            if (resp.code == '0000') {

              that.$Message.success(that.$t("uc.finance.xx5"));

            } else {
              that.$Message.error(resp.mesg);
            }
          });
      },

    goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },
  }

}
</script>

<style lang="scss" scoped>
.login_form {
  background: $color-background;
  height: 100vh;
  position: relative;
  overflow: hidden;
  }

  .headB{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	height: $head;
	padding: 10px;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	z-index: 200;
	width: 100%;
}
  .box_21{
    position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
 justify-content: space-between;
 align-items: center;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}

.middleBox{
  margin-top: 60px;
  width: 95%;
  color: $color-text;
  margin-left: 10px;
  margin-right: 15px;
}

.body{
  padding-left: 10px;
  padding-right: 10px;
  background-color: #1B2C38;
  border-radius: 10px;
}

.celllall{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: large;
  color: #fff;
  font-size:x-small;
}


/deep/ .ivu-input{
  border-radius: 8px;
  background-color: #1B2C38;
  color: #fff;
  border: 1px solid #2f2f2f;
  text-align: end;
}


/deep/ .ivu-divider-horizontal{
  margin: 1px 0;
}

/deep/ .ivu-divider{
  background-color: #2f2f2f;
}


.xxu1{
  width: 49%;
  background-image: url(../assets/logo/xxu1.png);
  background-size: 100% 100%;
  color: #d2b956;
  border: none;
  background-color: transparent;
  font-size: small;
  height: 35px;
  margin-left: -5px;

}
.xxu2{
  width: 49%;
  background-image: url(../assets/logo/xxu2.png) ;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  font-size: small;
  height: 35px;
  margin-right: -5px;
}
</style>
