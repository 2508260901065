<template>
  <div class="login_form">

<div class="header">

  <div class= "head_er">
    <!-- <img src="../assets/logo/ID.png" width="25" alt=""> -->
      <!-- <span style="margin-left: 10px;">{{ nicknamexx }}</span> -->
  <span style="margin-left: 45%;font-weight: 600;">{{ $t("common.tabbar4") }}</span>
  </div>

<div class="bodyback">
  <div style="display: flex;justify-content: space-between; padding-left: 10px;padding-right: 10px;padding-top: 5px;align-items: center;" v-if="isLogin == true">
    <div style="display: flex;color: aliceblue;align-items: center;" >
      <img src="../assets/logo/sss1.png" width="40" alt="">
      <span style="margin-left: 10px;" >: {{ userNoxx  }}</span>
      <!-- <span style="margin-left: 10px;" v-else>: {{ $t("common.logintip") }}</span> -->
    </div>

<div>
<img src="../assets/logo/mail.png" style="margin-right: 10px;" width="20" alt="">
<!-- <div>
        <Button type="success" size="small" @click="onyKeyBack(moneybab.ccy)">{{$t("common.oneKey") }}</Button>
      </div> -->
</div>

  </div>

  <div style="display: flex; align-items: flex-start; padding-left: 40px;" v-else>
      <Button  style="width: 80px; margin-top: 15px;height: 30px;  margin-right: 15px;" @click="gotoLogin">{{$t('uc.login.login')}}</Button>
      <Button  style="width: 80px; margin-top: 15px;height: 30px; margin-right: 15px" @click="gotoReg">{{$t('common.register')}}</Button>
    </div>

  <div style="display: flex;  justify-content: space-around;margin-top: 20px; color: aliceblue;">
      <div style="display: flex;flex-direction: column;" v-if="isLogin == true">
        <span>{{$t("common.meamount") }}  ( {{ moneybab.ccy }} )</span>
        <span style="font-size: larger;font-weight: 800;">{{ moneyamout }}</span>
      </div>
      <div style="display: flex;flex-direction: column;" v-else>
        <span>{{$t("common.meamount") }}</span>
        <span style="font-size: larger;font-weight: 800;">{{ 0 }}</span>
      </div>
      <Divider type="vertical" style="height: 30px;margin-left: -8%;" />
      <div style="display: flex;flex-direction: column; color: aliceblue;">
        <span>{{$t("common.mebonus") }}</span>
        <span style="font-size: larger;font-weight: 800;">{{ 0 }}</span>
      </div>
  </div>


  <Card class="mc_card" >

     <Row >
         <Col span="6">
           <Card style=" background-color: transparent; border-style: none;" dis-hover>
     <div style="align-items:center;margin-top: -25px;display: flex;flex-direction: column;" class="textColor"  @click="gotoWallet">
         <img src="../assets/logo/wallet.png" width="30">
         <span>{{$t("uc.forget.wallet") }}</span>
     </div>
 </Card>
     </Col>

     <Col span="6">
       <Card style="background-color: transparent; border-style: none;" dis-hover>
     <div style="align-items:center;margin-top: -25px;display: flex;flex-direction: column;" class="textColor" @click="gotocharge">
         <img src="../assets/logo/deposit.png" width="30">
         <span>{{$t("uc.finance.charge") }}</span>
     </div>
 </Card>
     </Col>
     <Col span="6">
       <Card style="background-color: transparent; border-style: none;" dis-hover>
     <div style="align-items:center;margin-top: -25px;display: flex;flex-direction: column;" class="textColor" @click="gotoWithdraw">
         <img src="../assets/logo/withdraw.png" width="30">
         <span>{{$t("uc.finance.pickup") }}</span>
     </div>
 </Card>
     </Col>
     <Col span="6">
       <Card style="background-color: transparent; border-style: none;" dis-hover>
     <div style="align-items:center;margin-top: -25px;display: flex;flex-direction: column;" class="textColor" @click="transaction">
         <img src="../assets/logo/transfer.png" width="30">
         <span>{{ $t("uc.finance.trans") }}</span>
     </div>
 </Card>
     </Col>

       </Row>


 </Card>
</div>


    </div>





<div class="body" style="margin-top:20px; width: 95%; margin-left: 3%;">

       <section class="celllall" @click="gotoMoney">
        <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/transrec.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
  {{$t('uc.finance.recordetail')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>
     <Divider />
       <section class="celllall" @click="gotoSet">
        <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/safty.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
        {{$t('uc.finance.center')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>
     <Divider />
 <section class="celllall" @click="gotoGameBill">

  <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/zhangdan.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
        {{$t('uc.finance.gamedetail')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>
     <Divider />
 <section class="celllall" @click="addAddress">

  <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/cardma.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
        {{$t('uc.finance.addUaddress')}}
</span >
    </div>



<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>
     <Divider />
     <section class="celllall" @click="addUSDTAddress">

      <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/address.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
        {{$t('uc.finance.xx123')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
   </section>


   <Divider />

     <section class="celllall" @click="gotoAgentPage">

      <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/agent.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
        {{$t('uc.finance.agentpage')}}
</span >
    </div>

<img src="../assets/logo/rightarr.png" width="15px" height="15px">
     </section>

     <Divider />
     <section class="celllall">

<div style="display: flex; align-items: center;width: 60%; ">
<img src="../assets/logo/timezone.png" width="25" alt="">
<span style="margin-left: 10px;" class="textColor1">
  {{$t('uc.finance.timezone')}}
</span >
</div>

<!-- <img src="../assets/logo/rightarr.png" width="15px" height="15px"> -->
<span style="margin-left: 10px;" class="textColor1"> {{ currentTime }} </span>
</section>
<Divider />

     <section class="celllall"  @click="language">

      <div style="display: flex; align-items: center;width: 60%; ">
      <img src="../assets/logo/worldwide.png" width="25" alt="">
      <span style="margin-left: 10px;" class="textColor1">
        {{$t('uc.finance.xx81')}}
</span >
    </div>
    <div style="display: flex; align-items: center;">
      <span class="textColor1">{{ currentLang }}</span>
<img src="../assets/logo/rightarr.png" width="15px" height="15px">
    </div>

     </section>
     <Divider />

     <section class="celllall"  @click="aboutus">

<div style="display: flex; align-items: center;width: 60%; ">
<img src="../assets/logo/infomation.png" width="25" alt="">
<span style="margin-left: 10px;" class="textColor1">
  {{$t('common.aboutus')}}
</span >
</div>
<div style="display: flex; align-items: center; color:#738191;font-size:small">
  {{ 'Version  2.66' }}
  <!-- //测试环境  2.46, 正式环境1.02 -->
<!-- <img src="../assets/logo/rightarr.png" width="15px" height="15px"> -->
</div>

</section>
<Divider />

<section class="celllall"  @click="logout" v-if="isLogin">

<div style="display: flex; align-items: center;width: 60%; ">
<img src="../assets/logo/exits.png" width="25" alt="">
<span style="margin-left: 10px;" class="textColor1">
  {{$t('common.logout')}}
</span >
</div>
<!-- <div style="display: flex; align-items: center;">
<span class="textColor1">{{$t('common.logout')}}</span>
</div> -->

</section>

</div>

      <!-- <div style="margin-top:20px;" v-if="isLogin">
        <Button class="exttt"  @click="logout">{{$t('common.logout')}}</Button>
      </div> -->

      <Modal
        v-model="modal1"
        :title="$t('uc.finance.xx81')"
        @on-ok="changelanguage(vertical)"
        @on-cancel="cancel">
        <RadioGroup v-model="vertical" vertical>
        <Radio label="zh-CN" style="display: flex;align-items: center;">

            <img src="../assets/logo/china.png" width="20" height="20">
            <span>{{ $t('uc.regist.china') }}</span>


        </Radio>
        <Radio label="th-TH" style="display: flex;align-items: center;">
          <img src="../assets/logo/thai.png" width="20" height="20">
          <span>{{ $t('uc.regist.thailand') }}</span>
        </Radio>
        <Radio label="vi-VN" style="display: flex;align-items: center;">
          <img src="../assets/logo/vietnam.png" width="20" height="20">
          <span>{{ $t('uc.regist.vietnam') }}</span>
        </Radio>
        <Radio label="ma-MA" style="display: flex;align-items: center;">
          <img src="../assets/logo/mala.png" width="20" height="20">

          <span>{{ $t('uc.regist.malaysia') }}</span>
        </Radio>
        <Radio label="ca-CB" style="display: flex;align-items: center;">
          <img src="../assets/logo/cambodia.png" width="20" height="20">

          <span>{{ $t('uc.regist.cambodia') }}</span>
        </Radio>
        <Radio label="in-IN" style="display: flex;align-items: center;">
          <img src="../assets/logo/indiaN.png" width="20" height="20">

          <span>{{ $t('uc.regist.india') }}</span>
        </Radio>
        <Radio label="en-US" style="display: flex;align-items: center;">
          <img src="../assets/logo/usa.png" width="20" height="20">

          <span>{{ $t('uc.regist.uk') }}</span>
        </Radio>
        <Radio label="ja-JP" style="display: flex;align-items: center;">
          <img src="../assets/logo/japan.png" width="20" height="20">

          <span>{{ $t('uc.regist.japan') }}</span>
        </Radio>
        <Radio label="ko-KR" style="display: flex;align-items: center;">
          <img src="../assets/logo/korea.png" width="20" height="20">

          <span>{{ $t('uc.regist.korea') }}</span>
        </Radio>
    </RadioGroup>
    </Modal>
    <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
			<Main-tab-bar></Main-tab-bar>
  </div>
</template>

<script>

import MainTabBar from '../components/tabbar/MainTabBar'
import {numberToCurrencyNo} from '@/config/index'
import moment from 'moment-timezone'

export default {
components: {
MainTabBar,
  },

  data() {
    return{
      theme1: 'dark',
      moneybab:'',
      isagent:'',
      moneyList:[],
      moneyamout: '',
      nicknamexx:'',
      userNoxx:'',
      modal1 : false,
      vertical: '',
      currentLang: '',
      eloading: false
    }
  },

  computed: {
    isLogin: function() {
      return this.$store.getters.isLogin;
    },
    currentTime() {
      return moment.tz.guess();
    }
  },

  mounted(){
    window.addEventListener('load', e =>this.beforeunloadHandler(e));
  },

  destroyed(){
    window.removeEventListener('load', e=>{console.log(e);} )

  },

created() {
  // this.getMoneyback();
  if (!this.isLogin) {
    console.log('temp');
  }else{
    // this.getMemberInfo();
    this.getMember();

  }

  if (this.$store.getters.lang == 'zh-CN') {
    this.currentLang = this.$t('uc.regist.china')
  }else if (this.$store.getters.lang == 'zh-HK') {
    this.currentLang = this.$t('uc.regist.hk')
  }else if (this.$store.getters.lang == 'en-US') {
    this.currentLang = this.$t('uc.regist.uk')
  }else if (this.$store.getters.lang == 'ca-CB') {
    this.currentLang = this.$t('uc.regist.cambodia')
  }else if (this.$store.getters.lang == 'vi-VN') {
    this.currentLang = this.$t('uc.regist.vietnam')
  }
  else if (this.$store.getters.lang == 'th-TH') {
    this.currentLang = this.$t('uc.regist.thailand')
  }
  else if (this.$store.getters.lang == 'ja-JP') {
    this.currentLang = this.$t('uc.regist.japan')
  }

  else if (this.$store.getters.lang == 'ma-MA') {
    this.currentLang = this.$t('uc.regist.malaysia')
  }
  else if (this.$store.getters.lang == 'in-IN') {
    this.currentLang = this.$t('uc.regist.india')
  }
  else if (this.$store.getters.lang == 'ko-KR') {
    this.currentLang = this.$t('uc.regist.korea')
  }


  this.vertical = this.$store.getters.lang


  },
 methods: {

  beforeunloadHandler(){
    if (!this.isLogin) {
    console.log('temp');
  }else{
    // this.getMemberInfo();
    this.getMember();

  }

  if (this.$store.getters.lang == 'zh-CN') {
    this.currentLang = this.$t('uc.regist.china')
  }else if (this.$store.getters.lang == 'zh-HK') {
    this.currentLang = this.$t('uc.regist.hk')
  }else if (this.$store.getters.lang == 'en-US') {
    this.currentLang = this.$t('uc.regist.uk')
  }else if (this.$store.getters.lang == 'ca-CB') {
    this.currentLang = this.$t('uc.regist.cambodia')
  }else if (this.$store.getters.lang == 'vi-VN') {
    this.currentLang = this.$t('uc.regist.vietnam')
  }
  else if (this.$store.getters.lang == 'th-TH') {
    this.currentLang = this.$t('uc.regist.thailand')
  }
  else if (this.$store.getters.lang == 'ja-JP') {
    this.currentLang = this.$t('uc.regist.japan')
  }

  else if (this.$store.getters.lang == 'ma-MA') {
    this.currentLang = this.$t('uc.regist.malaysia')
  }
  else if (this.$store.getters.lang == 'in-IN') {
    this.currentLang = this.$t('uc.regist.india')
  }
  else if (this.$store.getters.lang == 'ko-KR') {
    this.currentLang = this.$t('uc.regist.korea')
  }


  this.vertical = this.$store.getters.lang

  },
  cancel(){
    this.modal1 = false
  },
  aboutus(){
    this.$router.push('/aboutUS')
  },
  changelanguage: function(name) {
      let tempname = name
      if (name == 'en-US-cam') {
        tempname = 'en-US'
      }

        console.log("change language: " + name);
        this.$store.commit("setlang", tempname);
          this.$i18n.locale = tempname;
        this.country_en = tempname


        if (this.$store.getters.lang == 'zh-CN') {
    this.currentLang = this.$t('uc.regist.china')
  }else if (this.$store.getters.lang == 'zh-HK') {
    this.currentLang = this.$t('uc.regist.hk')
  }else if (this.$store.getters.lang == 'en-US') {
    this.currentLang = this.$t('uc.regist.uk')
  }else if (this.$store.getters.lang == 'ca-CB') {
    this.currentLang = this.$t('uc.regist.cambodia')
  }else if (this.$store.getters.lang == 'vi-VN') {
    this.currentLang = this.$t('uc.regist.vietnam')
  }else if (this.$store.getters.lang == 'vi-VN') {
    this.currentLang = this.$t('uc.regist.vietnam')
  }else if (this.$store.getters.lang == 'vi-VN') {
    this.currentLang = this.$t('uc.regist.vietnam')
  }

      this.modal1 = false
      },
  language(){
this.modal1 = true
  },
  gotoLogin(){

  this.$router.push("/login");
},

gotoReg(){
this.$router.push("/MobileRegister");
},
  changeItem(name){
       if (name == '1') {
       this.$router.push("/Recharge");
      } else {
        this.$router.push('/Withdraw')
      }
  },
  logout(){
    let self = this;
    this.$http
        .post(self.host + this.api.uc.toppromotionmonth)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            self.$Message.success(resp.mesg);
            this.$store.commit("setMember", null);
            localStorage.setItem("MEMBER", JSON.stringify(null));

              localStorage.setItem("TOKEN", null);
              localStorage.removeItem("USERKEY", null);

              this.$router.replace("/");
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
  },


  onyKeyBack(currency){


  let self = this;
     let param = {}
     let str = 'DG-'+currency+',BI-'+currency+',AG-'+currency+',GB-'+currency+',E0-'+currency+',IB-'+currency+',JD-'+currency+',PG-'+currency+',SB-'+currency+',WC-'+currency
     param.gameCodes = str
     this.eloading = true
     this.$http
        .get(self.host + this.api.uc.mdboBalance, {params: param})
        .then(response => {
          let resp = response.body;
          self.eloading = false
          if (resp.code == "0000") {
          let curs = resp.data
          curs.forEach(element => {
            if (element.balance != '0.0') {
              self.getMoneyback(element.gameCode)
            }
else
            console.log(element.gameCode);
          });

          } else {
            self.$Message.error(resp.mesg);
          }
        });
},

  gotocharge(){

    if (!this.isLogin) {
      this.$router.push("/login")
      return
    }
    this.$router.push("/Recharge");

  },
  gotoAgentPage(){
    if (!this.isLogin) {
      this.$router.push("/login")
      return
    }
    this.$router.push("/agentPage");
  },

  gotoMoney(){
    if (!this.isLogin) {
      this.$router.push("/login")
      return
    }
    this.$router.push("/dwrecords");
  },
   gotoWithdraw(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
    this.$router.push("/Withdraw");

  },

   gotoBill(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
    this.$router.push("/finde");

  },
  gotoGameBill(){

    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
    this.$router.push("/gamedetail");

  },

  addUSDTAddress(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
    this.$router.push("/usdtList");
  },

  addAddress(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
    this.$router.push("/cart");

  },
   transaction(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }

    this.$router.push("/trans");

  },

  gotoSet(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
    this.$router.push("/profile");
  },

  gotoWallet(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
// this.$router.push("/wallet");
this.$router.push({name: 'Wallet', params:{curr: this.moneybab}})
},

  agentData(){
    if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
this.$router.push("/agentdata");
},

promotion(){
  // this.$router.push({ path: '/promotion', query: { gpage: this.$store.getters.member.userNo } })

  if (!this.isLogin) {
      this.$router.push("/login");
      return
    }
this.$router.push("/promotion");
},
//   getMoneyback(){
//  var self = this;
//     this.$http
//         .post(this.host + this.api.uc.apply)
//         .then(response => {
//           var resp = response.body;
//           if (resp.code == "0000") {
//             this.getMember();
//           } else {
//             self.$Message.error(resp.mesg);
//           }
//         });
//   },


getMember() {
      //获取个人安全信息
      var self = this;
      // var params = {
      //     };

      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
         this.loading = true
        //  let cur = localStorage.getItem("currency");
      this.$http
        .post(self.host + this.api.uc.personalWallet)
        .then(response => {
          var resp = response.body;
          self.loading = false
          if (resp.code == "0000") {
            // self.user = resp.data;
            // self.usernameS = this.user.username.slice(0,1);
            self.moneyList = resp.data
           self.getMemberInfo()
          } else {
            // self.$Message.error(resp.mesg);
            self.$Notice.error({
                    title: self.$t("common.tip"),
                    desc:resp.mesg
                  });
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

getMoneyback(curCode){

let param = {};
  param["gameCode"] = curCode;
let self = this;
this.eloading = true
this.$http
  .get(this.host + this.api.uc.mdboMakeTransfer, {'params':param})
  .then(response => {
    let resp = response.body;
    self.eloading = false
    if (resp.code == '0000' || resp.code == 5000) {
      self.getMember();
    } else {
      self.$Notice.error({
              title: this.$t("common.tip"),
              desc:resp.mesg
            });
    }
  });
},

   getMemberInfo() {
      //获取个人安全信息
      var self = this;

      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
              localStorage.setItem("MEMBER", JSON.stringify(null));
              self.$store.commit("setMember", resp.data);
              self.isagent = resp.data.userType
              self.nicknamexx = self.$store.getters.member.nickname
              self.userNoxx = self.$store.getters.member.userNo
              self.moneyList.forEach(element => {
              if (element.ccy == resp.data.remark) {
                self.moneybab = element
              }
            });

            self.moneyamout = numberToCurrencyNo(self.moneybab.amount)
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

}
}

</script>

<style scoped lang="scss">
.login_form {
  background: #0a0a0a;
  height: 55rem;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.textColor{
  color: #fff;
  font-size: small;
}

.textColor1{
  color: #fff;
  font-size: small;
}

.header{
  // background-image: url('../assets/logo/headerBG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 230px;
  // border-radius: 10px;
  // margin-top: 20px;
}

.mc_card{
  width: 100%;
 margin-top: 25px;
 background-color:  transparent;
  border: none;
}

.body{
  padding: 5px;
  background-color: #1B2C38;
  border-radius: 10px;
}

.celllall{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exttt{
  width:80%;
  height: 40px;
  background-image: url(../assets/logo/exist.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  font-size: medium;
  border: none;
  color: #434240;
  font-weight: 600;
}


.bodyback{
  background-image: url(../assets/logo/backcard.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  margin-left: 10px;
  width: 95%;
  height: 200px;
}

.head_er{

display: flex;
align-items: center;
text-align: center;
padding: 10px;
color: aliceblue;
font-size: 20px;
// background-image: url(../assets/logo/45.png);
// background-position: center;
// background-size: 100% 100%;
// background-repeat: no-repeat;
height: 45px;
}

/deep/ .ivu-divider-horizontal{
  margin: 1px 0;
}

/deep/ .ivu-divider{
  background-color: #2f2f2f;
}


</style>
