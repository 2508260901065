<template>
    <div class="nav-rights">
        <div class="loader" v-if="!serverInfo && !guestModeUrl">
            <img src="../assets/logo/loading.svg" alt="">
        </div>
        <!--        <div class="box222">-->
        <!--            <img v-if="country_en != 'zh-CN'" src="../assets/logo/logojb.png" style="width: 100px;height: 20px;" alt="">-->
        <!--            <img src="../assets/logo/logojb1.png" style="width: 100px;height: 20px;" alt="" v-else>-->
        <!--            <div v-if="!token">-->
        <!--                <Button type="primary" style="width: 80px;height: 30px;  margin-right: 15px;" @click="gotoLogin">-->
        <!--                    {{ $t('uc.login.login') }}-->
        <!--                </Button>-->
        <!--                <Button type="primary" style="width: 80px;height: 30px; margin-right: 15px" @click="gotoReg">-->
        <!--                    {{ $t('common.register') }}-->
        <!--                </Button>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="banner-panel">-->
        <!--            <Banner :picList="picList"></Banner>-->
        <!--        </div>-->
        <!--体育大厅-->
        <div v-if="serverInfo && token" class="fbTing">
            <iframe name="iframe" :height="iframeH + 'px'" width="100%"
                    allowfullscreen="allowfullscreen"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowtransparency="true"
                    auto="autoplay"
                    muted="muted"
                    frameborder="0" :src="fbUrl"/>
        </div>
        <div v-if="!token && guestModeUrl" class="fbTing">
            <iframe name="iframe" :height="iframeH + 'px'" width="100%"
                    allowfullscreen="allowfullscreen"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowtransparency="true"
                    auto="autoplay"
                    muted="muted"
                    frameborder="0" :src="guestModeUrl"/>
            <!--                    frameborder="0" src="https://h5.st-newsports.com/index.html#/?token=guestMode&apiSrc=https://app.server.st-newsports.com&pushSrc=wss://sptph.server.st-newsports.com&decimal=test&userAgent=iphone&type=1"/>-->

        </div>
        <!--顶级体育项目 选项卡-->
        <!--        <NavTop v-if="tab == 1" :txt="'顶级体育项目'" :icon="'ios-basketball'" :type="1"></NavTop>-->
        <!--体育 卡片-->
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>
<script>

import MainTabBar from '../components/tabbar/MainTabBar'
// import PaoMaDeng from 'heyushuo-marquee';
// import SingleCardList from '../components/singlecard-list.vue'
import Sticky from 'vuejs-sticky-directive'
import Banner from "@/components/Banner.vue";
import NavTop from "../components/NavTop.vue"
import MatchList from "@/components/MatchList.vue";

export default {
    components: {
        // Banner,
        MainTabBar,
        // PaoMaDeng,
        // SingleCardList,
        // NavTop,
        // MatchList
    },
    directives: {Sticky},
    data() {
        return {
            iframeH: 500,
            token: '',
            tab: 1, // 1 2 3 4
            picList: [],
            country_en: '',
            fbUser: null,
            serverInfo: null,
            serverFbInfo: null,
            fbToken: '',
            fbUrl: null, //体育大厅入口
            guestModeUrl: null, //游客体育大厅入口
        };
    },
    created() {
        let h = window.innerHeight
        this.iframeH = h - 68  //内嵌页面的样式调整
        this.token = localStorage.getItem('TOKEN')
        if (!this.token || this.token === 'null') this.token = null
        // addEventListener('message', e => {
        //     //Iframe子页面触发确认时，父页面拿到e.data.relogin，贵公司需要跳转的地址
        //     if (e.data.relogin) {
        //         window.location.href = 'https://www.baidu.com'
        //     }
        // })
    },

    mounted() {
        window.addEventListener('message', this.handleMessage);
        this.initUserInfo()
        // 创建用户
        // let params = {
        //     options: 'GetFrbDetails,GetLines,Get-DataTypes,GetFeatures',
        //     // options: 'GetLines',
        //     // 'GetLines',
        //     // 'Get-DataTypes',
        //     // 'GetFeatures'
        // }
        // this.$http.get(this.hostSports + '/pp/getCasinoGames',
        //     {params}
        //     // {headers: {'Content-Type': 'application/json; charset=utf-8'}}
        // ).then(response => {
        //     console.log(response)
        // })
    },
    beforeUnmount() {
        // 在 beforeUnmount 钩子中移除监听器，避免内存泄漏
        window.removeEventListener('message', this.handleMessage);
    },
    methods: {
        gotoLogin() {
            this.$router.push("/login");
        },
        gotoReg() {
            this.$router.push("/MobileRegister");
        },
        getOrderUrlList() {

        },
        handleMessage(e) {
            console.log(12345)
            console.log(e.data)
            // alert(12345)
            // 确保消息来源合法
            // if (e.origin !== 'https://iframe-page-url.com') {
            //     console.warn('Received message from unauthorized origin:', e.origin);
            //     return;
            // }
            // 处理来自 iframe 的消息
            // alert(12345)
            if (e.data.relogin) {
                let h = window.location.origin + '#/login'
                window.location.href = h
            }
        },
        initUserInfo() {
            let apiSrc = 'https://app.server.st-newsports.com'  //配置一个默认 以防万一
            if (this.token && this.token !== 'null') {  //自己系统是否登陆
                this.getFbUserDetail()
            } else {  ///fb/domainList
                this.$http.get(this.hostSports + '/fb/domainList',
                ).then(response => {
                    const {code, data} = response.body
                    if (data.length) {
                        data.forEach(item => {
                            if(item.type === 1) {
                                apiSrc = item.domainList[0].domain
                            }
                            if (item.type === 3) {
                                this.guestModeUrl = item.domainList[0].domain + '/index.html#/?token=guestMode&apiSrc=' + apiSrc
                            }
                        })
                    }
                })
            }
        },
        // getMatchPage() {
        //     // leagueId	integer	联赛ID，matchIds、leagueId、type三者必传其一
        //     // type	integer	赛事分组类型，例如：1、滚球，3、今日，传数字类型编号，matchIds、leagueId、type三者必传其一，其他情况type必传 , see enum: match_play_type
        //     // matchIds	integer[]	赛事Id集合，批量查询赛事ID对应的赛事列表和赔率，matchIds、leagueId、type三者必传其一
        //     let data = {
        //         languageType: 'CMN',
        //         type: this.tab,
        //         // sportIds: [1]
        //     }
        //     this.$axios.post(
        //         this.hostSports + '/fb/matchPage',  // 请求的 URL
        //         // null,  // 不传请求体
        //         data,
        //     ).then(response => {
        //         const {data, code} = response.data
        //         if (code == 0) {
        //             if (this.tab == 1) this.matchListGun = data.records
        //             if (this.tab == 3) this.matchListFeature = data.records
        //         }
        //     }).catch(error => {
        //         console.error(error);
        //     });
        // },
        getFbToken() {  //这个会有游戏链接
            this.$http.get(this.hostSports + '/fb/getToken',
                {
                    params: {
                        platForm: 'h5'
                    }
                },
            ).then(response => {
                // (serverInfo对象里的h5Address/pcAddress)/index.html#/
                //     ?token={第一步存储的 token}&nickname={第一步存储的昵称}
                //     &apiSrc={第一步serverInfo对象里的apiServerAddress}
                //     &pushSrc={第一步serverInfo对象里的pushServerAddress}
                //     &virtualSrc={第一步serverInfo对象里的virtualAddress  (可选)}
                //     &platformName={平台名称 例：FB体育 (可选)}

                const res = response.body.data
                this.fbToken = res.token
                this.serverInfo = res.serverInfo
                this.serverFbInfo = res
                // console.log(this.getFbUrl())
                this.fbUrl = this.getFbUrl()
            })
        },
        getFbUrl() {
            const token = this.fbToken
            const h5Src = this.serverInfo.h5Address
            // const nickname = Storage.get("user-nickname")//（步骤一中的nickname）
            const nickname = this.serverFbInfo.userId//（步骤一中的nickname）
            const apiSrc = this.serverInfo.apiServerAddress //
            const pushSrc = this.serverInfo.pushServerAddress //（步骤一中的serverInfo对象里）
            // const virtualSrc = this.serverInfo.virtualAddress //（步骤一中的serverInfo对象里）
            // const platformName = "FB体育" //（自己平台名称，不传则默认FB体育）
            // const icoUrl = "https://fsports.co/favicon.ico"//（自己网页ico，不传则默认FB体育ico）;
            // const handicap = "1"//（盘口名称，不传则默认欧洲盘）;
            // const language = "CMN"//（多语言，不传则默认中文）;
            // const color = "" //（日间/黑夜模式，不传则默认黑夜模式）;
            const cid = "200"//（传币种id，不传则默认取接口里第一个币种展示）;
            const currencyId = '200' //（&currencyId={默认币种 例：1-人民币, 2-美元, 10-越南盾, 200-USDT等等 (可选)}
            const themeBg = '1C2B37' //（只支持十六进制颜色代码背景色，不传则默认展示，暂支持H5）;
            // const tutorialPop = "1"（首次登录弹窗教程提示：传1则不提示，不传则默认）;
            // const noType = "2"（隐藏FB网页多语言入口，多语言通过url多语言参数更改）;
            // const themeText = `{'h5FgColor':${this.serverFbInfo.themeFgColor}'` //（不传则默认展示，暂支持H5/PC）;
            const themeText = "1C2B37" //（不传则默认展示，暂支持H5/PC）;
            return `${h5Src}/index.html#/?token=${token}&nickname=${nickname}&apiSrc=${apiSrc}&pushSrc=${pushSrc}&themeText=${themeText}&themeBg=${themeBg}&currencyId=${currencyId}&cid=${cid}`
        },
        getFbUserDetail() {
            this.$http.get(this.hostSports + '/fb/userDetail',
                {
                    params: {
                        platForm: 'h5'
                    }
                },
            ).then(response => {
                const res = response.body.data
                if (res.userId) {  //已经绑定体育用户
                    this.fbUser = res
                    this.getFbToken()
                } else {  //创建FB账户
                    this.fbUserCreate()
                }
            })
        },
        fbUserCreate() {
            this.$http.get(this.hostSports + '/fb/userCreate',
                {
                    params: {
                        platForm: 'h5'
                    }
                },
            ).then(() => {
                // const res = response.body.data

                this.getFbUserDetail()
            })
        },
    },

    destroyed() {

    },

    checkLiveGameStatus() {

    }
};
</script>


<style scoped lang="scss">
.nav-rights {
    background: $color-background;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;

    .loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .box222 {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 45px;
        z-index: 200;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        align-items: center;
        background: #2f4553;
        padding-right: 13px;
    }

    .banner-panel {
        width: 96%;
        height: 170px;
        overflow: hidden;
        position: relative;
        margin: 60px auto 5px;
    }

    .fbTing {
        width: 100%;
        //height: calc(100vh - 60px);
        //padding-bottom: 60px;
        flex: 1;
    }
}

.rightBar {
    width: 90%;
    margin: auto;
}


.card-list-box {
    height: 170px;
    width: 98%;
    margin: 8px auto 0;

    .img-item {
        margin-left: 3%;
        width: 30%;
        height: 100%;
        border-radius: 6px;
    }

    .img-item:first-child {
        margin-left: 0;
    }
}

//.gunqiu{
//    padding-bottom: 200px;
//}

/deep/ .ivu-tabs-nav {
    color: #fff;

    .ivu-tabs-ink-bar {
        background-color: #fff;
    }
}

/deep/ .ivu-tabs-bar {
    border: none;
}

/deep/ .ivu-tabs-tab-active {
    color: #fff;
    background-color: #1c2a32;
}

/deep/ .ivu-tabs-tab-disabled {
    color: #aaa;
}

/deep/ .ivu-tabs-tab:hover {
    color: #fff;
}

/deep/ .ivu-select-selection {
    border-radius: 0 !important;
}


</style>



