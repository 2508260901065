<template>
    <div class="banner-box">
        <slider animation="fade" style="height: 100%;" :control-btn=false>
            <slider-item v-for="(i, index) in [1]" :key="index">
<!--                <img :src="i.coverImg" style="width:100%;height:100%">-->
                <div class="slide-box">
                    <div class="left">
                        <div class="tit1">促销活动</div>
                        <div class="tit2">美国网球公开赛</div>
                        <div class="tit3">Ace球双倍奖金</div>
                        <div class="tit4">阅读更多</div>
                        <div class="tit5">马上体验</div>
                    </div>
                    <div class="right">
                        <img src="../assets/banner/banner-r.jpg" alt="">
                    </div>
                </div>
            </slider-item>
        </slider>
    </div>
</template>
<script>
export default {
    name: "Banner",
    data() {
        return {}
    },
    props: {
        picList: {
            type: [Array, Array],
            default: () => {
            }
        }
    },
    watch: {
        // picList(newVal, old) {
        // }
    }
}
</script>


<style scoped lang="scss">
.banner-box{
    width: 100%;
    height: 100%;
    background-color: #1A2B37;
}
.slide-box{
    background-color: #213843;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;


    .left{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: #fff;
        width: 40%;
        .tit1{
            font-size: 11px;
            width: 80px;
            background-color: #fff;
            border-radius: 4px;
            color: #1A2B37;
        }
        .tit2{
            font-size: 18px;
            font-weight: 600;
            text-align: left;
        }
        .tit3{
            text-align: left;
        }
        .tit4{
            font-weight: 600;
            text-align: left;
        }
        .tit5{
            border: 1px solid #fff;
            border-radius: 5px;
            padding: 5px;
            font-weight: 600;
        }
    }

    .right{
        img{
            height: 100%;

        }
    }
}
</style>
