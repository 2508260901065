<template>
  <div class="login_form">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span  style="font-size:20px;">
        {{title}}
      </span>
      <span>{{  }}</span>
    </div>
    <Scroll :on-reach-bottom="handleReachBottom" :height="realHeight">
      <div class="result-list" ref="slotContent">
            <card-list @select="selectItemSub" :list="jdSlotList" v-if="desc == 'JDB_SLOT'"></card-list>
            <card-list @select="selectItemSub" :list="pgSlotList" v-else-if="desc == 'PG_SLOT'"></card-list>
            <card-list @select="selectItemSub" :list="ppSlotList" v-else-if="desc == 'PP_SLOT'"></card-list>
            <card-list @select="selectItemSub" :list="ntSlotList" v-else-if="desc == 'NT_SLOT'"></card-list>
            <card-list @select="selectItemSub" :list="jdFishList" v-else-if="desc == 'JDB_FISH'"></card-list>
          </div>

    </Scroll>
    
    <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
  </div>
</template>

<script>
import PG from '../../public/PG.json'
import JDB from '../../public/JDB.json'
import PP from '../../public/pp.json'
import NT from '../../public/netent.json'
import CardList from '../components/card-list.vue'

export default {
  components: {

CardList,

  },
  data() {
    return{
        desc: '',
        jdSlotList:[],
      pgSlotList:[],
      plSlotList:[],
      ppSlotList:[],
      ntSlotList:[],

      jdFishList:[],
      eloading : false,
      currentlang: '',
      pid : '',
    languageCode : '',
    operatorCode : '',
    realHeight: 0,
    
      windowHeight: 0,
    }
  },

  computed: {
    
    isLogin: function() {
      return this.$store.getters.isLogin;
    },
    
  
  },

  created() {

    this.windowHeight = document.documentElement.clientHeight;
    this.realHeight = (Number(this.windowHeight))
    let hhss = JSON.parse(this.$route.query.gid)
    this.desc = hhss.desc
    this.languageCode = hhss.langgg
    this.operatorCode = hhss.opp
    this.pid = hhss.pid

    switch (this.desc) {
      case 'JDB_SLOT':
        this.title = this.$t("uc.finance.xx157")
        break;
        case 'PG_SLOT':
        this.title = this.$t("uc.finance.xx158")
        break;
        case 'PP_SLOT':
        this.title = this.$t("uc.finance.xx159")
        break;
        case 'NT_SLOT':
        this.title = this.$t("uc.finance.xx160")
        break;
        case 'JDB_FISH':
        this.title = this.$t("uc.finance.xx161")
        break;
    
      default:
        break;
    }
    this.currentlang = this.$i18n.locale
    if (this.desc.includes('SLOT')) {
      this.getSlotGameList(this.desc)
    }else
    
    this.getFishGameList(this.desc)
  },



  activated(){

    this.windowHeight = document.documentElement.clientHeight;
    this.realHeight = (Number(this.windowHeight))
    let hhss = JSON.parse(this.$route.query.gid)
    this.desc = hhss.desc
    this.languageCode = hhss.langgg
    this.operatorCode = hhss.opp
    this.pid = hhss.pid
    switch (this.desc) {
      case 'JDB_SLOT':
        this.title = this.$t("uc.finance.xx157")
        break;
        case 'PG_SLOT':
        this.title = this.$t("uc.finance.xx158")
        break;
        case 'PP_SLOT':
        this.title = this.$t("uc.finance.xx159")
        break;
        case 'NT_SLOT':
        this.title = this.$t("uc.finance.xx160")
        break;
        case 'JDB_FISH':
        this.title = this.$t("uc.finance.xx161")
        break;
    
      default:
        break;
    }
    let xxy = ''
if (this.$i18n.locale != 'zh-CN') {
  xxy = 'en-US'
 
}else xxy = this.$i18n.locale

if (this.currentlang != xxy) {
      this.jdSlotList = []
      this.pgSlotList = []
      this.plSlotList = []
      this.ppSlotList = []
      this.ntSlotList = []
      this.jdFishList = []
      this.currentlang = xxy
    }
   
    if (this.desc.includes('SLOT')) {
      this.getSlotGameList(this.desc)
    }else
    
    this.getFishGameList(this.desc)

   
},
 
  methods: {

    goback() {
    //获取个人安全信息
    this.$router.back(-1)
   
  },


  
    gotoLogin(){
  
      this.$router.push("/login");
    },

  handleReachBottom(){
    console.log('bottom   xxxxxxx');
  },

    selectItemSub (item){
let self = this
      if (!this.isLogin) {
        this.gotoLogin()
        return
      }
this.eloading = true
      let param = {}
      param["gameID"] = item.GameCode
       param['gameType'] = item.GameType
      param['languageCode'] = this.languageCode
      param['operatorCode'] = this.operatorCode
      param['platform'] = 1
      param['productID'] = item.pID

       this.$http
        .post(this.host + this.api.uc.mdboLaunchGame, param)
        .then(response => {
          var resp = response.body;
          self.eloading = false
          if (resp.ErrorCode == 0) {
            self.gameUrl = resp.Url
           
if (self.modoGameCode.includes('AG') ) {
  window.location.href = self.gameUrl
  window.open(self.gameUrl,"_self",'toolbar=yes, menubar=yes')
}else
             this.$router.push({ path: '/GamePage', query: { gpage: self.gameUrl, gid:param.gameCode } })
          } else {
            // self.$Message.error(resp.mesg);
            self.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
            // this.$Message.error(this.$t('common.logintip'));
          }
        });

    },


    iSlotReturn(){
        if (this.desc == 'JDB_SLOT' && this.jdSlotList.length != 0 
        || this.desc == 'PG_SLOT' && this.pgSlotList.length != 0
        || this.desc == 'PP_SLOT' && this.ppSlotList.length != 0
        || this.desc == 'NT_SLOT' && this.ntSlotList.length != 0
       ) {
          
return true
        }
        return false
      },

    getSlotGameList(platform){

    
      if (this.iSlotReturn() == true) {
        if (platform.includes('JDB_SLOT')){
          this.realHeight = (Math.floor(this.jdSlotList.length / 3)  + 1)* 8 + 'rem'
        }else if (platform.includes('PG_SLOT')) {
          this.realHeight = (Math.floor(this.pgSlotList.length / 3)  + 1)* 8 + 'rem'
        }else if (platform.includes('PP_SLOT')) {
          this.realHeight = (Math.floor(this.ppSlotList.length / 3)  + 1)* 8 + 'rem'
        }else if (platform.includes('NT_SLOT')) {
          this.realHeight = (Math.floor(this.ntSlotList.length / 3)  + 1)* 8 + 'rem'
        }
        this.modoGameCode = platform
      this.currentGameType = 'SL'
        return
      }

      this.eloading = true
    let that = this
  let param = {};
     
    param["gameType"] = 1;
    param["productID"] = Number(this.pid);
    param['languageCode'] = this.languageCode
    param['operatorCode'] = this.operatorCode
    param['platform'] = 1

      this.modoGameCode = platform
      this.currentGameType = 'SL'
      this.$http
      .post(this.host + this.api.uc.mdboGameList, param)
      .then(response => {
        let resp = response.body
        that.eloading = false
        if (resp.ErrorCode == 0) {
          let temp = resp.ProviderGames

if (that.modoGameCode.includes('JDB_SLOT')) {
let slotjdb = ''
JDB.forEach(element2 => {
  if (element2.gType == that.gameConfig.JDB.SLOT) {
    slotjdb = element2
    return
  }
})

    temp.forEach(element1 => {
      if (that.$i18n.locale == 'zh-CN') {
        if (slotjdb.list[element1.GameCode]) {
          element1.ImageUrl =  require('@/assets/jdb_slot/'+element1.GameCode+'_cn.png')
element1.GameName = slotjdb.list[element1.GameCode].name_cn

        }
       

}
element1.pID = param["productID"]
    })


    that.jdSlotList = temp
    that.realHeight = (Math.floor(that.jdSlotList.length / 3)  + 1)* 8 + 'rem'
    console.log(' jd '+that.realHeight);

}
else
if (that.modoGameCode.includes('PP_SLOT')) {
// that.currentGType = that.gameConfig.GB.SLOT
that.ppSlotList = []
temp.forEach(element => {
  element.pID = param["productID"]
if (that.$i18n.locale == 'zh-CN') {
try {
  element.ImageUrl = require('@/assets/pp/'+element.GameCode+'_cn.png')
  } catch (error) {
    console.log(1111);
  }
if (PP[element.GameCode]) {
element.GameName = PP[element.GameCode].name_cn
}

}else {
try {
  element.ImageUrl = require('@/assets/pp/'+element.GameCode+'_en.png')
  } catch (error) {
    console.log(1111);
  }

}
  that.ppSlotList.push(element)
         
         });

         that.realHeight = (Math.floor(that.ppSlotList.length / 3)  + 1)* 8 + 'rem'

         console.log(' pp '+that.realHeight);
}
else
if (that.modoGameCode.includes('PL')) {
// that.currentGType = that.gameConfig.AG.SLOT
that.plSlotList = []
temp.forEach(element => {

element.pID = param["productID"]


  that.plSlotList.push(element)
         
         });

         that.realHeight =  (30 + (Math.floor(that.plSlotList.length / 3)  + 1)* 7.5) + 'rem'

         console.log(' pl '+that.realHeight);
}
else
if (that.modoGameCode.includes('PG_SLOT')) {
// that.currentGType = that.gameConfig.PG.SLOT
that.pgSlotList = []

temp.forEach(element => {

let element1 = {};
if (PG[element.GameCode]) {
if (that.$i18n.locale == 'zh-CN') {
element1.GameName = PG[element.GameCode].gn_cn
element1.ImageUrl =  require('@/assets/pg/' + PG[element.GameCode].pic_cn)
}else {
  element1.GameName = element.GameName
element1.ImageUrl =   require('@/assets/pg/' + PG[element.GameCode].pic)
}

element1.GameCode = element.GameCode
element1.GameType = element.GameType
element1.pID = param["productID"]
that.pgSlotList.push(element1)
}

         })
         that.realHeight = (Math.floor(that.pgSlotList.length / 3)  + 1)* 8 + 'rem'
         console.log(' pg '+that.realHeight);
}


else
if (that.modoGameCode.includes('NT_SLOT')) {
// that.currentGType = that.gameConfig.AG.SLOT
that.ntSlotList = []
temp.forEach(element => {
if (that.$i18n.locale == 'zh-CN') {
element.GameName = NT[element.GameCode].name_cn
try {
  element.ImageUrl = require('@/assets/nt/'+element.GameCode+'_cn.png')
  } catch (error) {
    console.log(1111);
  }
}else {
try {
  element.ImageUrl = require('@/assets/nt/'+element.GameCode+'_en.png')
  } catch (error) {
    console.log(1111);
  }
}
element.pID = param["productID"]


  that.ntSlotList.push(element)
         
         });

         that.realHeight = (Math.floor(that.ntSlotList.length / 3)  + 1) * 8 + 'rem'
}

        } else {
          // self.$Message.error(resp.mesg);
          this.$Notice.error({
                  title: this.$t("common.tip"),
                  desc:resp.ErrorMessage
                });
          this.realHeight = '50rem'
          // this.$Message.error(this.$t('common.logintip'));
        }
      });
    },

    isFishReturn(){
        if (this.desc == 'JDB_FISH' && this.jdFishList.length != 0 
       )  {
        this.realHeight = (30 + (Math.floor(this.jdFishList.length / 3)  + 1)* 8 ) + 'rem'
          return true
        }
        return false
      },

      getFishGameList(platform){
        if (this.isFishReturn() == true) {
          return
        }
        this.eloading = true
let that = this
    let param = {};
        // param["gameCode"] = platform;
        // param['Lang'] = this.$i18n.locale

        param["gameType"] = 8;
      param["productID"] = this.pid;
      param['languageCode'] = this.languageCode
      param['operatorCode'] = this.operatorCode
      param['platform'] = 1

        that.modoGameCode = platform
       
        this.currentGameType = 'FH'
        this.$http
        .post(this.host + this.api.uc.mdboGameList, param)
        .then(response => {
          var resp = response.body;
          that.eloading = false
          if (resp.ErrorCode == 0) {
            let temp = resp.ProviderGames

if ( that.modoGameCode.includes("JDB_FISH")) {

  that.currentGType = that.gameConfig.JDB.FISH
let fishjdb = ''
  JDB.forEach(element2 => {
    if (element2.gType == that.gameConfig.JDB.FISH) {
      fishjdb = element2
      return
    }
  })

      temp.forEach(element1 => {
        if (that.$i18n.locale == 'zh-CN') {
          if (fishjdb.list[element1.GameCode]) {
            element1.ImageUrl =  require('@/assets/jdb_fish/'+element1.GameCode+'_cn.png')
  element1.GameName = fishjdb.list[element1.GameCode].name_cn

          }
}
element1.pID = param["productID"]
      })


      that.jdFishList = temp
      that.realHeight = (30 + (Math.floor(that.jdFishList.length / 3)  + 1)* 8 ) + 'rem'

}

          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
                  that.loading = false
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
      },

  }
}




</script>

<style lang="scss" scoped>

.login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;

}


.result-list {
  width: 100%;
          // padding: 0px 0px;
          height: 100%;
          margin-top: 40px;
          padding-left: 10px;
        }

</style>
