export function formatTimestamp(timestamp) {
    // 创建 Date 对象
    const date = new Date(Number(timestamp));
    // 获取月份和日期
    const month = date.getMonth() + 1; // getMonth() 返回 0-11, 所以需要 +1
    const day = date.getDate();

    // 获取小时和分钟
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // 格式化小时和分钟为两位数
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // 返回格式化后的字符串
    return `${formattedHours}:${formattedMinutes}`;
}

/*判断是否真的登陆*/
export function getToken() {
    if (localStorage.getItem('TOKEN') && localStorage.getItem('TOKEN') !== 'null') {
        return true
    } else {
        return false
    }
}

