<template>
    <div class="nav-rights">
        <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
        <div class="banner-panel">
            <Banner :picList="picList"></Banner>
        </div>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>
<script>

import MainTabBar from '../components/tabbar/MainTabBar'
import Sticky from 'vuejs-sticky-directive'
import Banner from "@/components/Banner.vue";
import HeaderNav from "@/components/HeaderNav.vue";


export default {
    components: {
        Banner,
        MainTabBar,
        HeaderNav
    },
    directives: {Sticky},
    data() {

        return {

            picList: []
        };
    },

    methods: {
    },

    mounted() {
    },
    created() {

    },
    destroyed() {
        // clearInterval(this.timercode)
        // this.timercode = null
        // this.startRecPacket = false  //防止点击到其他位置 离开销毁
    },

    checkLiveGameStatus() {

    }
};
</script>
<style scoped lang="scss">

.banner-panel {
    width: 96%;
    height: 170px;
    overflow: hidden;
    position: relative;
    margin: 12px auto 25px;
}

.nav-rights {
    background: $color-background;
    height: $full-page;
    position: relative;
    overflow: hidden;
    text-align: center;

    .box222 {
        height: 60px;
        width: 100%;
    }
}

</style>



